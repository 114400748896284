import styled from "styled-components";
export const NavbarStyled = styled.div`
  color: ${({ theme }) => theme.secondary};
  height: 70px;
  top: 0;
  display: flex;
  padding: 0px 20px;
  justify-content: space-between;
  align-items: center;
  background: #ffffff;
  box-shadow: 3px 1px 2px rgba(0, 0, 0, 0.3), 6px 2px 6px 2px rgba(0, 0, 0, 0.15);
  &.bg-purple{
    background: rgb(82, 25, 134);
    color: #ffffff;
  }
`;
