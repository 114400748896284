import { Table } from "components/Table/Table";
import { BackButton } from "components/common/BackButton";
import {
  BoxStyle,
  BoxYellowOutline,
  ButtonBlue,
  ButtonYellowFill,
  DarkHeading,
  DashboardBoxStyle,
  Paragraph,
  SpanYellow,
  YellowButton,
} from "components/common/CommonStyles";
import React, { useEffect, useState } from "react";
import { AiFillCopy } from "react-icons/ai";
import { mockDataInterview } from "./mockData";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import {
  AppliedStudents,
  GetSingleInterview,
  ShortListForInterview,
} from "../../../axios/apis";
import { useNavigate, useParams } from "react-router";
import { Spinner } from "react-bootstrap";
import Moment from "react-moment";
import { IconButton, Tooltip } from "@mui/material";
import { MdDone, MdFileCopy } from "react-icons/md";
import "./interview.css";
import Loading from "components/common/Loading";

export const SingleMyInterview = ({ type }) => {
  // console.log(type);
  const [loading, setLoading] = useState(false);
  const [interviewdata, setInterviewData] = useState({});
  const [appliedstudents, setAppliedstudents] = useState([]);
  const params = useParams();
  const [showtable, setshowtable] = useState({
    shortlist: true,
    attempted: false,
  });
  const [showquestions, setshoequestions] = useState(false);
  const [candidates, setcandidates] = useState(0);
  const navigate = useNavigate();
  const GetInterviewDetails = async () => {
    setLoading(true);
    try {
      const response = await GetSingleInterview({ id: params.id });
      const res = await AppliedStudents({ interviewId: params.id });
      setAppliedstudents(res.data.candidates);
      // console.log(res)
      console.log(response);
      setInterviewData(response.data.interview);
      let unique = [
        ...new Set(
          response.data.interview?.interviewResult.map(
            (data) => data.attemptedby_id?._id
          )
        ),
      ];
      // console.log(unique);
      setcandidates(unique.length);
      // debugger;
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  // console.log(interviewdata);
  useEffect(() => {
    GetInterviewDetails();
  }, [params.id]);

  const totalscore = (data) => {
    let newdata = data?.filter((data2) => data2.body !== null);
    let score = newdata?.map((data2) => data2?.body["Total Score"]);
    const res = score.reduce((a, b) => a + b, 0);
    return (res / newdata.length).toFixed(2) === "NaN"
      ? 0
      : (res / newdata.length).toFixed(2);
  };

  const AllowAccess = async (e) => {
    // console.log(e)
    const res = window.confirm("Are you sure you want to Shortlist?");
    if (res) {
      setLoading(true);
      try {
        const data = {
          interviewId: e.interviewId,
          interviewee: e.interviewee._id,
          status: "approved",
        };
        const response = await ShortListForInterview(data);
        GetInterviewDetails();
        // console.log(response);
        toast.success("Shortlisted Successfully", ToastStyles);
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Try Again", ToastStyles);
        setLoading(false);
      }
    }
  };

  const columns2 = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      renderCell: (data) => (
        <div className="d-flex align-items-center">
          {data?.row?.interviewee?.firstname +
            " " +
            data?.row?.interviewee?.lastname || " "}
        </div>
      ),
    },

    {
      field: "createdAt",
      headerName: "Applied Date",
      minWidth: 120,
      renderCell: (data) => (
        <div className="d-flex align-items-center">
          <Moment format="DD-MM-YYYY">{data.value}</Moment>
        </div>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Time",
      minWidth: 120,
      renderCell: (data) => (
        <div className="d-flex align-items-center">
          <Moment format="hh:mm A">{data.value}</Moment>
        </div>
      ),
    },
    {
      field: "Role",
      headerName: "Role",

      minWidth: 180,
      renderCell: (data) => (
        <div className="d-flex align-items-center">
          {data?.row?.interviewee?.role}
        </div>
      ),
    },
    {
      field: "resume",
      headerName: "Resume",
      minWidth: "150",
      renderCell: (data) => (
        <SpanYellow className="c-pointer">
          <a href={data.row.interviewee.resume} target="_blank">
            Resume
          </a>
        </SpanYellow>
      ),
    },
    // {
    //   field: "status",

    //   minWidth: 115,

    //   headerName: "STATUS",
    // },
    // {
    //   field: "Selection",
    //   headerName: "Selection",
    //   minWidth: 120,
    // },
    {
      field: "coverLetter",
      headerName: "Cover Letter",
      minWidth: "150",
      renderCell: (data) => (
        <a
          href={data.row.interviewee.coverletter}
          target="_blank"
          className="d-flex align-items-center text-hover"
        >
          View
        </a>
      ),
    },
    {
      field: "shortlist",
      headerName: "Shortlist",
      minWidth: "120",
      renderCell: (params) => (
        <>
          {params.row.status === "approved" ? (
            <div className="mx-auto">🟢</div>
          ) : (
            <div
              onClick={() => AllowAccess(params.row)}
              className=" c-pointer circle-border-50 mx-2"
            >
              <MdDone color="#219653" fontSize={24} />
            </div>
          )}
        </>
      ),
    },
  ];
  const columns = [
    {
      field: "name",
      headerName: "Name",
      minWidth: 180,
      renderCell: (data) => (
        <div className="d-flex align-items-center">
          {data?.row?.attemptedby_id?.firstname +
            " " +
            data?.row?.attemptedby_id?.lastname || " "}
        </div>
      ),
    },

    {
      field: "createdAt",
      headerName: "Applied Date",
      minWidth: 120,
      renderCell: (data) => (
        <div className="d-flex align-items-center">
          <Moment format="DD-MM-YYYY">{data.value}</Moment>
        </div>
      ),
    },
    {
      field: "updatedAt",
      headerName: "Time",
      minWidth: 120,
      renderCell: (data) => (
        <div className="d-flex align-items-center">
          <Moment format="hh:mm A">{data.value}</Moment>
        </div>
      ),
    },
    {
      field: "Role",
      headerName: "Role",

      minWidth: 120,
      renderCell: (data) => (
        <div className="d-flex align-items-center">
          {data?.row?.attemptedby_id?.role}
        </div>
      ),
    },
    {
      field: "resume",
      headerName: "Resume",
      minWidth: "150",
      renderCell: (data) => {
        console.log("Resume data:", data.row); // Log the whole row data
        return data.row.attemptedby_id && data.row.attemptedby_id.resume ? (
          <SpanYellow className="c-pointer">
            <a href={data.row.attemptedby_id.resume} target="_blank">
              Resume
            </a>
          </SpanYellow>
        ) : (
          "N/A"
        );
      },
    },
    {
      field: "coverletter",
      headerName: "Cover Letter",
      minWidth: "150",
      renderCell: (data) =>
        data.row.attemptedby_id && data.row.attemptedby_id.coverletter ? (
          <SpanYellow
            className="c-pointer"
            onClick={() =>
              window.open(data.row.attemptedby_id.coverletter, "_blank")
            }
          >
            Cover Letter
          </SpanYellow>
        ) : (
          "N/A"
        ),
    },
    {
      field: "video",
      headerName: "Video",
      minWidth: "150",
      renderCell: (data) => (
        <SpanYellow
          className="c-pointer"
          onClick={() =>
            navigate(`/hire-pro/interview-video/${params.id}/${data?.row?._id}`)
          }
        >
          Video
        </SpanYellow>
      ),
    },
    // {
    //   field: "results",
    //   headerName: "Video Score",
    //   minWidth: "150",
    //   renderCell: (params) => (
    //     <div className="d-flex align-items-center">
    //       {totalscore(params.value)}
    //     </div>
    //   ),
    // },
    // {
    //   field: "status",

    //   minWidth: 115,

    //   headerName: "STATUS",
    // },
    // {
    //   field: "Selection",
    //   headerName: "Selection",
    //   minWidth: 120,
    // },
    {
      field: "reports",
      headerName: "Reports",
      minWidth: "150",
      renderCell: (data) => (
        <div
          onClick={() => {
            type === "superadmin"
              ? navigate(`/superadmin/reports/${data.row._id}`)
              : navigate(`/hire-pro/reports/${data.row._id}`);
          }}
          className="d-flex align-items-center text-hover"
        >
          View Reports
        </div>
      ),
    },
  ];
  return (
    <>
      <Toaster />
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller p-0 p-sm-2">
          <div className="container">
            <BoxStyle className="my-1  p-2 p-md-4">
              <div className="d-flex justify-content-between flex-wrap">
                <div className="d-flex align-items-center">
                  <BackButton />
                </div>
                <ButtonYellowFill
                  className="h-40"
                  style={{ width: "120px" }}
                  onClick={() => {
                    if (type === "superadmin") {
                      navigate(`/superadmin/edit-interview/${params.id}`);
                    } else {
                      navigate(`/hire-pro/edit-interview/${params.id}`);
                    }
                  }}
                >
                  Edit
                </ButtonYellowFill>
              </div>
              <div className="p-2 d-flex justify-content-evenly">
                <BoxYellowOutline className="w-0 p-1">
                  <DarkHeading className="text-center mb-0">
                    {candidates}
                  </DarkHeading>

                  <DarkHeading className="fs-14 text-center mb-0">
                    Total Candidates
                  </DarkHeading>
                </BoxYellowOutline>
                {/* <BoxYellowOutline className="w-0 p-1">
                  <DarkHeading className="text-center mb-0">3</DarkHeading>
                  <DarkHeading className="fs-14 mb-0 text-center">
                    Yet to respond
                  </DarkHeading>
                </BoxYellowOutline> */}
                <BoxYellowOutline className="w-0 p-1">
                  <DarkHeading className="text-center mb-0">
                    {interviewdata?.interviewResult?.length || 0}
                  </DarkHeading>
                  <DarkHeading className="fs-14 text-center mb-0">
                    Responses
                  </DarkHeading>
                </BoxYellowOutline>
                {/* <BoxYellowOutline className="w-0 p-1">
                  <DarkHeading className="text-center mb-0">3</DarkHeading>
                  <DarkHeading className="fs-14 text-center mb-0">
                    Shortlisted
                  </DarkHeading>
                </BoxYellowOutline> */}
                {/* <BoxYellowOutline className="w-0 p-1">
                  <DarkHeading className="text-center mb-0">3</DarkHeading>
                  <DarkHeading className="fs-14 text-center mb-0">
                    Hired
                  </DarkHeading>
                </BoxYellowOutline> */}
              </div>
              <div className="my-3  p-2">
                <div className="d-flex justify-content-between">
                  <div>
                    <Paragraph className="mb-0">
                      Job Status{" "}
                      <span>
                        {interviewdata?.draft ? "🔴 Draft" : "🟢 Live"}
                      </span>
                    </Paragraph>
                    <Paragraph className="mb-0">
                      Job Area: {interviewdata?.jobarea}
                    </Paragraph>
                    <Paragraph className="mb-0">
                      Job Location: {interviewdata?.joblocation}
                    </Paragraph>
                    {/* <Paragraph>
                        <Tooltip title="Copy to Clipboard">
                          <IconButton
                            onClick={() => {
                              navigator.clipboard.writeText(
                                window.location.origin +
                                  "/interview/apply-interview/" +
                                  params.id
                              );
                              toast.success(
                                "Link Copied to Clipboard",
                                ToastStyles
                              );
                            }}
                            className="d-flex align-items-center ms-1"
                            style={{
                              fontSize: "16px",
                              padding: "0",
                              margin: "0",
                            }}
                          >
                            Copy Link{" "}
                            <MdFileCopy fontSize={22} color="#521986" />
                          </IconButton>
                        </Tooltip>
                      </Paragraph> */}
                  </div>
                  <div>
                    <Paragraph className="mb-0">
                      Job Category: {interviewdata?.jobcategory}
                    </Paragraph>
                    <Paragraph className="mb-0">
                      Job Type: {interviewdata?.jobtype}
                    </Paragraph>
                  </div>
                  <div>
                    <Paragraph className="mb-0">
                      Created On :-{" "}
                      <Moment format="DD-MM-YYYY">
                        {interviewdata?.createdAt}
                      </Moment>
                    </Paragraph>
                    <Paragraph className="mb-0">
                      Expiry Date :-{" "}
                      <Moment format="DD-MM-YYYY">
                        {interviewdata?.expirydate}
                      </Moment>
                    </Paragraph>
                  </div>
                </div>
                <hr></hr>
                <div className="">
                  <Paragraph className="mb-0 ">
                    Job Title: {interviewdata?.jobtitle} &nbsp;
                    <div
                      style={{ fontSize: "13px" }}
                      dangerouslySetInnerHTML={{
                        __html: interviewdata?.jobdescription,
                      }}
                    ></div>
                  </Paragraph>
                </div>
                {showquestions && (
                  <>
                    {interviewdata?.interviewquestions.map(
                      (question, index) => (
                        <h6>
                          Question {index + 1}: {question.question}
                        </h6>
                      )
                    )}
                  </>
                )}
                <div
                  onClick={() => setshoequestions(!showquestions)}
                  className="d-flex text-hover"
                >
                  {!showquestions ? "View Questions" : "Hide Questions"}
                </div>
              </div>
            </BoxStyle>
            <div className="my-1 p-2 course-btn">
              <div
                className="d-flex align-items-center justify-content-evenly justify-content-sm-start mb-3"
                style={{ marginTop: "10px" }}
              >
                {/* <ButtonBlue
                  className={`px-1 px-sm-3 h-40 ${
                    showtable?.shortlist ? "" : "inactive"
                  }`}
                  onClick={() => {
                    setshowtable({
                      attempted: false,
                      shortlist: true,
                    });
                  }}
                >
                  Applied Candidates
                </ButtonBlue> */}
                <YellowButton
                  className={`mx-1 ${
                    showtable?.attempted ? "px-1 px-sm-3 active" : ""
                  }`}
                  onClick={() => {
                    setshowtable({
                      attempted: true,
                      shortlist: false,
                    });
                  }}
                >
                  Attempted Candidates{" "}
                </YellowButton>
              </div>
              {showtable.attempted ? (
                <>
                  <Table
                    columns={columns}
                    checkbox={false}
                    data={interviewdata?.interviewResult?.map((item) => {
                      return {
                        ...item,
                        id: item._id,
                        key: item._id,
                      };
                    })}
                    limit={10}
                  />
                  {/* <Table
                    columns={columns2}
                    checkbox={false}
                    data={appliedstudents?.map((item) => {
                      return {
                        ...item,
                        id: item._id,
                        key: item._id,
                      };
                    })}
                    limit={10}
                  /> */}
                </>
              ) : (
                <>
                  {/* <Table
                    columns={columns2}
                    checkbox={false}
                    data={appliedstudents?.map((item) => {
                      return {
                        ...item,
                        id: item._id,
                        key: item._id,
                      };
                    })}
                    limit={10}
                  /> */}
                  <Table
                    columns={columns}
                    checkbox={false}
                    data={interviewdata?.interviewResult?.map((item) => {
                      return {
                        ...item,
                        id: item._id,
                        key: item._id,
                      };
                    })}
                    limit={10}
                  />
                </>
              )}
            </div>
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};
