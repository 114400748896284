import cardsample from "assets/image/cardsample.png";
import heartblue from "assets/image/heartblue.png";
import { create_UUID } from "helper/uuid";
import sample from "assets/image/sample.jpg";
import job from "assets/image/job.png";
import expiry from "assets/image/expiry.png";
import time from "assets/image/time.png";
import courseposter1 from "assets/image/1.png";
import courseposter2 from "assets/image/2.png";
import courseposter3 from "assets/image/3.png";
import stock from "assets/image/stock.png";
import heart from "assets/image/heart.png";
import tick from "assets/image/tick.png";
import streak from "assets/image/streak.png";
import time2 from "assets/image/time2.png";
import journey from "assets/image/journey.png";

export const CardData = [
  {
    id: create_UUID(),
    title: "Introduction to Interview training",
    poster:courseposter1,
    description:
      "Interview training is an important aspect of career development, as it helps individuals prepare for job interviews by improving their communication, presentation, and interpersonal skills. This training provides an overview of the interviewing process, teaches effective interview techniques, and helps candidates build confidence and improve their chances of success.",
    image: "https://interview-bot.s3.ap-south-1.amazonaws.com/interview+bot/Interview+Pro/course+content/Interview+Training.m4v",
    icon: heartblue,
    duration :"1 Minute",
    button: "Watch Videos",
  },
  {
    id: create_UUID(),
    title: "Ways to Stand Out to an Employer",
    poster:courseposter2,

    description:
      "In today's competitive job market, it's crucial to find ways to stand out to employers. This can be achieved by highlighting unique skills and experiences, demonstrating a strong work ethic, showcasing a positive attitude, and networking effectively. It's also important to tailor resumes and cover letters to specific job postings and to prepare well for interviews",
    button: "Watch Videos",
    image: "https://interview-bot.s3.ap-south-1.amazonaws.com/interview+bot/Interview+Pro/course+content/7+Ways+to+Standout+to+an+Employer.mp4",
    icon: heartblue,
    duration :"10 Minutes",

  },
  {
    id: create_UUID(),
    title: "Interview Skills Training",
    poster:courseposter3,

    description:
      "Interview skills training is designed to help individuals improve their ability to effectively communicate and present themselves during job interviews. This training covers a range of topics, including how to prepare for an interview, how to make a positive first impression, how to answer common interview questions, and how to handle challenging interview situations.",
    button: "Watch Videos",
    image: "https://interview-bot.s3.ap-south-1.amazonaws.com/interview+bot/Interview+Pro/course+content/Interview+Skills+Training.m4v",
    icon: heartblue,
    duration:"6 Minutes"
  },
  // {
  //   id: create_UUID(),
  //   title: "Most Asked Questions",
  //   description:
  //     `During job interviews, candidates are often asked a range of common questions that help employers assess their skills, experience, and fit for the job. Some of the most frequently asked questions include: "Tell me about yourself," "What are your greatest strengths/weaknesses?," "Why do you want this job?," and "Where do you see yourself in five years?" Preparing well-crafted responses to these questions can help candidates make a positive impression and increase their chances of landing the job.
  //     `,
  //   button: "Watch Videos",
  //   image: cardsample,
  //   icon: heartblue,
  // },
];

export const PracticeCardData = [
  {
    id: create_UUID(),
    logo: sample,
    iconsAndTitle: [
      { icon: tick, title: "Mock Interview" },
      { icon: time, title: "15 minutes" },
      { icon: job, title: "Entry Level" },
      { icon: stock, title: "Difficulty Level 1 " },
    ],
    title: "UI Designer & webflow Developer",
    description:
      "Responsibilities : Should have good Understanding & concept of UI/UX Designing. Simple and professional design ideas using symbolize, icon, and images. Should have an eye for clean and artful design and possess superior UI skills to translate ",
    button: "Watch Videos",
    image: cardsample,
    icon: heart,
    buttons: ["Designer", "Webflow Developer", "UI Developer"],
  },
  {
    id: create_UUID(),
    title: "UI Developer",
    logo: sample,
    iconsAndTitle: [
      { icon: tick, title: "Mock Interview" },
      { icon: time, title: "15 minutes" },
      { icon: job, title: "Entry Level" },
      { icon: expiry, title: "Expire 21/2/2023" },
      { icon: stock, title: "Difficulty Level 1 " },
    ],
    description:
      "Responsibilities : Should have good Understanding & concept of UI/UX Designing. Simple and professional design ideas using symbolize, icon, and images. Should have an eye for clean and artful design and possess superior UI skills to translate ",
    button: "Watch Videos",
    image: cardsample,
    icon: heart,
    buttons: ["Designer", "Webflow Developer", "UI Developer"],
  },
  {
    id: create_UUID(),
    logo: sample,
    iconsAndTitle: [
      { icon: tick, title: "Mock Interview" },
      { icon: time, title: "15 minutes" },
      { icon: job, title: "Entry Level" },
      { icon: stock, title: "Difficulty Level 1 " },
    ],

    title: "Salesforce Developer",
    description:
      "Responsibilities : Should have good Understanding & concept of UI/UX Designing. Simple and professional design ideas using symbolize, icon, and images. Should have an eye for clean and artful design and possess superior UI skills to translate ",
    button: "Watch Videos",
    icon: heart,
    buttons: ["ERP", "ORACLE", "Salesforce"],
  },
];

export const MyGoalData = [
  {
    id: create_UUID(),
    title: "Time Spent",
    description: "See how much time you usually spent.",
    icon: time,
  },
  {
    id: create_UUID(),
    title: "Complete the Daily Goals",
    description: "Take the next step to success",
    icon: streak,
  },
  {
    id: create_UUID(),
    title: "Streak",
    description: "See how much time you usually spent.",
    icon: time2,
  },
  {
    id: create_UUID(),
    title: "Your Journey",
    description: "Journey with us",
    icon: journey,
  },
];

export const MyJourney = [
  {
    id: create_UUID(),
    title: "Joined",
    description: "Joined on 12/12/2022",
  },
  {
    id: create_UUID(),
    title: "Level 0",
    description: "Complete 2 Interviews to Become Master in Interviews",
  },
  {
    id: create_UUID(),
    title: "Level 1",
    description: "Complete 2 Interviews to Become Master in Interviews",
  },
];
