import React, { useEffect } from "react";
import { SideBarAndHeader } from "screen/SideBarAndHeader";
import { useNavigate } from "react-router";
import { CollegeStudentSidePanel } from "contants/CollegeStudentSidePanel";
import { SideBarAndHeaderCollegeStudent } from "screen/SideBarAndHeaderCollegeStudent";
import Cookies from "js-cookie";

export const StudentIndex = () => {
  const navigate = useNavigate();
  let loginToken = Cookies.get("token");
  useEffect(() => {
    if (!loginToken) {
      navigate("/");
    }
  }, [loginToken]);

  return <SideBarAndHeaderCollegeStudent data={CollegeStudentSidePanel} />;
};
