import React, { useEffect, useState } from "react";
import { BiArrowBack } from "react-icons/bi";
import { JolPlayer } from "jol-player";
import sample from "assets/sample.mp4";
import {
  BoxStyle,
  DashboardBoxStyle,
  YellowHeading,
} from "components/common/CommonStyles";
import { CardData } from "../data";
import { Link, useNavigate, useParams } from "react-router-dom";

import { AiFillLike } from "react-icons/ai";
import RecommendedVcard from "./RecommendedVcard";

export const SingleCourse = () => {
  const navigate = useNavigate();
  const params = useParams();
  // console.log(params)
  const [coursedata, setcoursedata] = useState(CardData)
  const [recommendedCourse, setRecommendedCourse] = useState([])
  useEffect(() => {
    let newdata = CardData?.filter(data => data?.id === params.id)
    setcoursedata(newdata)
    let newRec = CardData?.filter(data => data?.id !== params.id)
    setRecommendedCourse(newRec)
  }, [params.id])

  // console.log(coursedata)

  return (
    <DashboardBoxStyle className="scroller">
      <div className="container">
        <div className="row">
          <div className="col-md-12 d-flex align-items-center mt-2">
            <div className="button-cotainer">
              <button className="button-yellow px-1 h-40" style={{ width: "80px" }} onClick={() => navigate(-1)}>
                <BiArrowBack />
                <span>|</span>
                Back
              </button>
            </div>
            <YellowHeading className="mb-0 mx-2">
              {coursedata[0]?.title}
            </YellowHeading>
          </div>
          <div className="col-12 mt-3">
            <div className="row">
              <div className="col-7 w-100-820">
                <video
                  poster={coursedata[0]?.poster}
                  src={coursedata[0]?.image}
                  controls
                  className="w-100"
                />
                <div>
                  <YellowHeading className="fw-600 mt-3">
                    Description
                  </YellowHeading>
                </div>
                <div className="mt-3">
                  <p>
                    {coursedata[0]?.description}
                  </p>
                </div>
              </div>
              <div className="col-5 d-none-820">
                <BoxStyle className="py-3 pb-2 px-0 border-10">
                  <div className="d-flex d-flex justify-content-between mb-2 px-3">
                    <h4>Recommended</h4>
                    <AiFillLike size={26} color="#521986" />
                  </div>
                  <div className="Recommended-vbox">

                    {recommendedCourse?.slice(0, 6).map((item) => (
                      <Link to={`/interviewee/interview-courses/${item?.id}`}>

                        <RecommendedVcard item={item} />
                      </Link>
                    ))}
                  </div>
                </BoxStyle>
              </div>
            </div>
          </div>
        </div>
      </div>
    </DashboardBoxStyle>
  );
};
