import { useState } from 'react';
import Button from 'react-bootstrap/Button';
import Modal from 'react-bootstrap/Modal';
import { ButtonBlue } from './CommonStyles';
import Cookies from 'js-cookie';

function NotPurchasedModal({ show, setShow }) {
   const token = Cookies.get("token")
   const handleClose = () => setShow(false);
   const handleShow = () => setShow(true);

   return (
      <>
         <Modal
            show={show}
            onHide={handleClose}
            backdrop="static"
            keyboard={false}
            centered
         >
            <Modal.Header closeButton>
               <Modal.Title>Unlock the full potential of Interview Pro</Modal.Title>
            </Modal.Header>
            <Modal.Body className='my-5'>
               <p className='text-center'>Get Access to our Interview pro. Buy it from here-
                  <a href={`https://viosa.in/course/6343c15fe33839d145810f31`}>viosa.in/interviewpro</a>
               </p>
            </Modal.Body>
            <Modal.Footer>
               <ButtonBlue className='w-100'>
                  <a href={`https://viosa.in/course/6343c15fe33839d145810f31`}>

                     Buy Now
                  </a>
               </ButtonBlue>
            </Modal.Footer>
         </Modal>
      </>
   );
}

export default NotPurchasedModal;
