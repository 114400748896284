import React from "react";
import { Route, Routes } from "react-router";
import InstructionPage1 from "./InstructionPage1";
import { SkillAssesment } from "./SkillAssesment";
import { VideoPage1 } from "./VideoInterview/VideoPage1";
import { VideoPage2 } from "./VideoInterview/VideoPage2";
import InterviewPageStart from "./InterviewPageStart";
import ApplyInterview from "./ApplyInterview";
import UploadDocuments from "./UploadDocuments";

export const IndexInterview = () => {
  return (
    <>
      <Routes>
        {/* <Route path="/" element={<InterviewPageStart />} /> */}
        <Route path="/:id" element={<InstructionPage1 />} />
        <Route path="/skillassesment/:id" element={<SkillAssesment />} />
        <Route path="/video-interview/:id" element={<VideoPage1 />} />
        <Route path="/video-interview2/:id" element={<VideoPage2 />} />
        <Route path="/apply-interview/:id" element={<ApplyInterview />} />
        <Route path="/upload-documents/:id" element={<UploadDocuments />} />
      </Routes>
    </>
  );
};
