import React, { useEffect, useState } from "react";
import {
  BoxStyle,
  DashboardBoxStyle,
  Input,
  YellowHeading,
} from "components/common/CommonStyles";
import { PracticeCardData } from "../data";
import "./interviewee.css";

import PracticeInterviewCard from "../../../components/Card/PracticeInterviewCard";
import {
  GetAllInterviewAdmin,
  GetAllInterviewCorporate,
  GetStudentsDetails,
} from "../../../axios/apis";
import { Form, Spinner, Pagination } from "react-bootstrap";
import Loading from "components/common/Loading";
import { AiFillEyeInvisible, AiOutlineSearch } from "react-icons/ai";
import Cookies from "js-cookie";
import { useParams } from "react-router-dom";
import {
  MDBPagination,
  MDBPaginationItem,
  MDBPaginationLink,
} from "mdb-react-ui-kit";

export const PracticeInterview = () => {
  const [interview, setInterView] = useState([]);
  const [allinterview, setAllInterView] = useState([]);
  const [loading, setLoading] = useState(true);
  const [search, setSearch] = useState("");
  const [page, setPage] = useState(1);
  const [totalpages, setTotalpages] = useState(1);
  const arr = [
    1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21,
    22, 23, 24, 25, 26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
  ];
  console.log("LocalStorage", localStorage);

  const userID = localStorage.getItem("userId");
  console.log("user ID:", userID);

  const token = Cookies.get("token");
  console.log("token:", token);

  const response = GetStudentsDetails(token);
  console.log("response:", response);
  let userData;
  response.then((data) => {
    console.log(data.data.data._id);
    userData = data.data.data._id; // Saving the data into the userData variable
    console.log("UserID: ", userData);
  });

  const GetAllInterviews = async () => {
    setLoading(true);
    try {
      const response = await GetAllInterviewAdmin({
        page: page,
        limit: 10,
        title: search,
      });
      console.log(response.data.interviews);
      setInterView(response.data.interviews);
      setTotalpages(response.data.totalpages);
      setAllInterView(response.data.interviews);
      // console.log(response.data.interviews);
      setLoading(false);
    } catch (error) {
      console.log(error);
      setLoading(false);
    }
  };

  useEffect(() => {
    GetAllInterviews();
  }, [page]);

  const searchInterview = async () => {
    if (search.length) {
      setPage(1);
      const response = await GetAllInterviewAdmin({
        page: 1,
        limit: 10,
        title: search,
      });
      setTotalpages(response.data.totalpages);
      setInterView(response.data.interviews);
      console.log(response.data.interviews);
      setAllInterView(response.data.interviews);
    } else {
      const response = await GetAllInterviewAdmin({
        page: page,
        limit: 10,
        title: "",
      });
      setTotalpages(response.data.totalpages);
      setInterView(response.data.interviews);
      setAllInterView(response.data.interviews);
    }
  };

  const handleSearch = (e) => {
    setSearch(e.target.value);
    // const searched = e.target.value;
    // if (searched === "") {
    //   setInterView(allinterview)
    // }
    // else {
    //   const searchInterview = allinterview.filter(interview => interview.jobtitle.toLowerCase().includes(searched.toLowerCase()))
    //   setInterView(searchInterview)
    // }
  };

  const params = useParams();
  useEffect(() => {
    if (params?.id) {
      Cookies.set("token", params?.id);
      Cookies.set("role", "interviewee");
    }
    GetAllInterviews();
  }, []);

  return (
    <>
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="container my-0">
            <div className="row">
              <YellowHeading className="my-2">
                Practice Interviews
              </YellowHeading>
              <div className="px-2 mb-3">
                <Form.Control
                  className="form-input"
                  name="search"
                  onChange={(e) => handleSearch(e)}
                  value={search}
                  placeholder="Search"
                  type="text"
                  onKeyDown={(e) => {
                    if (e.key === "Enter") {
                      searchInterview();
                    }
                  }}
                />
                <div className="eye-position">
                  <AiOutlineSearch
                    onClick={searchInterview}
                    className="search-icon pe-1"
                    size={28}
                  />
                </div>
              </div>

              {/* <div className=" d-flex align-items-center ">

                <Input
                  placeholder="Search"
                  name="search"
                  className="search-box-border mb-3 px-5 br-5 password"
                  onChange={(e) => setSearch(e.target.value)}
                ></Input>
                <AiOutlineSearch
                  className="password-eye pe-1"
                  color="#ffa303"
                  size={28}
                />

              </div> */}
              {/* <div className="">
                <div className=" search-box-flex col-12 gap-1">
                  <Form.Group className="col-12">
                    <Form.Control
                      type="text"
                      className="search-box form-input"
                      name="search"
                      value={search}
                      placeholder="Search Jobs"
                      onChange={(e) => setSearch(e.target.value)}
                    />
                  </Form.Group>
                </div>
              </div> */}

              <div className="mt-0">
                <div>
                  {interview.length > 0 ? (
                    interview
                      .filter((item) => {
                        console.log(item);
                        console.log(item.draft);
                        return item.draft === false; // Filter interviews where draft is "true"
                      })
                      .map((item, index) => (
                        <PracticeInterviewCard key={index} item={item} />
                      ))
                  ) : (
                    <>
                      <h6>Currently No Practice Interview.</h6>
                    </>
                  )}
                </div>
                <Pagination className="justify-content-center">
                  <Pagination.First
                    onClick={() => setPage(1)}
                    disabled={page === 1}
                  />
                  <Pagination.Prev
                    onClick={() => setPage((prev) => Math.max(prev - 1, 1))}
                    disabled={page === 1}
                  />
                  {Array.from({ length: totalpages }, (_, i) => (
                    <Pagination.Item
                      key={i}
                      active={page === i + 1}
                      onClick={() => setPage(i + 1)}
                    >
                      {i + 1}
                    </Pagination.Item>
                  ))}
                  <Pagination.Next
                    onClick={() =>
                      setPage((prev) => Math.min(prev + 1, totalpages))
                    }
                    disabled={page === totalpages}
                  />
                  <Pagination.Last
                    onClick={() => setPage(totalpages)}
                    disabled={page === totalpages}
                  />
                </Pagination>
              </div>
              {/* <MDBPagination center className="mb-0">
                {arr.slice(0, totalpages).map((data, i) => (
                  <MDBPaginationItem active={page === i + 1}>
                    <MDBPaginationLink onClick={() => setPage(i + 1)} href="#">
                      {i + 1}
                    </MDBPaginationLink>
                  </MDBPaginationItem>
                ))}
              </MDBPagination> */}
            </div>
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};
