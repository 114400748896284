import styled from "styled-components";
export const BoxStyle = styled.div`
  width: 100%;
  padding: 30px;

  background: ${({ theme, background }) =>
    background ? theme.linearGradient : theme.white};
  border-radius: 20px;
  box-shadow: ${({ theme }) => theme.boxShadow4};
  &.linear {
    background: ${({ theme }) => theme.linearGradient};
  }
  &.bg-none {
    background: transparent;
    box-shadow: none;
  }
  &.border-10 {
    border-radius: 10px;
  }
  &.border-0 {
    border-radius: 0px;
  }
  &.padding-5 {
    padding: 5px;
  }
  &.background-primary {
    background: ${({ theme }) => theme.primary};
  }
  &.h-380 {
    // height: 380px;
  }
  &.scroller {
    overflow-y: scroll;
    height: calc(100vh - 130px);
    margin-top: 10px;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 5px;
      height: 100px;
      background: ${({ theme }) => theme.primary};
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: ${({ theme }) => theme.secondary};
    }
  }
  &.h-200 {
    height: calc(100vh - 231px);
  }
  &.h-100 {
    height: calc(100vh - 130px);
  }
  &.shadow-2 {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  }
`;
export const BoxStyle2 = styled.div`
  width: 100%;
  padding: 30px;

  background: ${({ theme, background }) =>
    background ? theme.linearGradient : theme.white};
  border-radius: 20px;
  box-shadow: ${({ theme }) => theme.boxShadow4};
  &.linear {
    background: ${({ theme }) => theme.linearGradient};
  }
  &.bg-none {
    background: transparent;
    box-shadow: none;
  }
  &.border-10 {
    border-radius: 10px;
  }
  &.border-0 {
    border-radius: 0px;
  }
  &.padding-5 {
    padding: 5px;
  }
  &.background-primary {
    background: ${({ theme }) => theme.primary};
  }
  &.h-380 {
    // height: 380px;
  }
  &.scroller {
    overflow-y: scroll;
    height: calc(100vh - 130px);
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 3px;
      border-radius: 0px;
      height: 100px;
      background: #ffa30308;
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 3px;
      background: ${({ theme }) => theme.secondary};
    }
  }
  &.h-200 {
    height: calc(100vh - 231px);
  }
  &.h-100 {
    height: calc(100vh - 130px);
  }
  &.shadow-2 {
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.16);
  }
`;
export const ReportFrontPage = styled.div`
  width: 100%;
  height: calc(100vh - 130px);
  /* background-image: url("assets/image/Frame 2608489.png"); */
  box-shadow: ${({ theme }) => theme.boxShadow4};
`;
export const BoxStyleBlueLight = styled.div`
  width: 100%;
  padding: 30px;
  background: ${({ theme, background }) =>
    background ? theme.linearGradient : theme.lightBlue1};
  border-radius: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow3};
  &.linear {
    background: ${({ theme }) => theme.linearGradient};
  }
  &.box-shadow-none {
    box-shadow: none;
  }
  &.border-10 {
    border-radius: 10px;
  }
  &.padding-5 {
    padding: 5px;
  }

  &.scroller {
    overflow-y: scroll;
    height: calc(100vh - 130px);
    margin-top: 10px;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 5px;
      height: 100px;
      background: ${({ theme }) => theme.primary};
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: ${({ theme }) => theme.secondary};
    }
  }
  &.h-200 {
    height: calc(100vh - 231px);
  }
`;
export const BoxYellowOutline = styled.div`
  width: 100%;
  padding: 30px;
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 10px;
  background-color: ${({ theme }) => theme.white};
  &.linear {
    background: ${({ theme }) => theme.linearGradient};
  }

  &.w-0 {
    min-width: 150px;
    max-width: fit-content;
  }
  &.h-80 {
    height: 80px;
  }
  &.h-60 {
    height: 60px;
  }
  &.secondary {
    border: 1px solid ${({ theme }) => theme.secondary};
  }
  &.active-button {
    background-color: ${({ theme }) => theme.lightBlue1};
  }
  &.border-10 {
    border-radius: 10px;
  }
  &.padding-5 {
    padding: 5px;
  }
  .secondary {
    border: 1px solid ${({ theme }) => theme.secondary};
  }
  &.scroller {
    overflow-y: scroll;
    height: calc(100vh - 130px);
    margin-top: 10px;
    overflow-x: hidden;

    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 5px;
      height: 100px;
      background: ${({ theme }) => theme.primary};
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: ${({ theme }) => theme.secondary};
    }
  }
  &.h-200 {
    height: calc(100vh - 231px);
  }
`;
export const DashboardBoxStyle = styled.div`
  width: 100%;
  padding: 10px;
  box-shadow: ${({ theme }) => theme.boxShadow};
  &.padding-0 {
    padding: 0;
  }
  &.scroller {
    overflow-y: auto;
    height: calc(100vh - 70px);
    overflow-x: hidden;
    &.h-70 {
      height: calc(100vh - 70px);
    }
    &::-webkit-scrollbar {
      width: 5px;
      border-radius: 5px;
      height: 100px;
      background: ${({ theme }) => theme.primary};
    }
    &::-webkit-scrollbar-thumb {
      border-radius: 5px;
      background: ${({ theme }) => theme.secondary};
    }
  }
`;

export const ButtonBlue = styled.button`
  height: 50px;
  border-radius: 5px;
  width: ${({ width }) => width || "fit-content"};
  background: ${({ theme, color }) => color || theme.secondary};
  color: ${({ theme, color }) => (color ? theme.black : theme.white)};
  text-align: center;
  border: none;
  &.h-40 {
    height: 40px;
    border: none;
  }
  &.w-160 {
    width: 160px;
  }
  &.h-35 {
    height: 35px;
    border: none;
  }
  &.box-shadow {
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
      0px 1px 3px rgba(0, 0, 0, 0.3);
  }
  &.primary {
    background: ${({ theme }) => theme.primary};
    border: none;
    color: ${({ theme, color }) => theme.white};
  }
  &.orange{
    background:${({ theme, color }) => theme.primary};
  }
  &.green{
    background:rgb(72, 222, 72);
  }
  &.red{
    background:red;
    cursor: not-allowed;
  }
  &.table-button {
    background: ${({ theme }) => theme.lightBlue1};
    border-radius: 20px;
    height: 35px;
    border: 2px solid ${({ theme }) => theme.success};
    color: ${({ theme }) => theme.success};
  }
  &.table-button-danger {
    background: ${({ theme }) => theme.danger};
    border-radius: 20px;
    height: 35px;
    border: 2px solid ${({ theme }) => theme.danger};
    color: ${({ theme }) => theme.white};
  }
  &.disabled {
    background: ${({ theme }) => theme.lightBlue1};
    color: #000;
    font-weight: 600;
  }
  &.secondary-2 {
    background: #fff;
    color: #828282;
    width: 100%;
    border: 2px solid #828282;
  }
  &.secondary-2-active {
    background: #4f4f4f;
  }
  &.success {
    background: ${({ theme }) => theme.success};
    border: none;
    color: ${({ theme, color }) => theme.white};
  }
  &.fw-700 {
    font-weight: 700;
  }
  &.fs-20 {
    font-size: 20px;
  }

  &.inactive {
    background: none;
    outline: none;
    border: none;
    color: ${({ theme }) => theme.primary};
    font-weight: 600;
    font-size: 18px;
  }
`;
export const ButtonYellowFill = styled.button`
  height: 50px;
  border-radius: 5px;
  width: ${({ width }) => width || "100%"};
  background: ${({ theme, color }) => color || theme.primary};
  color: ${({ theme, color }) => (color ? theme.black : theme.white)};
  text-align: center;
  border: none;
  &.h-40 {
    height: 40px;
    border: none;
  } &.bg-red{
    background: red;
  }
  &.bg-green{
    background: green;
  }
  &.box-shadow {
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
      0px 1px 3px rgba(0, 0, 0, 0.3);
  }
  &.primary {
    background: ${({ theme }) => theme.primary};
    border: none;
    color: ${({ theme, color }) => theme.white};
  }
  &.inactive {
    background: none;
    outline: none;
    border: none;
    color: ${({ theme }) => theme.primary};
    font-weight: 600;
    font-size: 18px;
  }
  &:hover {
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
      0px 1px 3px rgba(0, 0, 0, 0.3);
  }
`;
export const ButtonWhite = styled.button`
  height: 39px;
  border-radius: 0px;
  width: 150px;
  border: none;
  outline: none;
  background: ${({ theme }) => theme.white};
  color: ${({ theme }) => theme.black};
  text-align: center;
  &.h-40 {
    height: 40px;
    border: none;
  }
  &.box-shadow {
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
      0px 1px 3px rgba(0, 0, 0, 0.3);
  }
  &.inactive {
    background: none;
    outline: none;
    border: none;
    color: ${({ theme }) => theme.primary};
    font-weight: 600;
    font-size: 18px;
  }
  &.br-5 {
    border-radius: 5px;
  }
`;
export const ButtonYellowOutline = styled.button`
  height: 50px;
  //   width: 100%;
  //   padding: 15px;
  border-radius: 5px;
  padding: 0px 20px;

  border: 2px solid ${({ theme }) => theme.primary};
  color: ${({ theme }) => theme.primary};
  text-align: center;
  &:hover {
    box-shadow: 0px 4px 8px 3px rgba(0, 0, 0, 0.15),
      0px 1px 3px rgba(0, 0, 0, 0.3);
  }
  &.h-40 {
    height: 40px;
    padding: 5px 10px;
  }
  
`;
export const ButtonPurpleOutline = styled.button`
  height: 40px;
  //   width: 100%;
  //   padding: 15px;
  border-radius: 5px;
  padding: 5px 8px;
  border: 2px solid ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.secondary};
  background: transparent;
  &.fw-500 {
    font-weight: 500;
  }
  &.fs-16 {
    font-size: 16px;
  }
  &.h-auto{
    height: auto;
  }
  &.secondary {
    color: ${({ theme }) => theme.secondary};
  }

  text-align: center;
  &:hover {
    background: ${({ theme }) => theme.secondary};
    color: ${({ theme }) => theme.primary};
  }
`;

export const YellowButton = styled.button`
  color: ${({ theme }) => theme.primary};
  border: none;
  background: none;
  outline: none;
  font-size: 18px;
  font-weight: 600;
  &.active {
    color: ${({ theme }) => theme.secondary};
    background: ${({ theme }) => theme.secondary};
    border-radius: 5px;
    color: ${({ theme }) => theme.white};
    font-weight: 400;
    font-size: 16px;
    height: 40px;
  }
`;

export const YellowHeading = styled.h1`
  color: ${({ theme }) => theme.primary};
  font-size: 26px;
  font-weight: 800;
  font-family: ${({ theme }) => theme.fontFamily2};
  &.fs-14 {
    font-size: 14px;
  }
  &.fs-16 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 13px;
    color: ${({ theme }) => theme.primaryDark};
  }
  &.secondary {
    color: ${({ theme }) => theme.secondary};
  }
  &.fs-16-2 {
    font-size: 16px;
    font-weight: 500;
    margin-top: 13px;
    color: ${({ theme }) => theme.primary};
  }
  &.fs-20 {
    font-size: 20px;
  }
  &.fs-30 {
    font-size: 30px;
    font-weight: 800;
  }
  &.fw-600 {
    font-weight: 600;
  }
`;
export const SpanYellow = styled.span`
  color: ${({ theme }) => theme.primary};
  font-size: 14px;
  font-weight: 700;
  &.fs-12 {
    font-size: 12px;
    font-weight: 500;
  }
  &.fw-600 {
    font-weight: 600;
  }
`;

export const DarkHeading = styled.h2`
  font-size: 24px;
  line-height: 32px;
  color: ${({ theme }) => theme.black};
  font-weight: 700;
  &.fs-12 {
    font-size: 12px;
    font-weight: 500;
  }
  &:focus{
    border: none;
  }
  &.fw-600 {
    font-weight: 600;
  }
  &.fw-500 {
    font-weight: 500;
  }
  &.color-secondary {
    color: ${({ theme }) => theme.secondary};
  }
  &.fs-14 {
    font-size: 16px !important;
  }
`;
export const PurpuleHeading = styled.h2`
  font-size: 18px;
  line-height: 32px;
  color: ${({ theme }) => theme.secondary};
  font-weight: 700;
  &.fw-600 {
    font-weight: 600;
  }
  &.fs-14 {
    font-size: 16px !important;
  }
`;
export const Paragraph = styled.p`
  font-size: 16px;
  line-height: 32px;
  font-family: "Inter", sans-serif;
  color: ${({ theme }) => theme.black};
  font-weight: 500;
  &.secondary {
    color: ${({ theme }) => theme.secondary};
  }
  &.line-22 {
    line-height: 22px;
  }
  &.fs-low {
    opacity: 0.5;
  }
  &.fs-14 {
    font-size: 14px;
  }
  &.line-15 {
    line-height: 15px;
  }
  &.fs-12 {
    font-size: 12px;
    line-height: 18px;
    font-weight: 400;
  }
  &.fw-600 {
    font-weight: 600;
  }
  &.fw-500 {
    font-weight: 500;
  }
  &.fs-20 {
    font-size: 20px;
    font-weight: 500;
  }
`;

export const PinkButton = styled.button`
  background: ${({ theme }) => theme.pinkLight};
  color: ${({ theme }) => theme.secondary};
  border: none;
  outline: none;
  font-size: 14px;
  font-family: "Inter", sans-serif;
  padding: 5px 20px;
  height: 35px;
  border-radius: 10px;
  cursor: text;
`;
export const CircleStep = styled.div`
  background: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.white};
  border: 4px solid ${({ theme }) => theme.primary};
  border-radius: 50%;
  font-size: 24px;
  font-weight: 700;
  text-align: center;
  height: 50px;
  position: relative;
  width: 50px;
  &.last::after {
    content: "";
    position: absolute;
    top: -100px !important;
    height: 0em !important;
    overflow: hidden;
  }

  &.other::before {
    content: "";
    position: absolute;
    top: 0.9em;
    transform: rotate(90deg);
    right: -26px;
    width: 4em;
    height: 0.2em;
    background: ${({ theme }) => theme.primary};
    z-index: -1;
  }
`;

export const Input = styled.input`
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 0px;
  width: 100%;
  &.password{
    border-radius: 5px 0px 0px 5px;
     border: 1px solid #fff;
  }
  &.h-65 {
    height: 65px;
    border: 1px solid ${({ theme }) => theme.secondary};
    border-radius: 10px;
    &::placeholder {
      opacity: 0.8;
      color: ${({ theme }) => theme.secondary};
      text-align: left;
    }
  }
  &::placeholder {
    opacity: 0.5;
    text-align: center;
  }
  &.h-20 {
    height: 20px;
  }
  height: 40px;
  padding: 0px 10px;
  &:focus {
    outline: none;
  }
  &.h-40{
    height: 40px;
  }
  &.b-none{
    border: none; 
  }
`;
export const Select = styled.select`
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 0px;
  width: 100%;
  &::placeholder {
    opacity: 0.5;
    text-align: center;
  }
  &.h-60 {
    height: 60px;
  }
  &.secondary {
    border: 1px solid ${({ theme }) => theme.secondary};
  }
  &.b-none {
    border: none;
  }
  &.border-10 {
    border-radius: 10px;
  }
  &.h-65 {
    height: 65px;
  }
  height: 40px;
  padding: 0px 10px;
  &:focus {
    outline: none;
  }
`;
export const TextArea = styled.input`
  background: ${({ theme }) => theme.white};
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 0px;
  width: 100%;
  &::placeholder {
    opacity: 0.8;
    color: ${({ theme }) => theme.secondary};

    text-align: center;
  }
  &.h-60 {
    height: 60px;
  }
  &.secondary {
    border: 1px solid ${({ theme }) => theme.secondary};
  }
  &.b-none {
    border: none;
    color: black;
    font-weight: 600;
    width:220px !important;
  }
  &.border-10 {
    border-radius: 10px;
  }
  &.h-65 {
    height: 65px;
  }
  height: 40px;
  padding: 0px 10px;
  &:focus {
    outline: none;
  }
`;

export const SearchInput = styled.input`
  height: 60px;
  width: 100%;
  border-radius: 4px;
  border: none;
  padding: 0px 20px;
  &.h-50 {
    height: 50px;
  }
  &:focus {
    outline: none;
  }
  &.border-10 {
    border-radius: 10px;
  }
`;

export const Wrapper = styled.div`
  border: 2px solid ${({ theme }) => theme.primary};
  border-radius: 8px;
  position: relative;
  &.border-1 {
    border: 1px solid ${({ theme }) => theme.primary};
  }
`;
export const TextEditorBox = styled.div`
  border: 1px solid ${({ theme }) => theme.secondary};
  border-radius: 10px;
  padding: 10px;
  &.border-2 {
    border: 2px solid ${({ theme }) => theme.secondary};
  }
`;

export const Circle = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme, color }) =>
    color ? theme.secondary : theme.primary};
`;
export const CircleSuccess = styled.div`
  height: 50px;
  width: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  background-color: ${({ theme }) => theme.primary};
`;

export const InputOutline = styled.input`
  border: 1px solid ${({ theme }) => theme.primary};
  border-radius: 0px;
  width: 100%;
  &::placeholder {
    opacity: 0.5;
    text-align: center;
  }
`;
