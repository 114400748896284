import moment from "moment";
import React, { useEffect, useState } from "react";
import Moment from "react-moment";
import { BarChart, Bar, XAxis, YAxis, CartesianGrid } from "recharts";

export const Multicolorbar = ({data}) => {
  const [viewWidth, setViewWidth] = useState(800)
  useEffect(() => {
    setViewWidth(window.innerWidth - 350);
  }, [window.innerWidth]);
  const today = moment()
  const res = Array(7).fill().map(
    () => today.subtract(1, 'd').format('DD-MM')
  );
  // console.log(data)
  // Sample data
  const Chartdata = [
    { name: res[0], x: data[0] || 0 },
    { name: res[1], x: data[1] || 0 },
    { name: res[2], x: data[2] || 0 },
    { name: res[3], x: data[3] || 0 },
    { name: res[4], x: data[4] || 0 },
    { name: res[5], x: data[5] || 0 },
    { name: res[6], x: data[6] || 0 },
  ];

  return (
    <BarChart width={viewWidth} height={500} data={Chartdata}>
      {/* <CartesianGrid /> */}
      <XAxis dataKey="name" />
      <YAxis />
      <Bar
        dataKey="x"
        radius={[20, 20, 0, 0]}
        width={20}
        barSize={20}
        widths={[20, 20, 20, 20]}
        stackId="a"
        fill="#521986"
      />
      <Bar dataKey="y" radius={[0, 0, 0, 0]} stackId="a" fill="#DDB8FF" />
      <Bar dataKey="z" radius={[20, 20, 0, 0]} stackId="a" fill="#FFA303" />
    </BarChart>
  );
};
