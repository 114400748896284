import { SidePanelDataInterviewer } from "contants/SidePanelDataInterviewer";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { SideBarHeaderInterviewer } from "screen/SideBarHeaderInterviewer";

const IndexInterviewer = () => {
  const navigate = useNavigate();
  let loginToken = Cookies.get("token");
  useEffect(() => {
    if (!loginToken) {
      navigate("/");
    }
  }, [loginToken]);
  return <SideBarHeaderInterviewer data={SidePanelDataInterviewer} />;
};

export default IndexInterviewer;
