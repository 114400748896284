import { initializeApp } from "firebase/app";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// import { getAnalytics } from "firebase/analytics";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries
import {
  getAuth,
  GoogleAuthProvider,
} from "firebase/auth";

const firebaseConfig = {
  apiKey: "AIzaSyCVJ3RWTuVgXzmSaYmM0U1wuj8KxV_EByY",
  authDomain: "viosa-ai.firebaseapp.com",
  projectId: "viosa-ai",
  storageBucket: "viosa-ai.appspot.com",
  messagingSenderId: "173397455521",
  appId: "1:173397455521:web:01489752a810f33a74b6e0",
  measurementId: "G-VGLNDH7M15"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export default app;
// export const db = getFirestore(app);
export const storage = getStorage(app);
export const googleAuthProvider = new GoogleAuthProvider();


export const auth = getAuth();
