import React from "react";
import { SidePanelPrimaryBox, SidePanelSecondaryBox } from "./SidepanelStyled";
import logo from "assets/image/logo.svg";
import logosmall from "assets/image/logosmall.png";
import Cookies from "js-cookie";
export const SidePanel = ({ children, hideSidebar }) => {
  const role = Cookies.get("role");
  const profileImg = Cookies.get("profileImg");
  const logoStyle = {
    maxWidth: "100px", // Adjust the width as needed
    maxHeight: "100px", // Adjust the height as needed
    objectFit: "contain", // Ensures the image covers the area without distortion
  };
  return (
    <div className="sidebar ">
      <SidePanelSecondaryBox className="aligncenter">
        {hideSidebar ? (
          <img className="small-logo" src={logosmall} />
        ) : (
          <img
            src={role === "corporate" && profileImg ? profileImg : logo}
            style={logoStyle}
          />
        )}
      </SidePanelSecondaryBox>
      <SidePanelPrimaryBox
        className={`${role === "corporate" ? "bg-purple" : ""}`}
      >
        {children}
      </SidePanelPrimaryBox>
    </div>
  );
};
