import {
  BoxStyle,
  BoxYellowOutline,
  ButtonBlue,
  DarkHeading,
  DashboardBoxStyle,
  Input,
  Paragraph,
  YellowHeading,
} from "components/common/CommonStyles";
import React from "react";
import { UploadButtonInput } from "components/common/UploadButton";
import { Accordion } from "react-bootstrap";
import { BsTextLeft } from "react-icons/bs";
import { AiFillEdit, AiOutlineDelete } from "react-icons/ai";

export const QuestionAnswer = ({ type }) => {
  return (
    <DashboardBoxStyle className="scroller">
      <div className="container my-3">
        <div className="row">
          <div className="col-8">
            <BoxStyle className="border-10">
              <div className="d-flex justify-content-between align-items-center">
                <DarkHeading className="fs-20">Interview Questions</DarkHeading>
                <ButtonBlue className="px-2">+ Add Question</ButtonBlue>
              </div>

              <div>
                <label>Enter Your Question</label>
                <Input
                  placeholder="Enter Your Question"
                  className="h-65 mb-2"
                />
                <Input
                  placeholder="Keywords required in answers"
                  className="h-65 mb-2"
                />
                <Input placeholder="Time Duration" className="h-65 mb-2" />
                <UploadButtonInput />
              </div>
              <div className="col-12 d-flex justify-content-end mt-4 mb-2">
                <ButtonBlue className="px-5 mx-2">Add the Question</ButtonBlue>
              </div>
              <div>
                <label>List of Preparation Question </label>
                <BoxYellowOutline className="secondary h-80 p-2">
                  <div className="">
                    <div>
                      <DarkHeading className="color-secondary fs-14 fw-500 mb-1">
                        Question Number One
                      </DarkHeading>
                    </div>
                    <div className="d-flex justify-content-between align-items-center">
                      <DarkHeading className="color-secondary fs-14 fw-500">
                        <BsTextLeft /> Free text
                      </DarkHeading>

                      <div>
                        <AiFillEdit />
                        <a className="pt-2 mx-2" style={{ color: "#521986" }}>
                          Edit
                        </a>
                        <AiOutlineDelete color="#BA1A1A" />{" "}
                        <a className="pt-2" style={{ color: "#BA1A1A" }}>
                          Delete
                        </a>
                      </div>
                    </div>
                  </div>
                </BoxYellowOutline>
              </div>
              <div className="col-12 d-flex justify-content-end mt-4 mb-2">
                <ButtonBlue className="px-5 mx-2">Add the Question</ButtonBlue>
              </div>
            </BoxStyle>
          </div>
          <div className="col-4 my-1">
            <div className="">
              <BoxStyle className="pt-3 p-2 border-10  px-2">
                <div className="col-12 mb-3">
                  <div className="row mb-1">
                    <div className="col-10">
                      <Paragraph className="mb-1 fw-600 fs-14 mx-1">
                        Video Question Bank
                      </Paragraph>
                    </div>
                    <div className="mt-2">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div className="me-2 d-flex flex-column align-items-center">
                              <div>
                                <YellowHeading className="fs-14 fw-600 secondary">
                                  Functional/ Job Skills
                                </YellowHeading>
                                <YellowHeading className="fs-14 mb-0 fw-600">
                                  10 Skills
                                </YellowHeading>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="d-flex flex-column">
                            <p>&nbsp; &nbsp; Abc Group</p>
                            <p>&nbsp; &nbsp; Viosa AI</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    <div className="mt-2">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div className="me-2 d-flex flex-column align-items-center">
                              <div>
                                <YellowHeading className="fs-14 fw-600 secondary">
                                  Functional/ Job Skills
                                </YellowHeading>
                                <YellowHeading className="fs-14 mb-0 fw-600">
                                  10 Skills
                                </YellowHeading>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="d-flex flex-column">
                            <p>&nbsp; &nbsp; Abc Group</p>
                            <p>&nbsp; &nbsp; Viosa AI</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    <div className="mt-2">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div className="me-2 d-flex flex-column align-items-center">
                              <div>
                                <YellowHeading className="fs-14 fw-600 secondary">
                                  Functional/ Job Skills
                                </YellowHeading>
                                <YellowHeading className="fs-14 mb-0 fw-600">
                                  10 Skills
                                </YellowHeading>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="d-flex flex-column">
                            <p>&nbsp; &nbsp; Abc Group</p>
                            <p>&nbsp; &nbsp; Viosa AI</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    <div className="mt-2">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div className="me-2 d-flex flex-column align-items-center">
                              <div>
                                <YellowHeading className="fs-14 fw-600 secondary">
                                  Functional/ Job Skills
                                </YellowHeading>
                                <YellowHeading className="fs-14 mb-0 fw-600">
                                  10 Skills
                                </YellowHeading>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="d-flex flex-column">
                            <p>&nbsp; &nbsp; Abc Group</p>
                            <p>&nbsp; &nbsp; Viosa AI</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    <div className="mt-2">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div className="me-2 d-flex flex-column align-items-center">
                              <div>
                                <YellowHeading className="fs-14 fw-600 secondary">
                                  Functional/ Job Skills
                                </YellowHeading>
                                <YellowHeading className="fs-14 mb-0 fw-600">
                                  10 Skills
                                </YellowHeading>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="d-flex flex-column">
                            <p>&nbsp; &nbsp; Abc Group</p>
                            <p>&nbsp; &nbsp; Viosa AI</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    <div className="mt-2">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div className="me-2 d-flex flex-column align-items-center">
                              <div>
                                <YellowHeading className="fs-14 fw-600 secondary">
                                  Functional/ Job Skills
                                </YellowHeading>
                                <YellowHeading className="fs-14 mb-0 fw-600">
                                  10 Skills
                                </YellowHeading>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="d-flex flex-column">
                            <p>&nbsp; &nbsp; Beginer</p>
                            <p>&nbsp; &nbsp; Advance</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                    <div className="mt-2">
                      <Accordion>
                        <Accordion.Item eventKey="0">
                          <Accordion.Header>
                            <div className="me-2 d-flex flex-column align-items-center">
                              <div>
                                <YellowHeading className="fs-14 fw-600 secondary">
                                  Behavioural / Functional Skills
                                </YellowHeading>
                                <YellowHeading className="fs-14 mb-0 fw-600">
                                  10 Skills
                                </YellowHeading>
                              </div>
                            </div>
                          </Accordion.Header>
                          <Accordion.Body className="d-flex flex-column">
                            <p>&nbsp; &nbsp; React JS</p>
                            <p>&nbsp; &nbsp; Angular JS</p>
                          </Accordion.Body>
                        </Accordion.Item>
                      </Accordion>
                    </div>
                  </div>
                </div>
                <div>
                  <YellowHeading className="fs-14 text-center fw-700 secondary">
                    List More +
                  </YellowHeading>
                </div>
                <div className="col-12 mb-2">
                  <ButtonBlue className="w-100">+ Create Category</ButtonBlue>
                </div>
              </BoxStyle>
            </div>
          </div>
        </div>
      </div>
    </DashboardBoxStyle>
  );
};
