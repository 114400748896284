
import { ButtonBlue, ButtonYellowOutline } from "components/common/CommonStyles";
import React, { useCallback, useRef, useState } from "react";
import Webcam from "react-webcam";

export const WebCam = ({height,width}) => {
  const webcamRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);

  const handleDataAvailable = useCallback(
    ({ data }) => {
      if (data.size > 0) {
        setRecordedChunks((prev) => prev.concat(data));
      }
    },
    [setRecordedChunks]
  );

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener(
      "dataavailable",
      handleDataAvailable
    );
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef, handleDataAvailable]);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
  }, [mediaRecorderRef, setCapturing]);

  const handleDownload = useCallback(() => {
    if (recordedChunks.length) {
      const blob = new Blob(recordedChunks, {
        type: "video/webm",
      });
      const url = URL.createObjectURL(blob);
      const a = document.createElement("a");
      document.body.appendChild(a);
      a.style = "display: none";
      a.href = url;
      a.download = "react-webcam-stream-capture.webm";
      a.click();
      window.URL.revokeObjectURL(url);
      setRecordedChunks([]);
    }
  }, [recordedChunks]);

  const videoConstraints = {
    height: height|| 420,
    facingMode: "user",
    borderRadius: "20px",
  };

  return (
    <div className="Container">
      <div className="d-flex justify-content-center main-video">
        <Webcam
        style={{objectFit:"cover"}}
          height={height||400}
          width={width}
          audio={true}
          mirrored={true}
          ref={webcamRef}
          videoConstraints={videoConstraints}
        />
      </div>
      {/* <div className="d-flex justify-content-center my-2">
        {capturing ? (
          <ButtonBlue onClick={handleStopCaptureClick} className="px-2">
            Stop Capture
          </ButtonBlue>
        ) : (
          <ButtonBlue onClick={handleStartCaptureClick} className="px-2">
            Start Capture
          </ButtonBlue>
        )}
        {recordedChunks.length > 0 && (
          <ButtonYellowOutline onClick={handleDownload}>
            Download
          </ButtonYellowOutline>
        )}
      </div> */}
    </div>
  );
};
