import {
  BoxStyle,
  ButtonBlue,
  DashboardBoxStyle,
  YellowHeading,
} from "components/common/CommonStyles";
import React, { useEffect, useState, useMemo } from "react";
import { useNavigate } from "react-router";
import {
  GetAllCreatorInterview,
  GetAllCreatorInterviewByLimit,
  UpdateInterview,
  UpdateQuestions,
} from "../../../axios/apis";
import { Table } from "components/Table/Table";
import Moment from "react-moment";
import { Spinner } from "react-bootstrap";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import { MdFileCopy } from "react-icons/md";
import { IconButton, Tooltip } from "@mui/material";
import Loading from "components/common/Loading";
import ReactPaginate from "react-paginate";
export const MyInterview = ({ type }) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(true);
  const [interview, setInterView] = useState([]);
  const [page, setPage] = useState(1);
  const [totalpages, setTotalpages] = useState(1);
  const [jobTitleFilter, setJobTitleFilter] = useState("");
  const [jobLocationFilter, setJobLocationFilter] = useState("");
  const [statusFilter, setStatusFilter] = useState("");
  const [showExpiredOnly, setShowExpiredOnly] = useState(false); // State for the checkbox
  // const GetAllInterviews = async () => {
  //   setLoading(true);
  //   try {
  //     const response = await GetAllCreatorInterviewByLimit({
  //       page: page,
  //       limit: 50,
  //       title: "",
  //     });
  //     console.log("response", response);
  //     // Filter interviews based on creator_type
  //     // const corporateInterviews = response.data.interviews.filter(
  //     //   (interview) => interview.creator_type === "corporate"
  //     // );
  //     // Filter interviews based on the provided criteria
  //     // Convert date strings to Date objects for comparison
  //     const currentDate = new Date();
  //     const filteredInterviews = response.data.interviews.filter(
  //       (interview) => {
  //         const expiryDate = new Date(interview.expirydate);
  //         const isExpired = expiryDate < currentDate;

  //         // // Only show expired interviews if the checkbox is checked
  //         // if (showExpiredOnly && !isExpired) {
  //         //   return false;
  //         // }
  //         // return (
  //         //   (jobTitleFilter
  //         //     ? interview.jobtitle
  //         //         .toLowerCase()
  //         //         .includes(jobTitleFilter.toLowerCase())
  //         //     : true) &&
  //         //   (jobLocationFilter
  //         //     ? interview.joblocation
  //         //         .toLowerCase()
  //         //         .includes(jobLocationFilter.toLowerCase())
  //         //     : true) &&
  //         //   (statusFilter
  //         //     ? statusFilter === "Draft"
  //         //       ? interview.draft === true
  //         //       : interview.draft === false
  //         //     : true)
  //         // );
  //         // Only show expired interviews if the checkbox is checked
  //         if (showExpiredOnly) {
  //           return isExpired;
  //         } else {
  //           return !isExpired;
  //         }
  //       }
  //     );
  //     setTotalpages(response.data.totalpages);
  //     // setInterView(response.data.interviews);
  //     // setInterView(corporateInterviews);
  //     setInterView(filteredInterviews);
  //     // console.log(response.data.interviews);
  //     setLoading(false);
  //   } catch (error) {
  //     console.log(error);
  //     toast.error("Try Again", ToastStyles);
  //     setLoading(false);
  //   }
  // };
  const GetAllInterviews = async () => {
    setLoading(true);
    try {
      const response = await GetAllCreatorInterviewByLimit({
        page: page,
        limit: 50,
        title: "",
      });

      // Convert date strings to Date objects for comparison
      const currentDate = new Date();

      const filteredInterviews = response.data.interviews.filter(
        (interview) => {
          const expiryDate = new Date(interview.expirydate);
          const isExpired = expiryDate < currentDate;

          // Filter based on job title, job location, and status
          const jobTitleMatches = jobTitleFilter
            ? interview.jobtitle
                .toLowerCase()
                .includes(jobTitleFilter.toLowerCase())
            : true;
          const jobLocationMatches = jobLocationFilter
            ? interview.joblocation
                .toLowerCase()
                .includes(jobLocationFilter.toLowerCase())
            : true;
          const statusMatches = statusFilter
            ? statusFilter === "Draft"
              ? interview.draft === true
              : interview.draft === false
            : true;

          // Combine all filters including expired interviews filter
          const showInterview =
            jobTitleMatches && jobLocationMatches && statusMatches;

          // Show only expired interviews if the checkbox is ticked, otherwise show only non-expired interviews
          if (showExpiredOnly) {
            return showInterview && isExpired;
          } else {
            return showInterview && !isExpired;
          }
        }
      );

      setTotalpages(response.data.totalpages);
      setInterView(filteredInterviews);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };

  // useEffect(() => {
  //   GetAllInterviews();
  // }, [page]);
  // const handlePageChange = ({ selected }) => {
  //   setPage(selected + 1);
  // };

  useEffect(() => {
    GetAllInterviews();
  }, [page, statusFilter, jobLocationFilter, showExpiredOnly]);

  const handlePageChange = ({ selected }) => {
    setPage(selected + 1);
  };

  const handleFilterChange = (e) => {
    const { name, value } = e.target;
    if (name === "jobTitle") {
      setJobTitleFilter(value);
    } else if (name === "jobLocation") {
      setJobLocationFilter(value);
    }
  };
  const handleLocationFilterChange = (e) => {
    setJobLocationFilter(e.target.value);
  };
  const handleFilterSubmit = (e) => {
    if (e.key === "Enter") {
      GetAllInterviews();
    }
  };

  const handleStatusFilterChange = (e) => {
    setStatusFilter(e.target.value);
  };
  const handleChangeStatus = async (id, newStatus) => {
    setLoading(true);
    console.log(id, newStatus);
    let data = {
      id: id,
      updateBody: {
        draft: newStatus,
      },
    };
    try {
      const response = await UpdateInterview(data);
      console.log("response", response);
      if (response.status == 200) {
        toast.success("Status updated successfully", ToastStyles);
        // Refresh the interviews list
        GetAllInterviews();
      } else {
        toast.error("Failed to update status", ToastStyles);
        // Refresh the interviews list
        GetAllInterviews();
      }
    } catch (error) {
      console.log(error);
      toast.error("An error occurred", ToastStyles);
    } finally {
      setLoading(false);
    }
  };

  // const columns = [
  //   {
  //     field: "jobtitle",
  //     headerName: "ROLE",
  //     minWidth: 200,
  //     renderCell: (data) => (
  //       <div
  //         className="d-flex
  //       align-items-center "
  //         onClick={() => {
  //           // console.log(data);
  //         }}
  //       >
  //         {data?.value}
  //       </div>
  //     ),
  //   },
  //   {
  //     field: "joblocation",
  //     headerName: "JOB LOCATION",
  //     minWidth: 200,
  //     // renderCell: (data) => (
  //     //   <div
  //     //     className="d-flex
  //     //   align-items-center ms-4"
  //     //     onClick={(e) => {
  //     //       console.log(e);
  //     //     }}
  //     //   >
  //     //     {data?.value}
  //     //   </div>
  //     // ),
  //   },
  //   {
  //     field: "createdAt",
  //     headerName: "CREATED ON",
  //     minWidth: 120,
  //     renderCell: (data) => (
  //       <div className="d-flex align-items-center">
  //         <Moment format="DD-MM-YYYY">{data.value}</Moment>
  //       </div>
  //     ),
  //   },
  //   {
  //     field: "expirydate",
  //     headerName: "EXPIRY DATE",
  //     minWidth: 120,
  //     renderCell: (data) => (
  //       <div className="d-flex align-items-center">
  //         {" "}
  //         <Moment format="DD-MM-YYYY">{data.value}</Moment>
  //       </div>
  //     ),
  //   },
  //   // {
  //   //   field: "student",
  //   //   headerName: "STUDENTS",
  //   //   minWidth: 150,
  //   //   renderCell: (data) => (
  //   //     <div className="d-flex align-items-center ">{data.row?.attemptedby?.length||0}</div>
  //   //   ),
  //   // },
  //   {
  //     field: "interviewResult",
  //     headerName: "RESPONSES",
  //     minWidth: 150,
  //     renderCell: (data) => (
  //       <div className="d-flex align-items-center">
  //         {data.value.length || 0}
  //       </div>
  //     ),
  //   },
  //   {
  //     field: "draft",
  //     headerName: "STATUS",
  //     minWidth: 70,
  //     renderCell: (params) => (
  //       <div className="d-flex align-items-center justify-content-center">
  //         {params.value ? "🔴" : "🟢"}
  //       </div>
  //     ),
  //   },

  //   // {
  //   //   field: "shortlist",
  //   //   headerName: "SHORTLISTED",
  //   //   minWidth: 150,

  //   //   renderCell: (params) => (
  //   //     <div className="d-flex align-items-center ms-4">{params.value}</div>
  //   //   ),
  //   // },

  //   {
  //     field: "share",
  //     headerName: "SHARE",
  //     minWidth: 120,
  //     renderCell: (data) =>
  //       data.row.open == true &&
  //       data.row.draft == false && (
  //         // (console.log(data),
  //         <Tooltip title="Copy to Clipboard">
  //           <IconButton
  //             onClick={() => {
  //               navigator.clipboard.writeText(
  //                 window.location.origin +
  //                   "/interview/apply-interview/" +
  //                   data?.row?._id
  //               );
  //               toast.success("Link Copied to Clipboard", ToastStyles);
  //             }}
  //             className="d-flex align-items-center ms-1"
  //             style={{ color: " #521986" }}
  //           >
  //             <MdFileCopy fontSize={24} color="#521986" />
  //           </IconButton>
  //         </Tooltip>
  //       ),
  //   },
  //   {
  //     field: "act",
  //     headerName: "ACTION",
  //     minWidth: 70,
  //     renderCell: (data) => {
  //       console.log("data", data); // Add this line to log data.row
  //       return (
  //         <div
  //           onClick={() => navigate(`${data.row._id}`)}
  //           className="d-flex align-items-center text-hover"
  //         >
  //           View more
  //         </div>
  //       );
  //     },
  //   },
  // ];

  const columns = useMemo(
    () => [
      {
        field: "jobtitle",
        headerName: "ROLE",
        minWidth: 200,
        renderCell: (data) => (
          <div
            className="d-flex align-items-center"
            onClick={() => {
              console.log("data", data);
            }}
          >
            {data?.value}
          </div>
        ),
      },
      {
        field: "joblocation",
        headerName: "JOB LOCATION",
        minWidth: 120,
      },
      {
        field: "createdAt",
        headerName: "CREATED ON",
        minWidth: 120,
        renderCell: (data) => (
          <div className="d-flex align-items-center">
            <Moment format="DD-MM-YYYY">{data.value}</Moment>
          </div>
        ),
      },
      {
        field: "expirydate",
        headerName: "EXPIRY DATE",
        minWidth: 120,
        renderCell: (data) => (
          <div className="d-flex align-items-center">
            <Moment format="DD-MM-YYYY">{data.value}</Moment>
          </div>
        ),
      },
      {
        field: "interviewResult",
        headerName: "RESPONSES",
        minWidth: 80,
        renderCell: (data) => (
          <div className="d-flex align-items-center">
            {data.value.length || 0}
          </div>
        ),
      },
      {
        field: "draft",
        headerName: "STATUS",
        minWidth: 70,
        renderCell: (params) => (
          // <div className="d-flex align-items-center justify-content-center">
          //   {params.value ? "🔴" : "🟢"}
          // </div>
          <select
            value={params.value}
            onChange={() =>
              handleChangeStatus(params.row._id, !params.row.draft)
            }
            className="d-flex align-items-center justify-content-center form-select"
            style={{
              color: !params.row.draft === "true" ? "red" : "green",
              backgroundColor: "white",
              border: "1px solid gray",
            }}
          >
            <option value="false" style={{ color: "green" }}>
              🟢
            </option>
            <option value="true" style={{ color: "red" }}>
              🔴
            </option>
          </select>
        ),
      },
      {
        field: "share",
        headerName: "SHARE",
        minWidth: 120,
        renderCell: (data) =>
          data.row.open === true &&
          data.row.draft === false && (
            <Tooltip title="Copy to Clipboard">
              <IconButton
                onClick={() => {
                  navigator.clipboard.writeText(
                    `${window.location.origin}/interview/apply-interview/${data?.row?._id}`
                  );
                  toast.success("Link Copied to Clipboard", ToastStyles);
                }}
                className="d-flex align-items-center ms-1"
                style={{ color: " #521986" }}
              >
                <MdFileCopy fontSize={24} color="#521986" />
              </IconButton>
            </Tooltip>
          ),
      },
      // {
      //   field: "changeStatus",
      //   headerName: "CHANGE STATUS",
      //   minWidth: 150,
      //   renderCell: (params) => (
      //     <div className="d-flex align-items-center">
      //       <button
      //         onClick={() =>
      //           handleChangeStatus(params.row._id, !params.row.draft)
      //         }
      //         className="btn btn-primary"
      //       >
      //         {params.row.draft ? "Publish" : "Draft"}
      //       </button>
      //     </div>
      //   ),
      // },
      {
        field: "act",
        headerName: "ACTION",
        minWidth: 70,
        renderCell: (data) => (
          <div
            onClick={() => navigate(`${data.row._id}`)}
            className="d-flex align-items-center text-hover"
          >
            View
          </div>
        ),
      },
    ],
    [navigate]
  );
  return (
    <>
      <Toaster />
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="container">
            <div className="my-2 border-10">
              <div className="d-flex justify-content-between">
                <YellowHeading className="mb-0" style={{ lineHeight: "50px" }}>
                  {/* My Interviews */}
                </YellowHeading>

                <ButtonBlue
                  className="px-3"
                  onClick={() =>
                    navigate(
                      type === "superadmin"
                        ? "/superadmin/create-interview/new"
                        : "/hire-pro/create-interview/new"
                    )
                  }
                >
                  Create new interview
                </ButtonBlue>
              </div>
              {/* <MultiStepProgress /> */}
              {/* Filter options */}
              <div className="d-flex my-3">
                <input
                  type="text"
                  name="jobTitle"
                  placeholder="Search by Job Title"
                  value={jobTitleFilter}
                  onChange={handleFilterChange}
                  onKeyDown={handleFilterSubmit}
                  className="form-control me-2"
                />
                {/* <input
                  type="text"
                  name="jobLocation"
                  placeholder="Filter by Job Location"
                  value={jobLocationFilter}
                  onChange={handleFilterChange}
                  onKeyDown={handleFilterSubmit}
                  className="form-control me-2"
                /> */}
                <select
                  name="Location Type"
                  labelId="jobLocationFilter-label"
                  value={jobLocationFilter}
                  onChange={handleLocationFilterChange}
                  className="form-control me-2"
                  placeholder="Filter by Job Type"
                >
                  <option value="">All</option>
                  <option value="Hybrid">Hybrid</option>
                  <option value="Remote">Remote</option>
                  <option value="On-site">Onsite</option>
                </select>
                <select
                  name="status"
                  value={statusFilter}
                  onChange={handleStatusFilterChange}
                  className="form-control"
                >
                  <option value="">All</option>
                  <option value="Draft">Inactive</option>
                  <option value="Published">Active</option>
                </select>
                {/* Checkbox for filtering expired interviews */}
              </div>
              <div className="form-check mt-3">
                <input
                  className="form-check-input"
                  type="checkbox"
                  checked={showExpiredOnly}
                  onChange={(e) => setShowExpiredOnly(e.target.checked)}
                  id="showExpiredOnly"
                />
                <label className="form-check-label" htmlFor="showExpiredOnly">
                  Show expired interviews
                </label>
              </div>
            </div>

            <Table
              columns={columns}
              data={interview.map((item) => {
                return {
                  ...item,
                  id: item._id,
                  key: item._id,
                };
              })}
              data2={interview}
              limit={10}
              checkbox={false}
              pageSize={20}
            />
            {/* <ReactPaginate
              previousLabel={"Previous"}
              nextLabel={"Next"}
              breakLabel={"..."}
              pageCount={totalpages}
              marginPagesDisplayed={2}
              pageRangeDisplayed={3}
              onPageChange={handlePageChange}
              containerClassName={"pagination justify-content-center"}
              pageClassName={"page-item"}
              pageLinkClassName={"page-link"}
              previousClassName={"page-item"}
              previousLinkClassName={"page-link"}
              nextClassName={"page-item"}
              nextLinkClassName={"page-link"}
              breakClassName={"page-item"}
              breakLinkClassName={"page-link"}
              activeClassName={"active"}
            /> */}
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};
