import React, { useState } from "react";
import {
  BoxStyle,
  ButtonBlue,
  ButtonYellowOutline,
  DashboardBoxStyle,
  SpanYellow,
  YellowButton,
  YellowHeading,
} from "components/common/CommonStyles";
import { Card } from "components/Card/CardStyles";
import { CardData } from "../data";
import "./interviewee.css";
import { Link, useNavigate } from "react-router-dom";

export const Interviewee = () => {
  const [interview, setInverView] = useState({
    interviewCourses: true,
    favoriteCourses: false,
  });
  const navigate = useNavigate();
  return (
    <DashboardBoxStyle className="scroller">
      <div className="container course-btn">
        {/* <div
          className="d-flex align-items-center justify-content-evenly justify-content-sm-start mb-3"
          style={{ marginTop: "10px" }}
        >
          <ButtonBlue
            className={`px-1 h-40 ${interview.interviewCourses ? "" : "inactive"
              }`}
            onClick={() => {
              setInverView({
                interviewCourses: !interview.interviewCourses,
                favoriteCourses: !interview.favoriteCourses,
              });
            }}
          >
            Interview Courses
          </ButtonBlue>
          <YellowButton
            className={`mx-1 ${interview.favoriteCourses ? "px-1 active" : ""}`}
            onClick={() => {
              setInverView({
                interviewCourses: !interview.interviewCourses,
                favoriteCourses: !interview.favoriteCourses,
              });
            }}
          >
            Favorite Courses
          </YellowButton>
        </div> */}
        <BoxStyle className=" pt-3 px-sm-3 px-1">
          <div className="col-12 mb-3 border-bottom1">
            <div className="row mb-1">
              <div className="col-12 align-items-center">
                <YellowHeading className=" mx-1">
                  {/* {interview.interviewCourses
                    ? "Interview Courses"
                    : "Favorite Courses"} */}
                  Interview Courses
                </YellowHeading>
              </div>
              {/* <div className="col-2">
                <ButtonBlue width="100%">View All</ButtonBlue>
              </div> */}
            </div>
          </div>
          <div className="col-12">
            <div className="row">
              {CardData.slice(0, interview.interviewCourses ? 10 : 4).map(
                (item) => (
                  <div className="col-12 col-md-6 col-lg-4 mb-3" key={item.id}>
                    <div>
                      <Card>
                        <div className="imagecard">
                          <img src={item?.poster} />
                        </div>
                        <div className="card-content-wrapper my-3">
                          <h4 className="card-title course">{item.title}</h4>
                          <p
                            className="card-description course pt-3 mb-1 "
                            style={{ wordBreak: "break-word" }}
                          >
                            {item.description.slice(0, 125)}...
                          </p>
                          <SpanYellow
                            className="c-pointer"
                            onClick={() => navigate(`${item.id}`)}
                          >
                            {" "}
                            READ MORE
                          </SpanYellow>
                        </div>
                        <div className="card-footer-wrapper mt-2">
                          <div className="card-footer d-flex justify-content-between">
                            <div className="card-footer-left w-100">
                              <ButtonBlue
                                onClick={() => navigate(`${item.id}`)}
                                width="100%"
                              >
                                {item.button}
                              </ButtonBlue>
                            </div>
                            {/* <div className="card-footer-right">
                              <ButtonYellowOutline>
                                <img src={item.icon} />
                              </ButtonYellowOutline>
                            </div> */}
                          </div>
                        </div>
                      </Card>
                    </div>
                  </div>
                )
              )}
            </div>
          </div>
        </BoxStyle>
      </div>
    </DashboardBoxStyle>
  );
};
