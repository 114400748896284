import { Authentication } from "components/Authentication/Authentication";
import IndexCollege from "container/College/IndexCollege";
import { Index } from "container/Interviewee/Index";
import IndexInterviewer from "container/Interviewer/IndexInterviewer";
import SuperADminIndex from "container/SuperAdmin/SuperAdminIndex";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { IndexInterview } from "container/ActualInterview/IndexInterview";
import { StudentIndex } from "container/CollegeStudent/StudentIndex";


export const PageRoutes = () => {
  return (
    <BrowserRouter>
      <Routes>
        <Route exact path="/" element={<Authentication />} />
        <Route exact path="/interviewee/*" element={<Index />} />
        <Route exact path="/college-student/*" element={<StudentIndex />} />
        <Route exact path="/hire-pro/*" element={<IndexInterviewer />} />
        <Route exact path="/college/*" element={<IndexCollege />} />
        <Route exact path="/superadmin/*" element={<SuperADminIndex />} />
        <Route exact path="/interview/*" element={<IndexInterview />} />
      </Routes>
    </BrowserRouter>
  );
};
