import { BackButton } from "components/common/BackButton";
import { BiArrowBack } from "react-icons/bi";
import {
  BoxStyle,
  BoxStyleBlueLight,
  BoxYellowOutline,
  ButtonBlue,
  ButtonYellowOutline,
  DarkHeading,
  DashboardBoxStyle,
  Input,
  Paragraph,
  SearchInput,
  // Select,
  TextArea,
  TextEditorBox,
  Wrapper,
  YellowHeading,
} from "components/common/CommonStyles";
import React, { useEffect, useRef, useState } from "react";
import { Accordion, Form, Modal, Spinner } from "react-bootstrap";
import axios from "axios";
import { GridSearchIcon } from "@mui/x-data-grid";
import { AiFillCloseCircle, AiTwotoneEdit } from "react-icons/ai";
import {
  CreateInterview,
  CreateQuestions,
  CreateSkills,
  GetallQuestionsCategory,
  GetallSkillsCategory,
  GetCorporate,
  UpdateQuestions,
} from "../../../axios/apis";
import countryData from "mock-api/countries+states+cities.json";
import { cities } from "mock-api/cities";
import { jobDomain } from "mock-api/jobDomail";
import { subDomain } from "mock-api/subDomain";
import "./interview.css";
// import { EditorState } from "draft-js";
// import { Editor } from "react-draft-wysiwyg";
import InterviewLogo from "../../../assets/image/interview-logo.png";
import "react-quill/dist/quill.snow.css";
// import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BsArrowUp } from "react-icons/bs";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import ReactQuill from "react-quill";
import { createInterViewLeftData } from "container/Interviewer/CreateInterview/contants/data";
import { useNavigate } from "react-router";
import CreatableSelect from "react-select/creatable";
import Select from "react-select";
import Cookies from "js-cookie";

const CreateInterviewForm = ({ type }) => {
  const [activeIndex, setIndex] = useState(0);
  const [icon, seticon] = useState("");
  const navigate = useNavigate();
  const companyName = Cookies.get("name");
  const companyImg = Cookies.get("profileImg");
  const [questionsUpdated, setQuestionsUpdated] = useState(false);
  const [jobdetails, setJobDetails] = useState({
    company: companyName,
    companyDescription: "",
    jobtitle: "",
    jobdescription: "",
    jobdomain: "",
    jobcategory: "",
    jobcity: "",
    jobarea: "",
    jobtype: "",
    joblocation: "",
    experience: "",
    expirydate: "",
    status: "",
    skills: [],
    interviewquestions: [],
    documents: [],
    draft: "",
    open: true, // Added
    collegeid: [], // Added
  });
  const [userDetail, setUserDetail] = useState({});

  const GetCompanyDetails = async () => {
    try {
      const response = await GetCorporate();
      setUserDetail(response?.data?.data);
      // console.log(response);
    } catch (error) {
      // console.log(error);
      toast.error("Try Again: We Couldn't Fetch Company Details", ToastStyles);
    }
  };
  useEffect(() => {
    GetCompanyDetails();
  }, []);
  // Effect to load saved data when component mounts
  useEffect(() => {
    // Example: Load job details from localStorage
    const savedJobDetails = JSON.parse(localStorage.getItem("jobDetails"));
    if (savedJobDetails) {
      setJobDetails(savedJobDetails);
    }
  }, []);
  // Function to save job details to localStorage when form is submitted or updated
  const saveJobDetails = () => {
    localStorage.setItem("jobDetails", JSON.stringify(jobdetails));
    // Add logic to submit data to backend or perform further actions
  };

  const [jobdescription, setJobDescription] = useState("");
  // console.log(jobdetails);

  // upload image aws
  const UploadInput = useRef(null);
  const [percentage, setPercentage] = useState(0);
  const [loadingiconupload, setLoadingIconupload] = useState(null);
  function fileUploadHandler(e) {
    uploadLogo(e.target.files[0]);
  }
  const uploadLogo = async (e) => {
    if (e) {
      setLoadingIconupload(true);
      const config = {
        onUploadProgress: (progress) => {
          const percentange = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setPercentage(percentange);
          if (percentange === 100) {
            setLoadingIconupload(false);
          }
        },
      };
      const formData = new FormData();
      formData.append("file", e);
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      setLoadingIconupload(true);
      axios
        .post(`${rootUrl}/v2/upload/other`, formData, config)
        .then((data) => {
          // console.log(data.data.link);
          seticon(data.data.link);
          toast.success("Image Uploaded Successfully", ToastStyles);
          setLoadingIconupload(false);
        })
        .catch((error) => {
          toast.error("Try again: Logo isn't Uploaded", ToastStyles);
          console.error(error);
          setLoadingIconupload(false);
        });
    }
  };
  // Interview Form  basic Details
  // console.log(jobdetails);
  const handleChange = (e) => {
    setJobDetails({
      ...jobdetails,
      [e.target.name]: e.target.value,
    });
  };

  const getInnerContent = (id) => {
    const innerContent = document.getElementById(id);
    if (innerContent) {
      setJobDetails({ ...jobdetails, [id]: innerContent.innerHTML });
    }
  };

  // Interview Skill

  const [showCreateSkillModal, setShowCreateSkillModal] = useState(false);

  const createSkillModalClose = () => setShowCreateSkillModal(false);
  const createSkillModalOpen = () => setShowCreateSkillModal(true);

  const [newSkilltitle, setNewSkilltitle] = useState("");
  const [newskills, setnewskill] = useState([]);
  const removenewskills = (indexToRemove) => {
    setnewskill([...newskills.filter((_, index) => index !== indexToRemove)]);
  };
  const addnewskills = (event) => {
    if (event.target.value !== "") {
      setnewskill([...newskills, event.target.value]);
      event.target.value = "";
    }
  };
  const [interviewskills, setinterviewSkills] = useState([]);
  // console.log(interviewskills);
  const [interviewteams, setinterviewteams] = useState([]);
  const [skillNames, setSkillNames] = useState([]);
  // console.log("Interview Skills", interviewskills);
  // console.log("Skill Names", skillNames);

  const fetchSkillName = async (skillId) => {
    try {
      console.log("Fetching skill for ID/Name:", skillId);
      const response = await axios.get(
        `https://api.viosa.in/interview-pro/interview/skills/${skillId}`
      );
      console.log("response", response.data.skills);
      console.log("API Response:", response.data);
      return response.data.skills || "Unknown Skill"; // Assuming API returns skill name
    } catch (error) {
      console.error("Error fetching skill name:", error);
      return "Unknown Skill"; // Handle error gracefully or return default value
    }
  };
  useEffect(() => {
    const fetchSkillNames = async () => {
      if (!jobdetails.skills || jobdetails.skills.length === 0) {
        console.log("No skills to process.");
        return;
      }
      console.log("Fetching skill names for IDs/Names:", jobdetails.skills);
      console.log("  IDs/Names:", jobdetails);
      // Separate skill IDs and skill names
      const skillIds = jobdetails.skills.filter((skill) =>
        typeof skill === "object" ? skill._id : /^[0-9a-fA-F]{24}$/.test(skill)
      );
      const skillNames = jobdetails.skills.filter(
        (skill) => typeof skill === "string" && !/^[0-9a-fA-F]{24}$/.test(skill)
      );
      const skillObjects = jobdetails.skills.filter(
        (skill) => typeof skill === "object"
      );

      console.log("skillnames", skillNames);
      console.log("skillids", skillIds);
      try {
        // const skill = jobdetails.skills._id;
        // const names = await Promise.all(
        //   skill.map((skillId) => fetchSkillName(skillId))
        // );
        const fetchedNames = await Promise.all(
          skillIds.map((skillId) => fetchSkillName(skillId._id || skillId))
        );
        console.log("Fetched names:", fetchedNames);
        // Extract skill names from skill objects
        const objectNames = skillObjects.map((skill) => skill.skills);
        // Merge fetched names with existing skill names and object names
        const allNames = [...skillNames, ...objectNames, ...fetchedNames];
        console.log("All skill names:", allNames);
        // Assuming `jobdetails.skills` is an array of skill objects
        // const names = jobdetails.skills.map(
        //   (skill) => skill.skills || "Unknown Skill"
        // );
        // console.log("names", names);
        console.log("Job details skills:", jobdetails.skills);
        console.log("Interview skills:", interviewskills);

        setSkillNames(allNames);
      } catch (error) {
        console.error("Error fetching skill names:", error);
      }
    };

    fetchSkillNames();
  }, [jobdetails.skills]);

  // const handleInterviewSkillChange = (selectedOption) => {
  //   const skillId = selectedOption.value;
  //   console.log("skillid", skillId);
  //   if (!interviewskills.includes(skillId)) {
  //     const newSkills = [...interviewskills, skillId];
  //     console.log("newskills", newSkills);
  //     setinterviewSkills(newSkills);
  //     setJobDetails((prevDetails) => ({
  //       ...prevDetails,
  //       skills: newSkills,
  //     }));
  //   }
  // };
  // const handleInterviewSkillChange = (selectedOption) => {
  //   if (!selectedOption) return;

  //   const skillId = selectedOption.value;
  //   console.log("Selected skill ID:", skillId);

  //   // Add new skill ID to the list if it doesn't already exist
  //   if (!interviewskills.includes(skillId)) {
  //     const newSkills = [...interviewskills, skillId];
  //     console.log("New skills list:", newSkills);
  //     setinterviewSkills(newSkills);
  //     setJobDetails((prevDetails) => ({
  //       ...prevDetails,
  //       skills: newSkills,
  //     }));
  //   }

  //   // Find the skill name by label
  //   const skillName = selectedOption.label;
  //   setSkillNames((prevNames) => [...prevNames, skillName]);
  // };
  const handleInterviewSkillChange = (selectedOption) => {
    if (!selectedOption) return;

    const skillId = selectedOption.value;
    const skillName = selectedOption.label;

    console.log("Selected skill ID:", skillId);

    // Check if the skill is already added
    if (interviewskills.includes(skillId)) {
      toast.error("Skill Already Added", ToastStyles);
    } else if (interviewskills.length === 20) {
      toast.error("20 Skills Already added", ToastStyles);
    } else {
      // Add the new skill ID to interviewskills
      const newSkills = [...interviewskills, skillId];
      console.log("New skills list:", newSkills);
      setinterviewSkills(newSkills);

      // Add the new skill name to skillNames
      setSkillNames((prevNames) => [...prevNames, skillName]);

      // Update the job details with new skills
      setJobDetails((prevDetails) => ({
        ...prevDetails,
        skills: newSkills,
      }));
    }
  };

  const handleCreateSkill = async (inputValue) => {
    try {
      const newSkillTitle = inputValue;
      const newSkills = newSkillTitle; // Assuming the skill is a single skill for simplicity
      const payload = {
        skilltype: "User Specified", // or any other appropriate type
        skills: newSkills,
      };
      const response = await CreateSkills(payload);
      console.log("reponse craeteskill", response);
      const newSkill = response.data.skill; // Adjust this based on your API response structure
      const updatedSkills = [...skillsdata, newSkill];
      // Update the state with the new skill
      // setskillsdata(updatedSkills);
      // Update the state with the new skill
      const newSkillOption = { value: newSkill._id, label: newSkill.skills };
      setskillsdata((prevData) => [...skillsdata, newSkillOption]);

      // Add the new skill to the interview skills and skill names
      setinterviewSkills((prevSkills) => [...prevSkills, newSkill._id]);
      setSkillNames((prevNames) => [...prevNames, newSkill.skills]);

      setJobDetails((prevDetails) => ({
        ...prevDetails,
        skills: [...prevDetails.skills, newSkill._id],
      }));
      setinterviewSkills((prevSkills) => [...prevSkills, newSkill._id]);
    } catch (error) {
      console.error("Error creating new skill:", error);
    }
  };

  const handleinterviewSkillChange = (data) => {
    // console.log(data);
    if (data === "") {
      return;
    } else if (interviewskills.length === 20) {
      toast.error("20 Skills Already added", ToastStyles);
    } else if (interviewskills.length !== 0 && interviewskills.includes(data)) {
      // setinterviewSkills((interviewskills) =>
      //   interviewskills.filter((data2, index) => data2 !== data)
      // );
      toast.error("Skill Already Added", ToastStyles);
    } else {
      setinterviewSkills((interviewskills) => [...interviewskills, data]);
    }
  };

  // const removethisskill = (indexToRemove) => {
  //   setinterviewSkills([
  //     ...interviewskills.filter((_, index) => index !== indexToRemove),
  //   ]);
  // };
  const removethisskill = (index) => {
    const newSkills = [...interviewskills];
    const newSkillNames = [...skillNames];
    newSkills.splice(index, 1);
    newSkillNames.splice(index, 1);

    setinterviewSkills(newSkills);
    setSkillNames(newSkillNames);
  };
  const [skillmodalloading, setskillmodalloading] = useState(false);
  const createNewSkills = async () => {
    if (newSkilltitle === "") {
      toast.error("Title can't be empty", ToastStyles);
    } else if (newskills.length === 0) {
      toast.error("Add at least one skill", ToastStyles);
    } else {
      setskillmodalloading(true);
      const formdata = {
        skilltype: newSkilltitle,
        skill: newskills,
      };
      try {
        const response = await CreateSkills(formdata);
        if (response.status !== 200) {
          toast.error("Try Again 😣", ToastStyles);
        } else {
          toast.success("Skills set created", ToastStyles);

          // Create new skill objects
          const newSkills = response.data.data.map((data) => ({
            _id: data._id,
            skills: data.skills,
          }));

          // Update job details and interview skills
          setJobDetails((prevDetails) => ({
            ...prevDetails,
            skills: [
              ...prevDetails.skills,
              ...newSkills.map((skill) => skill._id),
            ],
          }));

          setinterviewSkills((prevSkills) => [
            ...prevSkills,
            ...newSkills.map((skill) => skill._id),
          ]);

          createSkillModalClose();
        }
      } catch (error) {
        console.log(error);
        toast.error("Try Again 😣", ToastStyles);
      }
      setskillmodalloading(false);
    }
  };

  const [allskills, setallskills] = useState([]);
  const [skillsdata, setskillsdata] = useState([]);
  const [skillshowlist, setskillshowlist] = useState(3);

  console.log("Skills Data:", skillsdata);
  const getallSkillswithType = async () => {
    try {
      const data = await GetallSkillsCategory();
      console.log(data?.data);
      setallskills(data?.data?.data);
      const skillls = [];
      data?.data?.data.map((data) =>
        data.skills.map((data2) => skillls.push(data2))
      );
      setskillsdata(skillls);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getallSkillswithType();
  }, []);

  // Interview Questions
  const [questionHide, setQuestionHide] = useState(false);
  const [updatequestionId, setUpdateQuestionId] = useState("");
  const [showCreateNewQueModal, setshowCreateNewQueModal] = useState(false);
  const [interviewQue, setInterviewQue] = useState([
    {
      question: "",
      keyword: [],
      duration: "",
      suggestedAnswer: "",
      videoQuestion: "",
      videoQuestionLink: "",

      hide: false,
    },
  ]);
  const [interviewnewQue, setInterviewnewQue] = useState({
    questiontype: "",
    question: "",
    keyword: [],
    suggestedAnswer: "",
    videoQuestion: "",
    videoQuestionLink: "",

    modeofquestion: "",
    modeofanswer: "",
    duration: "",
    hide: "false",
  });
  const handlenewquestionchange = (e) => {
    setInterviewnewQue({ ...interviewnewQue, [e.target.name]: e.target.value });
  };

  const handleInterviewQueChange = async (data, e) => {
    e.preventDefault();
    if (updatequestionId === "") {
      if (data._id && data.__v && data.createdAt && data.updatedAt) {
        delete data._id;
        delete data.__v;
        delete data.createdAt;
        delete data.updatedAt;
      }
      if (interviewQue.length !== 0 && interviewQue.includes(data)) {
        toast.error("Question Already Added", ToastStyles);
      } else {
        setInterviewQue((interviewQue) => [...interviewQue, data]);
        setshowCreateNewQueModal(false);
        setInterviewnewQue({
          questiontype: "",
          question: "",
          keyword: [],
          suggestedAnswer: "",
          videoQuestion: "",
          videoQuestionLink: "",

          modeofquestion: "",
          modeofanswer: "",
          duration: "",
          hide: false,
        });
        toast.success("Question Added Successfully", ToastStyles);
      }
    } else {
      let questions;
      let body = {
        id: updatequestionId,
        questions: [data],
      };
      try {
        setquestionmodalloading(true);
        const response = await UpdateQuestions(body);
        toast.success("Question Added SuccessFully", ToastStyles);
        setInterviewnewQue({
          questiontype: "",
          question: "",
          keyword: [],
          suggestedAnswer: "",
          videoQuestion: "",
          videoQuestionLink: "",

          modeofquestion: "",
          modeofanswer: "",
          duration: "",
          hide: false,
        });
        getallQuestionswithType();
        setquestionmodalloading(false);
        setshowCreateNewQueModal(false);
        setUpdateQuestionId("");
      } catch (error) {
        toast.error("Try Again", ToastStyles);
        console.log(error);
        setquestionmodalloading(true);
      }
    }
  };

  const addInterviewQueField = (e) => {
    e.preventDefault();
    setInterviewQue([
      ...interviewQue,
      {
        question: "",
        keyword: "",
        duration: "",
        modeofquestion: "",
        modeofanswer: "",
        hide: false,
      },
    ]);
  };
  const removeInterviewQueField = (i) => {
    let newFormValues = [...interviewQue];
    newFormValues.splice(i, 1);
    setInterviewQue(newFormValues);
  };

  const interviewquestionadd = (data) => {
    const checkdata = interviewQue.filter(
      (data2) => data2.question === data.question
    );
    // console.log(checkdata);
    if (checkdata.length !== 0) {
      return;
    } else {
      setInterviewQue([
        ...interviewQue,
        {
          question: data.question,
          keyword: data.keyword,
          duration: data.duration,
          modeofquestion: data.modeofquestion,
          modeofanswer: data.modeofanswer,
        },
      ]);
    }
  };

  const [interviePracticeQue, setInterviewPracticeQue] = useState("");

  // custom interview questions add
  const [showCreateQueModal, setShowCreateQueModal] = useState(false);

  const CreateQueModalClose = () => setShowCreateQueModal(false);
  const CreateQueModalOpen = () => setShowCreateQueModal(true);

  const [newquetitle, setNewquetitle] = useState();
  const [newquelist, setnewQuelist] = useState([
    {
      questiontype: newquetitle,
      question: "",
      keyword: "",
      suggestedAnswer: "",
      videoQuestion: "",
      videoQuestionLink: "",

      duration: "",
      modeofquestion: "",
      modeofanswer: "",
      hide: false,
    },
  ]);
  // console.log(newquelist);
  const handleInterviewQueDataChange = (i, e) => {
    // //console.log(e.target.date)
    const { name, value, files } = e.target;
    let newFormValues = [...interviewQue];
    if (files) {
      newFormValues[i][name] = files[0];
      newFormValues[i]["videoFileName"] = files[0].name; // Save file name in state
    } else {
      newFormValues[i][name] = value;
    }
    setInterviewQue(newFormValues);
    setQuestionsUpdated(false); // Reset the questionsUpdated flag on data change
    localStorage.setItem("interviewQue", JSON.stringify(newFormValues));
    if (e.target.name === "videoQuestionLink") {
      let newFormValues = [...interviewQue];
      // console.log(e)
      newFormValues[i][e.target.name] = e.target?.files[0];
      setInterviewQue(newFormValues);
      // debugger;
    } else {
      let newFormValues = [...interviewQue];
      newFormValues[i][e.target.name] = e.target.value;
      setInterviewQue(newFormValues);
    }
  };

  // Function to handle replacing or uploading video for a specific question
  const handleReplaceVideoButtonClick = (index, event) => {
    // Implement logic to handle file upload or replace here
    const file = event.target.files[0]; // Access the uploaded file
    let updatedInterviewQue = [...interviewQue];
    updatedInterviewQue[index] = {
      ...updatedInterviewQue[index],
      videoQuestion: file, // Assign the uploaded file to videoQuestion field
      videoFileName: file.name, // Store the file name for display purposes
    };
    setInterviewQue(updatedInterviewQue);
  };
  const [canProceed, setCanProceed] = useState(false);

  useEffect(() => {
    const allVideosUploaded = interviewQue.every((q) => q.videoQuestion);
    setCanProceed(allVideosUploaded);
  }, [interviewQue]);
  const handleCustomQueChange = (i, e) => {
    // //console.log(e.target.date)
    let newFormValues = [...newquelist];
    newFormValues[i][e.target.name] = e.target.value;
    setnewQuelist(newFormValues);
  };

  const addCustomQueField = (e) => {
    e.preventDefault();
    setnewQuelist([
      ...newquelist,
      {
        questiontype: newquetitle,
        question: "",
        keyword: "",
        duration: "",
        modeofquestion: "",
        modeofanswer: "",
        hide: false,
      },
    ]);
  };
  const removeCustomQueField = (i) => {
    let newFormValues = [...newquelist];
    newFormValues.splice(i, 1);
    setnewQuelist(newFormValues);
  };

  const [questionmodalloading, setquestionmodalloading] = useState(false);
  const createNewInterviewQue = async (e) => {
    e.preventDefault();
    if (newquetitle === "") {
      toast.error("Title can't be empty", ToastStyles);
    } else if (newquelist.length === 0) {
      toast.error("Add atleast one Question", ToastStyles);
    } else {
      setquestionmodalloading(true);
      // console.log(newquetitle, newquelist);
      const formdata = {
        questions: newquelist,
      };
      try {
        const response = await CreateQuestions(newquelist);
        // console.log(response);
        getallQuestionswithType();

        toast.success("Questions set created", ToastStyles);

        CreateQueModalClose();
        setquestionmodalloading(false);
      } catch (error) {
        console.log(error);
        toast.error("Try Again 😣", ToastStyles);
        setquestionmodalloading(false);
      }
    }
  };
  const [allquestions, setallquestions] = useState([]);
  const [questionsdata, setquestionsdata] = useState([]);
  const [questionshowlist, setquestionshowlist] = useState(3);
  const getallQuestionswithType = async () => {
    try {
      const data = await GetallQuestionsCategory();
      // console.log(data?.data);
      setallquestions(data?.data?.data);
      // const questionss = [];
      // data?.data?.data?.map((data) =>
      //   data.questions?.map((data2) => questionss.push(data2))
      // );
      // setquestionsdata(questionss);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getallQuestionswithType();
  }, []);

  // Ask for Documents
  const [documentlist, setDocumentList] = useState([]);
  const handleDocumentsListChange = (e) => {
    if (documentlist.length !== 0 && documentlist.includes(e.target.value)) {
      setDocumentList((documentlist) =>
        documentlist.filter((data, index) => data !== e.target.value)
      );
    } else {
      setDocumentList((documentlist) => [...documentlist, e.target.value]);
    }
  };
  const removenewdoc = (indexToRemove) => {
    setDocumentList([
      ...documentlist.filter((_, index) => index !== indexToRemove),
    ]);
  };
  const addnewdoc = (event) => {
    if (event.target.value !== "") {
      setDocumentList([...documentlist, event.target.value]);
      event.target.value = "";
    }
  };

  // Function to create Interview
  // console.log(jobdetails);
  const [createInterviewLoading, setCreateInterviewLoading] = useState(false);
  const [createInterviewDraftLoading, setCreateInterviewDraftLoading] =
    useState(false);

  const [uploadVideoLoading, setUploadLoading] = useState(false);
  const uploadVideo = async () => {
    setUploadLoading(true);
    try {
      // Check if any video files are missing
      const missingFiles = interviewQue.some(
        (item) => item.videoQuestion === "" || item.videoQuestion === undefined
      );
      if (missingFiles) {
        toast.error(
          "Please upload all video files before proceeding.",
          ToastStyles
        );
        setUploadLoading(false);
        return;
      }
      interviewQue?.forEach(async (item, i) => {
        console.log("check");
        console.log(item);
        if (
          item?.videoQuestion !== "" &&
          typeof item.videoQuestion === "object"
        ) {
          debugger;
          const config = {
            onUploadProgress: (progress) => {
              const percentange = Math.round(
                (progress.loaded / progress.total) * 100
              );
              if (percentange === 100) {
              }
            },
          };
          const formData = new FormData();
          formData.append("file", item.videoQuestion);
          const rootUrl = "https://uploader.viosa.in/api";
          const { data } = await axios.post(
            `${rootUrl}/v2/upload/other`,
            formData,
            config
          );

          console.log("data link", data.link);
          const newFormValues = [...interviewQue];
          newFormValues[i]["videoQuestion"] = data.link;
          setInterviewQue(newFormValues);
          console.log("newformvalues", newFormValues);
          setQuestionsUpdated(true);
          setUploadLoading(false);
          toast.success("Updated", ToastStyles);
        }
      });
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setUploadLoading(false);
    }
  };
  const createNewInterview = async (e) => {
    e.preventDefault();
    const {
      company,
      companyDescription,
      jobtitle,
      jobdomain,
      jobcategory,
      jobcity,
      jobarea,
      jobtype,
      joblocation,
      experience,
      expirydate,
      open, // Added
      collegeid, // Added
    } = jobdetails;
    if (company === "") {
      toast.error("Please Add Company Name", ToastStyles);
    } else if (jobtitle === "") {
      toast.error("Please Add Job Title", ToastStyles);
    } else if (jobtype === "") {
      toast.error("Please Add Job Type", ToastStyles);
    } else if (experience === "") {
      toast.error("Please Add Job Experience", ToastStyles);
    } else {
      try {
        setCreateInterviewLoading(true);
        let newFormValues = [...interviewQue];
        let data = {
          jobtitle,
          jobdescription,
          joblocation,
          jobtype,
          jobcategory,
          expirydate,
          jobcity,
          jobarea,
          icon: companyImg,
          companyDescription,
          company,
          domain: jobdomain,
          experience,
          interviewquestions: newFormValues,
          documents: documentlist,
          preparationQue: interviePracticeQue,
          skills: interviewskills,
          draft: false,
          status: "Scheduled",
          creator_type: "corporate",
          open, // Updated to use jobdetails.open
          collegeid, // Updated to use jobdetails.collegeid
        };

        const res = await CreateInterview(data);
        // console.log(res);
        // localStorage.setItem("jobDetails", JSON.stringify(jobdetails));
        setCreateInterviewLoading(false);
        toast.success("Interview Created SuccessFully", ToastStyles);
        navigate("/hire-pro/my-interviews");
      } catch (error) {
        toast.error("Try Again", ToastStyles);
        console.log(error);
        setCreateInterviewLoading(false);
      }
    }
    // console.log(e);
    // console.log(jobdetails);
    // console.log(interviewskills);
    // console.log(interviewQue);
    // console.log(documentlist);
  };
  const createNewInterviewDraft = async (e) => {
    e.preventDefault();
    const {
      company,
      companyDescription,
      jobtitle,
      jobdomain,
      jobcategory,
      jobcity,
      jobarea,
      jobtype,
      joblocation,
      experience,
      expirydate,
      open, // Added
      collegeid, // Added
    } = jobdetails;
    if (company === "") {
      toast.error("Please Add Company Name", ToastStyles);
    } else if (jobtitle === "") {
      toast.error("Please Add Job Title", ToastStyles);
    } else if (jobtype === "") {
      toast.error("Please Add Job Type", ToastStyles);
    } else if (experience === "") {
      toast.error("Please Add Job Experience", ToastStyles);
    } else {
      try {
        setCreateInterviewDraftLoading(true);

        let data = {
          jobtitle,
          jobdescription,
          joblocation,
          jobtype,
          jobcategory,
          expirydate,
          jobcity,
          jobarea,
          icon: companyImg,
          companyDescription,
          company,
          domain: jobdomain,
          experience,
          interviewquestions: interviewQue,
          documents: documentlist,
          preparationQue: interviePracticeQue,
          skills: interviewskills,
          draft: true,
          status: "Scheduled",
          creator_type: "corporate",
          open, // Updated to use jobdetails.open
          collegeid, // Updated to use jobdetails.collegeid
        };

        const res = await CreateInterview(data);
        // console.log(res);
        toast.success("Draft Interview Created SuccessFully", ToastStyles);
        navigate("/hire-pro/create-interview/draft");
        setCreateInterviewDraftLoading(false);
      } catch (error) {
        toast.error("Try Again", ToastStyles);
        console.log(error);
        setCreateInterviewDraftLoading(false);
      }
    }
    // console.log(e);
    // console.log(jobdetails);
    // console.log(interviewskills);
    // console.log(interviewQue);
    // console.log(documentlist);
  };

  // console.log(jobdetails);

  const jobTypes = [
    {
      value: "Full Time",
      label: "Full Time",
    },
    {
      value: "Part Time",
      label: "Part Time",
    },
    {
      value: "Contract",
      label: "Contract",
    },
    {
      value: "Freelancer",
      label: "Freelancer",
    },
  ];
  const jobLocation = [
    {
      value: "On-site",
      label: "On-site",
    },
    {
      value: "Remote",
      label: "Remote",
    },
    {
      value: "Hybrid",
      label: "Hybrid",
    },
  ];

  const experience = [
    {
      value: "1",
      label: "1",
    },
    {
      value: "2",
      label: "2",
    },
    {
      value: "3",
      label: "3",
    },
  ];
  const [colleges, setColleges] = useState([]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setJobDetails({ ...jobdetails, [name]: value });
  };

  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setJobDetails({ ...jobdetails, [name]: checked });
  };

  // const handleMultiSelectChange = (e) => {
  //   console.log("Selected college ID:", e.target.value);
  //   const value = e.target.value;
  //   if (!jobdetails.collegeid.includes(value) && value !== "") {
  //     setJobDetails({
  //       ...jobdetails,
  //       collegeid: [...jobdetails.collegeid, value],
  //     });
  //   }
  // };
  const handleMultiSelectChange = (selectedOptions) => {
    // selectedOptions will be an array of the selected option objects
    if (selectedOptions) {
      // Extract the value (college IDs) from the selected options
      const selectedCollegeIds = selectedOptions.map((option) => option.value);
      setJobDetails({
        ...jobdetails,
        collegeid: selectedCollegeIds,
      });
    } else {
      // If no options are selected, reset the collegeid array
      setJobDetails({
        ...jobdetails,
        collegeid: [],
      });
    }
  };
  // Map colleges to react-select format
  const collegeOptions = colleges.map((college) => ({
    label: college.collegename,
    value: college._id,
  }));
  const fetchColleges = async () => {
    try {
      const response = await fetch(
        // "https://interview-api.viosa.in/auth/college/all"
        "https://api.viosa.in/interview-pro/auth/college/all"
      );
      if (response.ok) {
        const data = await response.json();
        // Filter colleges based on the status being true
        const filteredColleges = data.data.filter(
          (college) => college.status === true
        );
        setColleges(filteredColleges);
        console.log("College Data", filteredColleges);
        // setColleges(data.data); // Assuming data is an array of colleges
        // console.log("College Data", data.data);
      } else {
        console.error("Failed to fetch colleges");
      }
    } catch (error) {
      console.error("Error fetching colleges:", error);
    }
  };
  useEffect(() => {
    // Fetch college data when the component mounts
    fetchColleges();
  }, []);
  useEffect(() => {
    console.log("Colleges state:", colleges);
  }, [colleges]);

  const removeCollege = (index) => {
    const newCollegeIdList = jobdetails.collegeid.filter((_, i) => i !== index);
    setJobDetails({ ...jobdetails, collegeid: newCollegeIdList });
  };

  // Example function to format date
  function formatDate(dateString) {
    const date = new Date(dateString);
    // Format date as desired (e.g., "MM/DD/YYYY" or "YYYY-MM-DD")
    const formattedDate = `${date.getFullYear()}-${(date.getMonth() + 1)
      .toString()
      .padStart(2, "0")}-${date.getDate().toString().padStart(2, "0")}`;
    return formattedDate;
  }

  // Usage example
  const expiryDateString = jobdetails.expirydate;
  const formattedExpiryDate = formatDate(expiryDateString);
  // console.log(formattedExpiryDate); // Outputs: "2024-08-10"

  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState(null);
  const [selectedState, setSelectedState] = useState(null);

  useEffect(() => {
    setCountries(countryData);
  }, []);
  const handleCountryChange = (selectedOption) => {
    setSelectedCountry(selectedOption);
    const country = countries.find((c) => c.name === selectedOption.value);
    setStates(country.states || []);
    setSelectedState(null); // Reset the state selection when country changes
    setJobDetails({
      ...jobdetails,
      jobcountry: selectedOption ? selectedOption.value : "",
    });
  };

  const handleStateChange = (selectedOption) => {
    setSelectedState(selectedOption);
    setJobDetails({
      ...jobdetails,
      jobcity: selectedOption ? selectedOption.value : "",
    });
  };

  const countryOptions = countries.map((country) => ({
    value: country.name,
    label: country.name,
  }));

  const stateOptions = states.map((state) => ({
    value: state.name,
    label: state.name,
  }));

  //AI

  return (
    <>
      <Toaster />
      {/* add custom skill modal */}
      <Modal show={showCreateSkillModal} onHide={createSkillModalClose}>
        <Modal.Header closeButton>
          <DarkHeading>Create Custom Skills</DarkHeading>
        </Modal.Header>
        <Modal.Body className="job-form mx-2">
          <label>Skills Title</label>
          <TextArea
            placeholder="Title"
            className="h-65 secondary border-10 mb-2"
            name="title"
            required
            value={newSkilltitle}
            onChange={(e) => {
              setNewSkilltitle(e.target.value);
            }}
          ></TextArea>
          <label>Skills</label>

          <div className="tags-input">
            <ul id="tags">
              {newskills?.map((tag, index) => (
                <li key={index} className="tag">
                  <span className="tag-title">{tag}</span>
                  <span
                    className="tag-close-icon"
                    onClick={() => removenewskills(index)}
                  >
                    x
                  </span>
                </li>
              ))}
            </ul>
            <input
              type="text"
              onKeyUp={(event) =>
                event.key === "Enter" ? addnewskills(event) : null
              }
              placeholder="Press enter to add Skill"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {skillmodalloading ? (
            <ButtonBlue className="w-100">
              <Spinner animation="border" size="sm" />
            </ButtonBlue>
          ) : (
            <ButtonBlue className="w-100" onClick={createNewSkills}>
              Add New Skills
            </ButtonBlue>
          )}
        </Modal.Footer>
      </Modal>

      {/* add custom questions modal */}

      <Modal show={showCreateQueModal} onHide={CreateQueModalClose}>
        <Modal.Header closeButton>
          <DarkHeading>Create Custom Questions</DarkHeading>
        </Modal.Header>
        <Form onSubmit={createNewInterviewQue}>
          <Modal.Body className="job-form h-70 mx-2">
            <label>Questions Title</label>
            <TextArea
              placeholder="Title"
              className="h-65 secondary border-10 mb-2"
              name="title"
              required
              value={newquetitle}
              onChange={(e) => {
                setNewquetitle(e.target.value);
                let data = [...newquelist];
                data[0].questiontype = e.target.value;
                setnewQuelist(data);
              }}
            ></TextArea>

            <div className="d-flex justify-content-between align-items-center">
              <DarkHeading className="fs-20">Interview Questions</DarkHeading>
              <ButtonBlue onClick={addCustomQueField} className="px-2">
                + Add More Question
              </ButtonBlue>
            </div>

            {newquelist.map((e, i) => (
              <div key={i}>
                <div className="d-flex justify-content-between my-3">
                  <h5>Q{i + 1}. Enter Your Question</h5>
                  <div
                    onClick={() => removeCustomQueField(i)}
                    className="cancel-icon c-pointer"
                  >
                    <AiFillCloseCircle color="red " size={24} />
                  </div>
                </div>
                <Input
                  placeholder="Enter Your Question"
                  className="h-65 mb-2"
                  name="question"
                  required
                  value={e.question}
                  onChange={(e) => handleCustomQueChange(i, e)}
                />
                <Input
                  placeholder="Keywords (Seprated by Comma(','))"
                  className="h-65 mb-2"
                  name="keyword"
                  required
                  value={e.keyword}
                  onChange={(e) => handleCustomQueChange(i, e)}
                />
                <Input
                  placeholder="Time Duration (seconds)"
                  className="h-65 mb-2"
                  name="duration"
                  type="number"
                  required
                  value={e.duration}
                  onChange={(e) => handleCustomQueChange(i, e)}
                />
                <Input
                  placeholder="Suggested Answer"
                  className="h-65 mb-2"
                  name="suggestedAnswer"
                  type="text"
                  required
                  value={e.suggestedAnswer}
                  onChange={(e) => handleCustomQueChange(i, e)}
                />
              </div>
            ))}
          </Modal.Body>
          <Modal.Footer>
            {questionmodalloading ? (
              <ButtonBlue className="w-100">
                <Spinner animation="border" size="sm" />
              </ButtonBlue>
            ) : (
              <ButtonBlue className="w-100" type="submit">
                Add New Questions
              </ButtonBlue>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        show={showCreateNewQueModal}
        onHide={() => {
          setUpdateQuestionId("");
          setInterviewnewQue({
            questiontype: "",
            question: "",
            keyword: [],
            suggestedAnswer: "",
            videoQuestion: "",
            videoQuestionLink: "",

            modeofquestion: "",
            modeofanswer: "",
            duration: "",
          });
          setshowCreateNewQueModal(false);
        }}
      >
        <Modal.Header closeButton>
          <DarkHeading>Add New Question</DarkHeading>
        </Modal.Header>
        <Form onSubmit={(e) => handleInterviewQueChange(interviewnewQue, e)}>
          <Modal.Body className="job-form h-70 mx-2">
            <label>Questions Title</label>
            <TextArea
              placeholder="Title"
              className="h-65 secondary border-10 mb-2"
              name="questiontype"
              required
              value={interviewnewQue.questiontype}
              onChange={handlenewquestionchange}
            ></TextArea>
            <div>
              <h5>Enter Your Question</h5>
              <Input
                placeholder="Enter Your Question"
                className="h-65 mb-2"
                name="question"
                required
                value={interviewnewQue.question}
                onChange={handlenewquestionchange}
              />
              <Input
                placeholder="Keywords (Seprated by Comma(','))"
                className="h-65 mb-2"
                name="keyword"
                required
                value={interviewnewQue.keyword}
                onChange={handlenewquestionchange}
              />
              <Input
                placeholder="Time Duration (seconds)"
                className="h-65 mb-2"
                name="duration"
                type="number"
                required
                value={interviewnewQue.duration}
                onChange={handlenewquestionchange}
              />
              <Input
                placeholder="Suggested Answer"
                className="h-65 mb-2"
                name="suggestedAnswer"
                required
                value={interviewnewQue.suggestedAnswer}
                onChange={handlenewquestionchange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {questionmodalloading ? (
              <ButtonBlue className="w-100">
                <Spinner animation="border" size="sm" />
              </ButtonBlue>
            ) : (
              <ButtonBlue className="w-100" type="submit">
                Add This Questions
              </ButtonBlue>
            )}
          </Modal.Footer>
        </Form>
      </Modal>

      <DashboardBoxStyle className="scroller p-0-525">
        <div className="container my-3">
          <div className="row flex-wrap">
            <div
              className="col-12 col-md-4 position-unset-768 my-2 "
              style={{
                position: "sticky",
                top: "0",
                bottom: "0",
                height: "100%",
              }}
            >
              <div className="d-flex align-items-center mx-0 px-0">
                {/* <BackButton /> */}
                <button className="button-yellow" onClick={() => navigate(-2)}>
                  <BiArrowBack />
                  <span>|</span>
                  Back{" "}
                </button>
                <YellowHeading className="fs-20 my-2 mx-3">
                  Job Interview
                </YellowHeading>
              </div>
              <div className="my-3">
                {createInterViewLeftData.map((item, index) => (
                  <BoxYellowOutline
                    key={index}
                    onClick={() => setIndex(index)}
                    className={`p-1 my-2 cursor-pointer  ${
                      index === activeIndex ? "active-button" : ""
                    }`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="theme-primary">{item.icon}</div>
                      <DarkHeading className="fs-14 fw-500 mx-2">
                        {item.name}
                      </DarkHeading>
                    </div>
                  </BoxYellowOutline>
                ))}
              </div>
              {activeIndex === 1 && <></>}

              {/* {activeIndex === 2 && (
                <div className="my-3">
                  <BoxStyle className="pt-3 p-2 border-10  px-3">
                    <div className="col-12 mb-3">
                      <div className="row mb-1">
                        <div className="col-10">
                          <Paragraph className="mb-1 fw-600 fs-14 mx-1">
                            Video Question Bank
                          </Paragraph>
                        </div>

                        <div className="w-100">
                          <Wrapper>
                            <SearchInput placeholder="Search" />
                            <GridSearchIcon
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "20px",
                              }}
                            />
                          </Wrapper>
                        </div>
                        <div className="category-scroller">
                          {allquestions
                            ?.slice(0, questionshowlist)
                            ?.map((data, i) => (
                              <div
                                className="
                             mt-2"
                                key={i}
                              >
                                <Accordion>
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                      <div className="me-2 d-flex flex-column align-items-center">
                                        <div>
                                          <YellowHeading className="fs-14 fw-600 secondary">
                                            {data?.questiontype
                                              .charAt(0)
                                              .toUpperCase() +
                                              data?.questiontype.slice(1)}
                                          </YellowHeading>
                                          <YellowHeading className="fs-14 mb-0 fw-600">
                                            {data?.questions.length} Questions
                                          </YellowHeading>
                                        </div>
                                      </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="d-flex flex-column">
                                      <ButtonBlue
                                        onClick={() => {
                                          setUpdateQuestionId(data?._id);
                                          setInterviewnewQue({
                                            ...interviewnewQue,
                                            questiontype: data?.questiontype,
                                          });
                                          setshowCreateNewQueModal(true);
                                        }}
                                        className="h-40 px-2 mx-3 mb-3"
                                      >
                                        + Add Question
                                      </ButtonBlue>
                                      {data?.questions.map((data2, i) => (
                                        <Form.Group
                                          key={data2?._id}
                                          className="mb-3 align-items-baseline d-flex"
                                          controlId="formBasicCheckbox"
                                        >
                                          <h6
                                            className="px-3 c-pointer"
                                            onClick={(e) =>
                                              handleInterviewQueChange(data2, e)
                                            }
                                          >
                                            {data2.question}
                                          </h6>
                                        </Form.Group>
                                      ))}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div>
                      {questionshowlist === 3 ? (
                        <YellowHeading
                          className="fs-14 text-center fw-700 secondary c-pointer"
                          onClick={() =>
                            setquestionshowlist(allquestions.length)
                          }
                        >
                          List More +
                        </YellowHeading>
                      ) : (
                        <YellowHeading
                          className="fs-14 text-center fw-700 secondary c-pointer"
                          onClick={() => setquestionshowlist(3)}
                        >
                          List Less
                        </YellowHeading>
                      )}
                    </div>
                  </BoxStyle>
                </div>
              )} */}
            </div>
            <div className="col-12 col-md-8 ">
              {activeIndex === 0 && (
                <BoxStyle className="job-form">
                  <Form
                    onSubmit={() => {
                      setIndex((prev) => prev + 1);
                    }}
                  >
                    {/* <div className="d-flex align-items-center">
                      <div className="interview-logo">
                        <img
                          src={companyImg}
                          className="rounded-circle"
                          width={100}
                          height={100}
                        />
                      </div>
                      <div className="pt-3 mx-1 mx-sm-3">
                        <div className="d-flex justify-content-between">
                          <h5 className="mb-0">
                            <TextArea
                              value={userDetail?.company}
                              id="company"
                              name="company"
                              className="b-none w-100"
                              placeholder="Name Of Company"
                              disabled
                              onChange={handleChange}
                            ></TextArea>
                          </h5>
                        </div>
                         <div className="d-flex justify-content-between">
                          <p className="mb-0">
                            <TextArea
                              value={jobdetails?.companyDescription}
                              name="companyDescription"
                              onChange={handleChange}
                              placeholder="Description of Company"
                              className="b-none w-100"
                            ></TextArea>
                          </p>
                          <div className="mt-1 mx-sm-1 mx-0">
                            <AiTwotoneEdit />
                          </div>
                      </div>
                    </div> */}
                    <label>Job Title*</label>
                    <TextArea
                      placeholder="Title"
                      className="h-65 secondary border-10 mb-2"
                      name="jobtitle"
                      required
                      value={jobdetails?.jobtitle}
                      onChange={handleChange}
                      style={{ color: "#000" }}
                    ></TextArea>

                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <label>Select Domain</label>
                          <CreatableSelect
                            className="select-interview"
                            createOptionPosition="first"
                            placeholder={jobdetails.jobdomain}
                            closeMenuOnSelect={true}
                            onChange={(newval) => {
                              setJobDetails({
                                ...jobdetails,
                                jobdomain: newval.value,
                              });
                            }}
                            options={jobDomain}
                            // onInputChange={changeInputHandler}
                            isClearable={false}

                            // inputValue={titleInput}
                          />
                        </div>

                        <div className="col-12 col-sm-6">
                          <label>Select SubDomain</label>
                          <CreatableSelect
                            className="select-interview"
                            createOptionPosition="first"
                            placeholder={jobdetails.jobcategory}
                            closeMenuOnSelect={true}
                            onChange={(newval) => {
                              setJobDetails({
                                ...jobdetails,
                                jobcategory: newval.value,
                              });
                            }}
                            options={subDomain}
                            // onInputChange={changeInputHandler}
                            isClearable={false}

                            // inputValue={titleInput}
                          />
                        </div>
                      </div>
                    </div>
                    <label>Roles & Responsibilities</label>
                    <TextEditorBox>
                      <ReactQuill
                        theme="snow"
                        value={jobdescription}
                        onChange={setJobDescription}
                      />
                    </TextEditorBox>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <label>Country*</label>
                          <Select
                            className="select-interview"
                            createOptionPosition="first"
                            placeholder={
                              jobdetails.jobcountry || "Select Country"
                            }
                            closeMenuOnSelect={true}
                            onChange={handleCountryChange}
                            options={countryOptions}
                            required
                            isClearable={true}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <label>State*</label>
                          <Select
                            className="select-interview"
                            createOptionPosition="first"
                            placeholder={jobdetails.jobcity || "Select State"}
                            closeMenuOnSelect={true}
                            onChange={handleStateChange}
                            options={stateOptions}
                            required
                            isClearable={true}
                          />
                        </div>
                        <div className="col-12 col-sm-6 mt-3">
                          <label>City*</label>
                          <TextArea
                            className="h-65 secondary border-10 mb-2"
                            name="jobarea"
                            required
                            placeholder="Area"
                            value={jobdetails?.jobarea}
                            style={{ color: "#000" }}
                            onChange={handleChange}
                          ></TextArea>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <div className="col-12 col-sm-6">
                          <label>Job Type</label>
                          <CreatableSelect
                            className="select-interview"
                            createOptionPosition="first"
                            placeholder={jobdetails.jobtype}
                            closeMenuOnSelect={true}
                            onChange={(newval) => {
                              setJobDetails({
                                ...jobdetails,
                                jobtype: newval.value,
                              });
                            }}
                            options={jobTypes}
                            // onInputChange={changeInputHandler}
                            isClearable={false}

                            // inputValue={titleInput}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <label>Job Location</label>
                          <CreatableSelect
                            className="select-interview"
                            createOptionPosition="first"
                            placeholder={jobdetails.joblocation}
                            closeMenuOnSelect={true}
                            onChange={(newval) => {
                              setJobDetails({
                                ...jobdetails,
                                joblocation: newval.value,
                              });
                            }}
                            options={jobLocation}
                            // onInputChange={changeInputHandler}
                            isClearable={false}

                            // inputValue={titleInput}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <label>Years of Experience</label>

                        <div className="col-12 col-sm-6">
                          <CreatableSelect
                            className="select-interview"
                            createOptionPosition="first"
                            placeholder={jobdetails.experience}
                            closeMenuOnSelect={true}
                            onChange={(newval) => {
                              setJobDetails({
                                ...jobdetails,
                                experience: newval.value,
                              });
                            }}
                            options={experience}
                            // onInputChange={changeInputHandler}
                            isClearable={false}

                            // inputValue={titleInput}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <label>Job Expiry Date*</label>
                        <div className="col-12 col-sm-6">
                          <TextArea
                            type="date"
                            name="expirydate"
                            required
                            value={formattedExpiryDate}
                            style={{ color: "#000" }}
                            onChange={handleChange}
                            className="h-65 secondary border-10 mb-2"
                          ></TextArea>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                      <ButtonBlue className="px-4 mx-2" type="submit">
                        Next
                      </ButtonBlue>
                    </div>
                  </Form>
                </BoxStyle>
              )}
              {activeIndex === 1 && (
                <BoxStyle>
                  <DarkHeading className="fs-20 fw-500 mb-3">
                    {jobdetails.jobtitle || "Job Title"}
                  </DarkHeading>
                  <hr />
                  <Paragraph className="fs-14 fw-500 mb-1 secondary">
                    Add Skills*
                  </Paragraph>
                  <div className="tags-input">
                    {interviewskills.length > 0 ? (
                      <ul id="tags">
                        {skillNames.map((skillName, index) => (
                          <li key={index} className="tag">
                            <span className="tag-title">{skillName}</span>
                            <span
                              className="tag-close-icon"
                              onClick={() => removethisskill(index)}
                            >
                              x
                            </span>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <ul id="tags">
                        <h6>Add upto 20 Skills</h6>
                      </ul>
                    )}
                    <CreatableSelect
                      className="select-interview"
                      createOptionPosition="first"
                      placeholder="Add Skills"
                      closeMenuOnSelect={true}
                      onCreateOption={handleCreateSkill}
                      onChange={handleInterviewSkillChange}
                      options={skillsdata.map((data) => ({
                        value: data._id,
                        label: data.skills,
                      }))}
                      isClearable={false}
                    />
                  </div>
                  <div className="col-12 d-flex justify-content-end mt-4 mb-0">
                    <ButtonBlue
                      className="px-5 mx-2"
                      onClick={() => {
                        if (interviewskills.length === 0) {
                          toast.error(
                            "Please Select Atleast One Skill",
                            ToastStyles
                          );
                        } else {
                          setIndex((prev) => prev + 1);
                        }
                      }}
                    >
                      Next
                    </ButtonBlue>
                  </div>
                </BoxStyle>
              )}
              {activeIndex === 2 && (
                <BoxStyle>
                  <div className="d-flex justify-content-between align-items-center">
                    <DarkHeading className="fs-20">
                      Interview Questions
                    </DarkHeading>
                    <ButtonBlue
                      onClick={(e) => {
                        let data = {
                          question: "",
                          keyword: [],
                          suggestedAnswer: "",
                          videoQuestion: "",
                          videoQuestionLink: "",

                          duration: "",
                          hide: false,
                        };
                        handleInterviewQueChange(data, e);
                      }}
                      className="px-2"
                    >
                      + Add New Question
                    </ButtonBlue>
                  </div>
                  {interviewQue?.length > 0 ? (
                    <>
                      {interviewQue?.map((e, i) => (
                        <div key={i}>
                          {e.hide === true ? (
                            <>
                              <div className="d-flex justify-content-between my-3">
                                <h5>
                                  Q{i + 1}. {e?.question}
                                </h5>
                                <ButtonBlue
                                  onClick={(e) => {
                                    let newFormValues = [...interviewQue];
                                    newFormValues[i]["hide"] = false;
                                    setInterviewQue(newFormValues);
                                    localStorage.setItem(
                                      "interviewQue",
                                      JSON.stringify(newFormValues)
                                    );
                                  }}
                                  className="px-5 h-40"
                                >
                                  View
                                </ButtonBlue>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="d-flex justify-content-between my-3">
                                <h5>
                                  Q{i + 1}. {e?.questiontype}
                                </h5>
                                <div
                                  onClick={() => removeInterviewQueField(i)}
                                  className="cancel-icon c-pointer"
                                >
                                  <AiFillCloseCircle color="red " size={24} />
                                </div>
                              </div>
                              <h6>Question</h6>

                              <Input
                                placeholder="Enter Your Question"
                                className="h-65 mb-2"
                                name="question"
                                value={e.question}
                                onChange={(e) =>
                                  handleInterviewQueDataChange(i, e)
                                }
                              />
                              <h6>Keyword</h6>

                              <Input
                                placeholder="Keywords (Seprated by Comma(','))"
                                className="h-65 mb-2"
                                name="keyword"
                                value={e.keyword}
                                onChange={(e) =>
                                  handleInterviewQueDataChange(i, e)
                                }
                              />
                              <h6>Duration (seconds)</h6>

                              <Input
                                placeholder="Time Duration (seconds)"
                                className="h-65 mb-2"
                                name="duration"
                                type="number"
                                value={e.duration}
                                onChange={(e) =>
                                  handleInterviewQueDataChange(i, e)
                                }
                              />
                              <h6>Suggested Answer</h6>

                              <Input
                                placeholder="Suggested Answer"
                                className="h-65 mb-2"
                                name="suggestedAnswer"
                                value={e.suggestedAnswer}
                                onChange={(e) =>
                                  handleInterviewQueDataChange(i, e)
                                }
                              />
                              <h6>Upload Video</h6>

                              {/* <Input
                                placeholder="Suggested Answer"
                                className="h-65 mb-2 pt-3"
                                type="file"
                                name="videoQuestionLink"
                                accept="video/mp4, video/webm"
                                // value={e.videoQuestion}
                                onChange={(e) =>
                                  handleInterviewQueDataChange(i, e)
                                }
                              /> */}
                              <div className="d-flex align-items-center mb-2">
                                <ButtonBlue className="h-65 mr-2 flex-grow-1">
                                  <label style={{ cursor: "pointer" }}>
                                    {e.videoQuestion
                                      ? "Replace Video"
                                      : "Choose File"}
                                    <input
                                      type="file"
                                      name="videoQuestionLink"
                                      accept="video/mp4, video/webm"
                                      style={{ display: "none" }}
                                      onChange={(event) =>
                                        handleReplaceVideoButtonClick(i, event)
                                      }
                                    />
                                  </label>
                                </ButtonBlue>
                              </div>
                              {e.videoQuestion && (
                                <p
                                  style={{ color: "red" }}
                                  className="form-title mb-0"
                                >
                                  {e.videoFileName || "Uploaded"}
                                </p>
                              )}
                              {e.videoQuestion && (
                                <div className="video-preview mt-2">
                                  <p>Preview</p>
                                  <video controls width="400">
                                    <source
                                      src={e.videoQuestion}
                                      type={e.videoQuestion.type}
                                    />
                                    Your browser does not support the video tag.
                                  </video>
                                  {/* <p>{e.videoFileName || "Uploaded"}</p> */}
                                </div>
                              )}
                            </>
                          )}
                        </div>
                      ))}
                      <div className="col-12 d-flex justify-content-end mt-4 mb-2">
                        {uploadVideoLoading ? (
                          <ButtonBlue
                            className="px-5 mx-2"
                            style={{ width: "180px" }}
                          >
                            <Spinner size="sm" animation="border" />
                          </ButtonBlue>
                        ) : (
                          <ButtonBlue
                            style={{ width: "180px" }}
                            onClick={() => uploadVideo()}
                          >
                            Upload Questions
                          </ButtonBlue>
                        )}
                      </div>
                    </>
                  ) : (
                    <Paragraph className="fs-14 fw-500 mb-1 secondary">
                      Add Questions*
                    </Paragraph>
                  )}
                  <hr />

                  <div className="col-12 d-flex justify-content-end mt-4 mb-2">
                    <ButtonBlue
                      className="px-5 mx-2"
                      onClick={() => {
                        if (interviewQue.length === 0) {
                          toast.error(
                            "Please Select Atleast One Question",
                            ToastStyles
                          );
                        } else {
                          setIndex((prev) => prev + 1);
                        }
                      }}
                      disabled={!canProceed}
                    >
                      Next
                    </ButtonBlue>
                  </div>
                </BoxStyle>
              )}
              {activeIndex === 3 && (
                <BoxStyle>
                  <div className="d-flex justify-content-between align-items-center">
                    <DarkHeading className="fs-20">Ask Documents</DarkHeading>
                  </div>

                  <div>
                    <label className="secondary mb-2">
                      Select required documents
                    </label>
                    <div className="col-12 col-sm-7">
                      <BoxYellowOutline className="secondary h-60 p-2 mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <Paragraph className="color-secondary fs-14 mt-0 fw-500 mb-1">
                              Resume
                            </Paragraph>
                          </div>
                          <Form.Check
                            className="checkbox"
                            type="checkbox"
                            value="Resume"
                            checked={documentlist.includes("Resume")}
                            onChange={handleDocumentsListChange}
                          />
                        </div>
                      </BoxYellowOutline>
                      <BoxYellowOutline className="secondary h-60 p-2 mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <Paragraph className="color-secondary fs-14 mt-0 fw-500 mb-1">
                              Cover Letter
                            </Paragraph>
                          </div>
                          <Form.Check
                            value="CoverLetter"
                            onChange={handleDocumentsListChange}
                            className="checkbox"
                            type="checkbox"
                            checked={documentlist.includes("CoverLetter")}
                          />
                        </div>
                      </BoxYellowOutline>
                    </div>
                  </div>
                  {/* <div>
                    <label className="secondary my-3">Selected Documents</label>
                    <Input
                      placeholder="Documents"
                      className="h-65 mb-2"
                      name="docs"
                      value={documentlist}
                      disabled
                    />
                  </div> */}
                  <label className="secondary my-3">
                    If kept "Open" then any user can apply for this interview
                  </label>
                  <BoxYellowOutline className="secondary h-60 p-2 mb-2">
                    <div className="d-flex justify-content-between align-items-center">
                      <div>
                        <Paragraph className="color-secondary fs-14 mt-0 fw-500 mb-1">
                          Open
                        </Paragraph>
                      </div>
                      <Form.Check
                        type="checkbox"
                        name="open"
                        checked={jobdetails.open}
                        onChange={handleCheckboxChange}
                      />
                    </div>
                  </BoxYellowOutline>
                  {!jobdetails.open && (
                    <div className="tags-input">
                      <ul id="tags">
                        {jobdetails.collegeid.length > 0 ? (
                          jobdetails.collegeid.map((id, index) => (
                            <li key={index} className="tag">
                              <span className="tag-title">
                                {
                                  colleges.find((college) => college._id === id)
                                    ?.collegename
                                }
                              </span>
                              <span
                                className="tag-close-icon"
                                onClick={() => removeCollege(index)}
                              >
                                x
                              </span>
                            </li>
                          ))
                        ) : (
                          <h6>Add Colleges</h6>
                        )}
                      </ul>
                      <Select
                        className="select-interview"
                        createOptionPosition="first"
                        placeholder="Add College"
                        closeMenuOnSelect={true}
                        onChange={handleMultiSelectChange}
                        options={collegeOptions}
                        isClearable={false}
                        isMulti // Ensures multiple selections
                      >
                        {/* <option value="">Select College</option>
                        {colleges.length > 0 ? (
                          colleges.map((college) => (
                            <option key={college._id} value={college._id}>
                              {college.collegename}
                            </option>
                          ))
                        ) : (
                          <option value="">No colleges available</option>
                        )} */}
                      </Select>
                    </div>
                  )}

                  <div className="col-12 d-flex justify-content-end mt-4 mb-2">
                    {createInterviewDraftLoading ? (
                      <ButtonYellowOutline className="px-5">
                        <Spinner animation="border" size="sm" />
                      </ButtonYellowOutline>
                    ) : (
                      <ButtonYellowOutline
                        className="px-2 mx-2"
                        onClick={createNewInterviewDraft}
                      >
                        Save As Draft
                      </ButtonYellowOutline>
                    )}

                    {createInterviewLoading ? (
                      <ButtonBlue className="mx-2 px-5">
                        <Spinner animation="border" size="sm" />
                      </ButtonBlue>
                    ) : (
                      <ButtonBlue
                        className=" mx-2 px-2"
                        style={{ width: "auto" }}
                        onClick={createNewInterview}
                      >
                        Create Interview
                      </ButtonBlue>
                    )}
                  </div>
                </BoxStyle>
              )}
            </div>
          </div>
        </div>
      </DashboardBoxStyle>
    </>
  );
};

export default CreateInterviewForm;
