import { BoxStyle, ButtonYellowFill } from 'components/common/CommonStyles'
import React, { useState } from 'react'
import { AiOutlineCheck, AiOutlineStar } from 'react-icons/ai'
import { InterviewPageBox, PurpleBoxYellowBorder } from '../InterviewStyled'
import Logo from "assets/image/logo.svg";
import { Rating } from '@mui/material';
import { useNavigate } from 'react-router';
import { Form, Spinner } from 'react-bootstrap';
import Cookies from 'js-cookie';

const Feedback = ({ feedbackLoading }) => {
  const [formDetails, setFormDetails] = useState({
    rating: 3,
    message: "",
    name: Cookies.get("name"),
  })
  const role = Cookies.get("role");
  const navigate = useNavigate()
  const submitFeedback = () => {
    // console.log(formDetails)
    if (role === "student") {
      navigate("/college-student/dashboard")
    }
    else {
      navigate("/interviewee/dashboard")

    }
  }
  return (
    <>
      {feedbackLoading ? (
        <>
          <InterviewPageBox className=" px-5">
            <div className="d-flex justify-content-center flex-column">
              <div className="m-auto">
                <img style={{ width: "150px" }} src={Logo} alt="" />
              </div>
              <h6>
                We are submitting Your responses,Please do not close your
                browser...
              </h6>
              <div className="d-flex justify-content-center">

                <Spinner size="sm" animation="border" />
              </div>
            </div>
          </InterviewPageBox>
        </>
      ) : (
        <>
          <InterviewPageBox className="px-5">
            <div className="col-12 px-5">
              <BoxStyle>
                <PurpleBoxYellowBorder className="br-50 p-3 my-5">
                  <div className="d-flex align-items-center">
                    <div className="circle-green">
                      <AiOutlineCheck color="#219653" />
                    </div>
                    <div>
                      <h6 className="green mb-0">Congratulations</h6>
                      <p className="green mb-0">
                        Your Video responses have been successfully sent.
                      </p>
                    </div>
                  </div>
                </PurpleBoxYellowBorder>
                <hr />
                <div className="my-5">
                  <h6>You Have Finished the Interview</h6>
                  {/* <p>Share Your Recording Experience</p>
                  <Form.Group className="mb-3" controlId="formBasicEmail">
                    <div>
                      <Rating
                        name="size-large"
                        size="large"
                        value={formDetails.rating}
                        onChange={(event, newValue) => {
                          setFormDetails({ ...formDetails, rating: newValue });
                        }}
                      />
                    </div>
                  </Form.Group>
                  <textarea
                    placeholder="Your Feedback is much appriciated"
                    className="feedback-input mt-2"
                    rows="5"
                    name="message"
                    onChange={(event) => {
                      setFormDetails({ ...formDetails, message: event.target.value });
                    }}
                    value={formDetails.message}
                    cols="50"
                  />
                  <div>
                    <ButtonYellowFill onClick={submitFeedback} className="h-40 mt-2" width="120px">
                      Submit
                    </ButtonYellowFill>
                  </div> */}
                </div>
              </BoxStyle>
            </div>
          </InterviewPageBox>
        </>
      )}
    </>
  )
}

export default Feedback