import React, { useRef, useState } from "react";
import "./common.css";
import axios from "axios";
import { BsArrowUp } from "react-icons/bs";
import { MdFileUpload, MdUploadFile } from "react-icons/md";

export const UploadButton = (props) => {
  const [uplaodedFileName, setUploadFileName] = useState(null);
  const UploadInput = useRef(null);
  const [file, setFile] = useState("");
  const [percentage, setPercentage] = useState(0);
  const [loading, setLoading] = useState(null);
  function fileUploadHandler(e) {
    setUploadFileName(e.target.files[0]);
    setFile(e.target.files[0]);
    uploadLogo(e.target.files[0]);
    // console.log(UploadInput.current.files[0]);
  }
  const uploadLogo = async (e) => {
    if (e) {
      setLoading(true);
      const config = {
        onUploadProgress: (progress) => {
          const percentange = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setPercentage(percentange);
          if (percentange === 100) {
            setLoading(false);
          }
        },
      };
      const formData = new FormData();
      formData.append("file", e);
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://aws-uploader.onrender.com/api"
          : "https://aws-uploader.onrender.com/api";
      setLoading(true);
      axios
        .post(`${rootUrl}/v2/upload/pdf`, formData, config)
        .then((data) => {
          props?.setlogoLink(data?.data?.link);
          // console.log(data.data.link);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
          setLoading(false);
        });
    }
  };
  return (
    <>
      {loading ? (
        <div class="upload-btn-wrapper">
          <button class="btn">{percentage}% Uploading...</button>
        </div>
      ) : (
        <div
          onClick={() => UploadInput.current.click()}
          class="upload-btn-wrapper"
        >
          <button class="btn">
            Upload Image <BsArrowUp />
          </button>
        </div>
      )}

      <input
        ref={UploadInput}
        type="file"
        hidden
        name="companyLogo"
        onChange={fileUploadHandler}
      />
    </>
  );
};
export const UploadButtonInput = () => {
  return (
    <div class="upload-btn-wrapper w-100 h-65">
      <button class="btn-2">
        <div className="d-flex justify-content-between align-items-center">
          Attach a file/ text/ video (default) <MdFileUpload fontSize={20} />
        </div>
      </button>
      <input type="file" name="myfile" />
    </div>
  );
};
