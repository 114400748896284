import React from "react";
import "./header.css";
const Footer = () => {
  const date = new Date();
  return (
    <div className="footer">
      VIOSA.IN Copyright © {date.getFullYear()} VIOSA.IN. All rights reserved.
    </div>
  );
};

export default Footer;
