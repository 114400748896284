import {
  BoxStyle,
  BoxYellowOutline,
  ButtonYellowFill,
  DashboardBoxStyle,
} from "components/common/CommonStyles";
import React, { useState } from "react";
import { AiOutlineLeft, AiOutlineRight } from "react-icons/ai";
import { useNavigate } from "react-router";
import Footer from "./Footer";
import Header from "./Header";
import { InterviewBoxStyled, InterviewPageBox } from "./InterviewStyled";
import './interview.css'
export const SkillAssesment = () => {
  const [questionNo, setQuestionNo] = useState(1);
  const navigate = useNavigate()
  const submitAns = () =>{
navigate("/interview/instruction1")
  }
  return (
    <>
      <Header />
      <InterviewBoxStyled className="scroller interview-form h-70 padding-0 ">
      <InterviewPageBox >
        <div className=" py-3  justify-content-center  d-flex col-12">
          <div className="col-8">
            <BoxStyle className=" assesment-questions">
              <div className="p-3">
                <h5 className="d-flex justify-content-center my-2">
                  Skill Assessment
                </h5>
                {
                  questionNo===1?(<><div className="">
                  <div className="d-flex align-items-start w-100 m-auto">
                    <div className="pe-3 question-no-page">
                      <AiOutlineLeft onClick={()=>setQuestionNo(1)} />
                      <div className="page">{questionNo}/4</div>
                      <AiOutlineRight onClick={()=>setQuestionNo(2)} />
                    </div>
                    <div className="quesetion-ans">
                      <h5 className="question">
                        I am comfortable leading a team through a change in work
                        processes.
                      </h5>
                      <p>Select Answer</p>
                      <div className="ans-options">
                        <div className="ans-option">
                          <h6>Design</h6>
                        </div>
                        <div className="ans-option">
                          <h6>Webflow Designing</h6>
                        </div>
                        <div className="ans-option">
                          <h6>Wireframing</h6>
                        </div>
                        <div className="ans-option">
                          <h6>Wireframing</h6>
                        </div>
                        <div className="ans-option">
                          <h6>Design</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-end w-100 m-auto ">
                    <div
                      onClick={() => setQuestionNo(questionNo + 1)}
                      className="my-4"
                    >
                      <ButtonYellowFill width="150px"> Next</ButtonYellowFill>
                    </div>
                  </div>
                </div></>): (questionNo===2?(<><div className="">
                  <div className="d-flex align-items-start w-100 m-auto">
                    <div className="pe-3 question-no-page">
                      <AiOutlineLeft onClick={()=>setQuestionNo(1)} />
                      <div className="page">{questionNo}/4</div>
                      <AiOutlineRight onClick={()=>setQuestionNo(3)} />
                    </div>
                    <div className="quesetion-ans">
                      <h5 className="question">
                        I am comfortable leading a team through a change in work
                        processes.
                      </h5>
                      <p>Select Answer</p>
                      <div className="ans-options">
                        <div className="ans-option">
                          <h6>Design</h6>
                        </div>
                        <div className="ans-option">
                          <h6>Webflow Designing</h6>
                        </div>
                        <div className="ans-option">
                          <h6>Wireframing</h6>
                        </div>
                        <div className="ans-option">
                          <h6>Wireframing</h6>
                        </div>
                        <div className="ans-option">
                          <h6>Design</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-end w-100 m-auto ">
                    <div
                      onClick={() => setQuestionNo(questionNo + 1)}
                      className="my-4"
                    >
                      <ButtonYellowFill width="150px"> Next</ButtonYellowFill>
                    </div>
                  </div>
                </div></>):(questionNo===3?(<><div className="">
                  <div className="d-flex align-items-start w-100 m-auto">
                    <div className="pe-3 question-no-page">
                      <AiOutlineLeft onClick={()=>setQuestionNo(2)} />
                      <div className="page">{questionNo}/4</div>
                      <AiOutlineRight onClick={()=>setQuestionNo(4)} />
                    </div>
                    <div className="quesetion-ans">
                      <h5 className="question">
                        I am comfortable leading a team through a change in work
                        processes.
                      </h5>
                      <p>Select Answer</p>
                      <div className="ans-options">
                        <div className="ans-option">
                          <h6>Design</h6>
                        </div>
                        <div className="ans-option">
                          <h6>Webflow Designing</h6>
                        </div>
                        <div className="ans-option">
                          <h6>Wireframing</h6>
                        </div>
                        <div className="ans-option">
                          <h6>Wireframing</h6>
                        </div>
                        <div className="ans-option">
                          <h6>Design</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-end w-100 m-auto ">
                    <div
                      onClick={() => setQuestionNo(questionNo + 1)}
                      className="my-4"
                    >
                      <ButtonYellowFill width="150px"> Next</ButtonYellowFill>
                    </div>
                  </div>
                </div></>):(<><div className="">
                  <div className="d-flex align-items-start w-100 m-auto">
                    <div className="pe-3 question-no-page">
                      <AiOutlineLeft onClick={()=>setQuestionNo(3)} />
                      <div className="page">{questionNo}/4</div>
                      <AiOutlineRight onClick={()=>setQuestionNo(4)} />
                    </div>
                    <div className="quesetion-ans">
                      <h5 className="question">
                        I am comfortable leading a team through a change in work
                        processes.
                      </h5>
                      <p>Select Answer</p>
                      <div className="ans-options">
                        <div className="ans-option">
                          <h6>Design</h6>
                        </div>
                        <div className="ans-option">
                          <h6>Webflow Designing</h6>
                        </div>
                        <div className="ans-option">
                          <h6>Wireframing</h6>
                        </div>
                        <div className="ans-option">
                          <h6>Wireframing</h6>
                        </div>
                        <div className="ans-option">
                          <h6>Design</h6>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div>
                  <div className="d-flex justify-content-end w-100 m-auto ">
                    <div
                    className="my-4"
                      onClick={submitAns}
                    >
                      <ButtonYellowFill width="150px"> Submit</ButtonYellowFill>
                    </div>
                  </div>
                </div></>)))
                }
              </div>
            </BoxStyle>
          </div>
        </div></InterviewPageBox>
        <Footer/>
      </InterviewBoxStyled>
    </>
  );
};
