import { Chartbar } from "components/Bar/Chartbar";
import {
  BoxStyle,
  BoxStyleBlueLight,
  BoxYellowOutline,
  ButtonBlue,
  DashboardBoxStyle,
  Paragraph,
  PurpuleHeading,
  YellowHeading,
} from "components/common/CommonStyles";
import React, { useState } from "react";
import trophy from "assets/image/trophy.png";
import tick1 from "assets/image/tick1.png";
import { MyGoalData, MyJourney } from "../data";
import { ProgressLine } from "components/ProgressBar/ProgressBar";
import { Calender } from "components/Calender/Calender";
import { ProgressTrackGoal } from "components/ProgressTrackGoal/ProgressTrackGoal";

export const MyGoal = () => {
  const [active, setActive] = useState(0);
  return (
    <DashboardBoxStyle className="scroller">
      <div className="container">
        <div className="">
          <BoxStyle className="d-flex justify-content-between align-items-center">
            <div>
              <YellowHeading>My Goals</YellowHeading>
              <h6>
                Update your account’s profile information and email address
              </h6>
            </div>
            <div className="d-flex align-items-center">
              <h6 className="m-0 me-3">Joining Date - 10/10/2023</h6>
              <ButtonBlue className="h-40 px-4">
                <b>Level 1</b>
              </ButtonBlue>
            </div>
          </BoxStyle>
          <BoxStyle className="my-3 px-0 py-3 bg-none">
            <div className="row">
              <div className="col-8">
                {active === 0 && (
                  <BoxStyleBlueLight className="">
                    <BoxStyle>
                      <Chartbar />
                    </BoxStyle>
                  </BoxStyleBlueLight>
                )}
                {active === 1 && (
                  <BoxStyleBlueLight className="">
                    <BoxStyle className="p-3 border-10">
                      <BoxYellowOutline className="py-3 px-3 my-3">
                        <div className="d-flex align-items-center">
                          <div className="col-1">
                            <img
                              height={33}
                              width={33}
                              src={trophy}
                              alt="trophy"
                            />
                          </div>
                          <div className="col-3">
                            <Paragraph className="fs-16 mb-0 fw-bold">
                              This Week Goal
                            </Paragraph>
                            <Paragraph className="fs-12 mb-0">
                              {new Date().toLocaleDateString()}
                            </Paragraph>
                          </div>
                          <div className="col-8">
                            <ProgressLine />
                          </div>
                        </div>
                      </BoxYellowOutline>
                      <div className="d-flex row my-1">
                        <div className="col-6">
                          <div className="my-2">
                            <BoxYellowOutline className="py-2 px-3">
                              <div className="d-flex align-items-center my-2">
                                <PurpuleHeading className="mb-0">
                                  Level 1
                                </PurpuleHeading>
                                <img
                                  src={tick1}
                                  className="mx-2"
                                  height={20}
                                  width={20}
                                />
                              </div>
                              <div className="my-2">
                                <div className="d-flex align-items-center">
                                  <YellowHeading className="fs-16">
                                    Day - 1 Complete One Interview
                                  </YellowHeading>
                                </div>
                                <div className="d-flex align-items-center">
                                  <YellowHeading className="fs-16">
                                    Day - 1 Complete One Interview
                                  </YellowHeading>
                                </div>
                                <div className="d-flex align-items-center">
                                  <YellowHeading className="fs-16">
                                    Day - 1 Complete One Interview
                                  </YellowHeading>
                                </div>
                              </div>
                            </BoxYellowOutline>
                          </div>
                        </div>
                        <div className="col-6">
                          <div className="my-2">
                            <BoxYellowOutline className="py-2 px-3">
                              <div className="d-flex align-items-center my-2">
                                <PurpuleHeading className="mb-0">
                                  Level 1
                                </PurpuleHeading>
                              </div>
                              <div className="my-2">
                                <div className="d-flex align-items-center">
                                  <YellowHeading className="fs-16-2">
                                    Day - 1 Complete One Interview
                                  </YellowHeading>
                                </div>
                                <div className="d-flex align-items-center">
                                  <YellowHeading className="fs-16-2">
                                    Day - 1 Complete One Interview
                                  </YellowHeading>
                                </div>
                                <div className="d-flex align-items-center">
                                  <YellowHeading className="fs-16-2">
                                    Day - 1 Complete One Interview
                                  </YellowHeading>
                                </div>
                              </div>
                            </BoxYellowOutline>
                          </div>
                        </div>
                      </div>
                    </BoxStyle>
                  </BoxStyleBlueLight>
                )}
                {active === 2 && (
                  <BoxStyleBlueLight className="p-4 py-5">
                    <BoxStyle className="p-0 border-10 py-5">
                      <div className="w-100">
                        <Calender />
                      </div>
                    </BoxStyle>
                  </BoxStyleBlueLight>
                )}
                {active === 3 && (
                  <BoxStyleBlueLight className="p-4 py-5">
                    <BoxStyle className="p-0 border-10 py-5">
                      <div className="w-100">
                        <ProgressTrackGoal data={MyJourney} />
                      </div>
                    </BoxStyle>
                  </BoxStyleBlueLight>
                )}
              </div>
              <div className="col-4">
                <BoxStyleBlueLight
                  className="p-0 py-3"
                  style={{ height: "419px" }}
                >
                  {MyGoalData.map((item, index) => (
                    <div
                      className={`d-flex align-items-center border-top-mygoal px-3 cursor-pointer py-3  ${
                        active === index ? "active-mygoal" : ""
                      } ${index === 3 && "border-bottom-mygoal"} ${
                        index === 0 && "mt-4"
                      } `}
                      onClick={() => setActive(index)}
                    >
                      <img
                        src={item.icon}
                        height={30}
                        width={30}
                        alt={item.title}
                      />
                      <div className="mx-3">
                        <Paragraph className="fs-16 mb-0 fw-bold">
                          {item.title}
                        </Paragraph>
                        <Paragraph className="fs-12 mb-0">
                          {item.description}
                        </Paragraph>
                      </div>
                    </div>
                  ))}
                </BoxStyleBlueLight>
              </div>
            </div>
          </BoxStyle>
        </div>
      </div>
    </DashboardBoxStyle>
  );
};
