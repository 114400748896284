export const jobDomain = [
  {
    id: 1,
    label:"IT",
    value: "IT",
    roles: [
      "Software Engineer",
      "Web Developer",
      "Data Scientist",
      "DevOps",
      "Frontend Developer",
      "Backend Developer",
      "UI/UX Designer",
      "Full Stack Developer",
      "Mobile Developer",
      "Game Developer",
      "QA Engineer",
      "IT Manager",
      "IT Support",
      "IT Consultant",
      "IT Specialist",
      "IT Analyst",
      "IT Executive",
      "IT Assistant",
      "IT Coordinator",
      "IT Technician",
      "IT Engineer",
      "IT Security",
      "IT Auditor",
      "IT Director",
      "IT Administrator",
      "IT Architect",
      "IT Developer",
      "IT Specialist",
      "Flutter Developer",
      "React Native Developer",
      "React Developer",
      "Angular Developer",
      "Node.js Developer",
      "Vue.js Developer",
      "Python Developer",
      "Java Developer",
      "Android Developer",
      "iOS Developer",
      "Ruby Developer",
      "PHP Developer",
      "C# Developer",
      "C++ Developer",
      "C Developer",
      "ASP.NET Developer",
      "Django Developer",
      "Laravel Developer",
      "WordPress Developer",
      "Magento Developer",
      "Drupal Developer",
      "Salesforce Developer",
      "SharePoint Developer",
      "SQL Developer",
      "MySQL Developer",
      "Oracle Developer",
      "MongoDB Developer",
      "PostgreSQL Developer",
      "Database Developer",
      "Database Administrator",
      "Database Architect",
      "Database Analyst",
      "Database Manager",
      "Database Consultant",
      "Database Engineer",
      "Database Specialist",
      "Database Developer",
      "Database Administrator",
      "Database Architect",
      "Database Analyst",
      "Database Manager",
      "Database Consultant",
      "Database Engineer",
      "Database Specialist",
      "Data Analyst",
      "Data Engineer",
      "Data Scientist",
      "Data Architect",
      "Javascript Developer",
      "HTML Developer",
      "CSS Developer",
      "HTML5 Developer",
      "CSS3 Developer",
      "HTML/CSS Developer",
      "HTML/CSS/JS Developer",
      "UI Developer",
      "UX Developer",
      "UI/UX Developer",
    ],
  },
  {
    id: 2,
    value: "Finance",
    label: "Finance",
    roles: [
      "Accountant",
      "Financial Analyst",
      "Financial Advisor",
      "Financial Planner",
      "Investment Banker",
      "Stock Broker",
      "Trader",
    ],
  },
  {
    id: 3,
    value: "Marketing",
    label: "Marketing",
    roles: [
      "Digital Marketing",
      "Content Writer",
      "Social Media Manager",
      "Brand Manager",
      "Marketing Manager",
      "Marketing Analyst",
      "Marketing Executive",
    ],
  },
  {
    id: 4,
    value: "Sales",
    label: "Sales",
    roles: [
      "Sales Manager",
      "Sales Executive",
      "Sales Representative",
      "Sales Engineer",
      "Sales Consultant",
      "Sales Associate",
      "Sales Coordinator",
    ],
  },
  {
    id: 5,
    value: "Human Resources",
    label: "Human Resources",
    roles: [
      "HR Manager",
      "HR Executive",
      "HR Assistant",
      "HR Coordinator",
      "HR Specialist",
      "HR Generalist",
      "HR Recruiter",
    ],
  },
];
