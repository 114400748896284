import React, { useState } from "react";
import loginbg from "assets/image/loginbackground.png";
import {
  BoxStyle,
  DarkHeading,
  Paragraph,
} from "components/common/CommonStyles";
import logo from "assets/image/logo.svg";
import "./auth.css";
import { Login } from "./Login";
import { Signup } from "./Signup";
import { ForgetPassword } from "./ForgetPassword";

export const Authentication = () => {
  const [select, setSelect] = useState(0);
  return (
    <div className="d-flex row ">
      <div className="col-8-5" style={{ overflow: "hidden" }}>
        <img src={loginbg} alt="loginbg" className="login-bg w-100" />
      </div>
      <div className="col-3-5 mt-5">
        <div className="d-flex justify-content-center flex-column align-items-center">
          <img src={logo} width="157px" height="48px" alt="logo" />
          <DarkHeading>Viosa Interview</DarkHeading>
          <Paragraph className="text-center fs-12">
            Indias First AI-Powered Interview portal
          </Paragraph>
        </div>
        <BoxStyle className="background-primary h-380">
          <div className="d-flex">
            <Paragraph
              onClick={() => setSelect(0)}
              className={`fw-400 fs-14 text-left mb-0 cursor-pointer ${select === 0 && "text-underlined text-purple "
                }}`}
            >
              Login
            </Paragraph>
            <Paragraph
              onClick={() => setSelect(1)}
              className={`fw-400 fs-14 mx-3 text-left mb-0 cursor-pointer ${select === 1 && "text-underlined text-purple "
                }}`}
            >
              Signup
            </Paragraph>
            <hr />
          </div>
          {select === 0 && <Login setSelect={setSelect} />}
          {select === 1 && <Signup setSelect={setSelect} />}
          {select === 2 && <ForgetPassword setSelect={setSelect} />}
        </BoxStyle>
      </div>
    </div>
  );
};
