import {
  BoxStyle,
  ButtonYellowFill,
  DashboardBoxStyle,
  YellowHeading,
} from "components/common/CommonStyles";
import React, { useEffect, useState } from "react";
import "./notification.css";
import { getStudentNotificationReq } from '../../../axios/apis';
import { Spinner } from "react-bootstrap";
import { MdNotifications } from "react-icons/md";
import { useNavigate } from "react-router";
import Loading from "components/common/Loading";

const Notification = () => {
  const navigate = useNavigate()

    const [loading, setLoading] = useState(false);
    const [notification, setNotification] = useState();
  const getNotification = async () => {
    try {
      setLoading(true);
      const res = await getStudentNotificationReq();
      setNotification(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };

  useEffect(() => {
    getNotification();
  }, []);

  const gettime = (oldtime) => {
    const datenow = new Date();
    let newtime = Math.abs(new Date(oldtime) - new Date());
    // console.log(new Date(oldtime), datenow)
    let months = newtime / (30 * 24 * 60 * 60 * 1000)
    let days = newtime / (24 * 60 * 60 * 1000)
    let hours = newtime / (60 * 60 * 1000)
    let minutes = newtime / (60 * 1000)
    let secs = newtime / (1000)
    // console.log(months, days, hours, minutes, secs)
    if (months > 12) {
      return "1yr";
    }
    else if (months >= 1) {
      return months.toFixed(0) + "M";
    }
    else if (days >= 1) {
      return days.toFixed(0) + "d";
    }
    else if (hours >= 1) {
      return hours.toFixed(0) + "h";
    }
    else if (minutes >= 1) {
      return minutes.toFixed(0) + "m";
    }
    else {
      return secs.toFixed(0) + "s";
    }
  }

  return (
    <>
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
        <Loading/>
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="container my-0">
            <div className="row">
              <YellowHeading className="my-2">Notifications</YellowHeading>
              {
                notification?.length > 0 ? (<>
                  <div className="mt-0">
                    <div className="col-12 col-sm-8 col-md-6">
                      <BoxStyle className="my-1 p-2">
                        {notification?.map((data) => (<>
                          <div className="notification-border">
                            <div className="notification-box" >
                              <div className="reminder-icon">
                                <MdNotifications size={26} color="#521986" />
                              </div>
                              <div
                                style={{ width: "70%" }}
                                className="remainder-content">
                                <div>
                                  <h6>{data.body}</h6>
                                </div>

                              </div>
                              <div style={{ width: "8%" }}
                                className="remainder-content">
                                <p>{gettime(data?.createdAt)}</p>
                              </div>
                            </div>
                              {data?.title === "Interview Results" ? <>
                                <div className="mt-2 d-flex justify-content-end mx-2">
                                  <ButtonYellowFill  style={{ width: "100px", fontSize: "0.9rem" ,height:"30px"}} onClick={() => navigate(`/college-student/reports/${data?.resultId}`)}>View Reports</ButtonYellowFill>
                                </div>
                              </> : <></>}
                          </div>
                        </>
                        ))}

                      </BoxStyle>
                    </div>
                  </div>
                </>) : (<><h6>Currently no notifications to show</h6></>)
              }

            </div>
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
}


export default Notification