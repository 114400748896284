import {
  ButtonPurpleOutline,
  ButtonYellowFill,
  Paragraph,
  PinkButton,
  Input,
} from "components/common/CommonStyles";
import React, { useEffect, useRef, useState } from "react";
import Footer from "./Footer";
import InterviewLogo from "assets/image/interview-logo.png";
import user from "assets/image/user.png";
import Header from "./Header";
import "./interview.css";
import { AiFillFilePdf, AiFillMail, AiFillPhone } from "react-icons/ai";
import { Form, Spinner } from "react-bootstrap";
import { useNavigate, useParams } from "react-router";
import { MultiStepProgress } from "components/ProgressBar/MultiStepProgress";
import {
  InterviewBoxStyled,
  InterviewPageBox,
  LightPurpleBtn,
  PurpleBoxYellowBorder,
} from "./InterviewStyled";
import { toast } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import axios from "axios";
import {
  GetSingleInterview,
  IntervieweeSignUp,
  IntervieweeLogin,
  LoginAll,
} from "../../axios/apis";
import Loading from "components/common/Loading";
import Cookies from "js-cookie";
import ReCAPTCHA from "react-google-recaptcha";

const ApplyInterview = (item) => {
  const UploadResume = useRef(null);
  const params = useParams();
  const [loading, setLoading] = useState(false);
  const [interviewdata, setInterviewData] = useState({});
  const [skillNames, setSkillNames] = useState([]);
  const [skillDetails, setSkillDetails] = useState([]);
  const [userDetail, setUserDetail] = useState({
    email: "",
    firstname: "",
    lastname: "",
    linkdin: "",
    phoneNumber: "",
    profile: "",
    resume: "",
    password: "qwerty123",
    role: "interviewee",
  });
  const [isLogin, setIsLogin] = useState(false);
  const [showpassword, setShowPassword] = useState(false);

  const [captchaValue, setCaptchaValue] = useState("");
  const [isCaptchaVerified, setIsCaptchaVerified] = useState(false); // State to track captcha verification
  const handleCaptchaChange = (value) => {
    setCaptchaValue(value);
    setIsCaptchaVerified(true); // Set captcha verification state to true when user interacts with captcha
  };
  const GetInterviewDetails = async () => {
    setLoading(true);
    try {
      const response = await GetSingleInterview({ id: params.id });
      console.log("interview", response);
      console.log(response.data.interview.skills);
      setInterviewData(response.data.interview);
      setSkillNames(response.data.interview.skills);
      setLoading(false);
    } catch (error) {
      // console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  const fetchSkillDetails = async (skillNames) => {
    // try {
    //   const response = await fetch(
    //     // `https://interview-api.viosa.in/interview/skills/${skillId}`
    //     `https://api.viosa.in/interview-pro/interview/skills/${skillNames}`
    //     // `http://localhost:3200/interview-pro/interview/skills/${skillId}`
    //   );
    //   const data = await response.json();
    //   console.log("data", data);
    //   console.log("response", response);
    //   return data.skills; // Assuming the API returns the skill object with a 'skills' field containing the skill name.
    // } catch (error) {
    //   // console.error(`Error fetching skill details for ${skillId}:`, error);
    //   return null;
    // }
    try {
      const skillsPromises = skillNames.map(async (skillId) => {
        console.log("skillid", skillId);
        const response = await fetch(
          `https://api.viosa.in/interview-pro/interview/skills/${skillId}`
        );
        console.log("response", response);
        const data = await response.json();
        return data.skills; // Assuming the API returns an object with a 'skills' property.
      });
      const skills = await Promise.all(skillsPromises);
      console.log("skills", skills);
      return skills;
    } catch (error) {
      console.error("Error fetching skill details:", error);
      return [];
    }
  };
  console.log(interviewdata);
  useEffect(() => {
    GetInterviewDetails();
    // fetchSkillDetails();
  }, [params.id]);
  useEffect(() => {
    const fetchSkills = async () => {
      if (Array.isArray(item.skills) && item.skills.length > 0) {
        const skills = await fetchSkillDetails(item.skills);
        console.log("skills", skills);
        setSkillDetails(skills);
      } else {
        setSkillDetails([]);
      }
    };

    fetchSkills();
  }, [item.skills]);
  const handleChange = (e) => {
    setUserDetail({ ...userDetail, [e.target.name]: e.target.value });
  };
  const navigate = useNavigate();
  const UploadDetails = (e) => {
    e.preventDefault();
    // console.log(userDetail)
    // setShow(true);
  };
  const removeProfilePhoto = () => {
    setUserDetail({ ...userDetail, profile: "" });
    toast.success("Profile Image Removed Successfully", ToastStyles);
  };
  const uploadIcon = async (e) => {
    if (e.target.files[0]) {
      setLoadingIconupload(true);
      const config = {
        onUploadProgress: (progress) => {
          const percentange = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setPercentage(percentange);
          if (percentange === 100) {
            setLoadingIconupload(false);
          }
        },
      };
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      setLoadingIconupload(true);
      axios
        .post(`${rootUrl}/v2/upload/other`, formData, config)
        .then((data) => {
          // console.log(data.data.link);
          setUserDetail({ ...userDetail, [e.target.name]: data.data.link });
          setLoadingIconupload(false);
          toast.success("Image Uploaded Successfully", ToastStyles);
        })
        .catch((error) => {
          toast.error("Try again", ToastStyles);
          console.error(error);
          setLoadingIconupload(false);
        });
    }
  };
  const UploadInput = useRef(null);
  const [percentage, setPercentage] = useState(0);
  const [loadingiconupload, setLoadingIconupload] = useState(null);
  const [loadingfileUpload, setLoafingFileUpload] = useState(false);
  const uploadFile = async (e) => {
    if (e.target.files[0]) {
      setLoafingFileUpload(true);
      const config = {
        onUploadProgress: (progress) => {
          const percentange = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setPercentage(percentange);
          if (percentange === 100) {
            setLoafingFileUpload(false);
          }
        },
      };
      const formData = new FormData();
      formData.append("file", e.target.files[0]);
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      setLoafingFileUpload(true);

      axios
        .post(`${rootUrl}/v2/upload/pdf`, formData, config)
        .then((data) => {
          // console.log(data.data.link);
          setUserDetail({ ...userDetail, [e.target.name]: data.data.link });
          setLoafingFileUpload(false);
          toast.success("File Uploaded Successfully", ToastStyles);
        })
        .catch((error) => {
          toast.error("Try again", ToastStyles);
          console.error(error);
          setLoafingFileUpload(false);
        });
    }
  };
  const [signuploading, setsignupLoading] = useState(false);
  // const signup = async (e) => {
  //   e.preventDefault();
  //   console.log(userDetail);
  //   setsignupLoading(true);
  //   try {
  //     const res = await IntervieweeSignUp(userDetail);
  //     console.log("API Response:", res); // Log the entire API response
  //     if (res?.data?.status === 400) {
  //       alert("Email already Exist");
  //     } else {
  //       console.log(res);
  //       toast.success("Signup Successfully", ToastStyles);
  //       Cookies.set("token", res?.data?.token);
  //       Cookies.set("name", res?.data?.data?.interviewee?.firstname);
  //       Cookies.set("email", res?.data?.data?.interviewee?.email);
  //       Cookies.set("profileImg", res?.data?.data?.interviewee?.profile);
  //       setsignupLoading(false);
  //       Cookies.set("role", "interviewee");
  //       Cookies.set("logintype", "interviewLink");
  //       navigate(`/interview/${params.id}`);
  //     }
  //   } catch (error) {
  //     console.log(error);
  //     toast.error("Try Again", ToastStyles);
  //     // navigate(`/interview/${params.id}`);
  //     setsignupLoading(false);
  //   }
  // };
  // const signup = async (e) => {
  //   e.preventDefault();
  //   console.log(userDetail);

  //   try {
  //     // Attempt to log in the user
  //     const loginRes = await LoginAll(userDetail);
  //     if (loginRes?.data) {
  //       if (loginRes.data.status === 400) {
  //         // // User with the same email already exists, proceed with login
  //         // console.log("User already exists:", loginRes);

  //         // // Add your logic to store user data in the database (if needed)
  //         // // await StoreUserDataInDB(existingUser.data.user._id, userDetail);

  //         // toast.success("Login Successfully", ToastStyles);
  //         // Cookies.set("token", loginRes?.data?.token);
  //         // Cookies.set("name", loginRes?.data?.data?.interviewee?.firstname);
  //         // Cookies.set("email", loginRes?.data?.data?.interviewee?.email);
  //         // Cookies.set("profileImg", loginRes?.data?.data?.interviewee?.profile);
  //         // Cookies.set("role", "interviewee");
  //         // Cookies.set("logintype", "interviewLink");
  //         // // Navigate to /interview/${params.id}
  //         // navigate(`/interview/${params.id}`);
  //         // User not found during login, proceed with signup
  //         console.log("User not found during login, proceeding with signup...");

  //         // If login fails (user doesn't exist), proceed with signup
  //         const signupRes = await IntervieweeSignUp(userDetail);

  //         if (signupRes?.status === 400) {
  //           alert("Email already exists");
  //         } else if (signupRes?.status === 500) {
  //           alert("Account already exists");
  //         } else {
  //           console.log(signupRes);
  //           toast.success("Signup Successfully", ToastStyles);
  //           Cookies.set("token", signupRes?.data?.token);
  //           Cookies.set("name", signupRes?.data?.data?.interviewee?.firstname);
  //           Cookies.set("email", signupRes?.data?.data?.interviewee?.email);
  //           Cookies.set(
  //             "profileImg",
  //             signupRes?.data?.data?.interviewee?.profile
  //           );
  //           Cookies.set("role", "interviewee");
  //           Cookies.set("logintype", "interviewLink");
  //           // Navigate to /interview/${params.id}
  //           navigate(`/interview/${params.id}`);
  //         }
  //       } else if (loginRes.data.status === 200) {
  //         // User with the same email already exists, proceed with login
  //         console.log("User already exists:", loginRes);

  //         // Add your logic to store user data in the database (if needed)
  //         // await StoreUserDataInDB(existingUser.data.user._id, userDetail);

  //         toast.success("Login Successfully", ToastStyles);
  //         Cookies.set("token", loginRes?.data?.token);
  //         Cookies.set("name", loginRes?.data?.data?.interviewee?.firstname);
  //         Cookies.set("email", loginRes?.data?.data?.interviewee?.email);
  //         Cookies.set("profileImg", loginRes?.data?.data?.interviewee?.profile);
  //         Cookies.set("role", "interviewee");
  //         Cookies.set("logintype", "interviewLink");
  //         // Navigate to /interview/${params.id}
  //         navigate(`/interview/${params.id}`);
  //       } else {
  //         // If login fails (user doesn't exist), proceed with signup
  //         const signupRes = await IntervieweeSignUp(userDetail);

  //         if (signupRes?.status === 400) {
  //           alert("Email already exists");
  //         } else if (signupRes?.status === 500) {
  //           alert("Account already exists");
  //         } else {
  //           console.log(signupRes);
  //           toast.success("Signup Successfully", ToastStyles);
  //           Cookies.set("token", signupRes?.data?.token);
  //           Cookies.set("name", signupRes?.data?.data?.interviewee?.firstname);
  //           Cookies.set("email", signupRes?.data?.data?.interviewee?.email);
  //           Cookies.set(
  //             "profileImg",
  //             signupRes?.data?.data?.interviewee?.profile
  //           );
  //           Cookies.set("role", "interviewee");
  //           Cookies.set("logintype", "interviewLink");
  //           // Navigate to /interview/${params.id}
  //           navigate(`/interview/${params.id}`);
  //         }
  //       }
  //     } else {
  //       // Handle unexpected response format
  //       console.error("Unexpected response format from LoginAll:", loginRes);
  //     }
  //   } catch (error) {
  //     // Handle error, for example, display a message or log it
  //     console.error("Error during login or signup:", error.response.data);
  //   }
  // };
  const signup = async (e) => {
    e.preventDefault();
    // console.log(userDetail);
    if (!isCaptchaVerified) {
      // Check if captcha is verified
      toast.error("Captcha Not Filled");
      return; // Return early if captcha is not verified
    }
    setsignupLoading(true);
    try {
      const captchaResponse = await verifyCaptcha(captchaValue);
      // console.log(captchaResponse);
      if (!captchaResponse.success) {
        // Display error message for CAPTCHA verification failure
        setsignupLoading(false); // Set loading state to false
        return;
      }
      const res = await IntervieweeSignUp(userDetail);
      console.log("res", res);
      if (res?.data?.status === 400) {
        alert("Email already Exist");
      } else {
        // console.log(res);
        toast.success("Signup Successfully", ToastStyles);
        Cookies.set("token", res?.data?.token);
        Cookies.set("name", res?.data?.data?.interviewee?.firstname);
        Cookies.set("email", res?.data?.data?.interviewee?.email);
        Cookies.set("profileImg", res?.data?.data?.interviewee?.profile);
        setsignupLoading(false);
        Cookies.set("role", "interviewee");
        Cookies.set("logintype", "interviewLink");
        // navigate(`/interview/${params.id}`);
        navigate(`/interview/upload-documents/${params.id}`);
      }
    } catch (error) {
      // console.log(error);
      toast.error("Try Again", ToastStyles);
      setsignupLoading(false);
    }
  };

  const login = async (e) => {
    e.preventDefault();
    // console.log(userDetail);
    if (!isCaptchaVerified) {
      // Check if captcha is verified
      toast.error("Captcha Not Filled");
      return; // Return early if captcha is not verified
    }
    setsignupLoading(true);
    try {
      const captchaResponse = await verifyCaptcha(captchaValue);
      // console.log(captchaResponse);
      if (!captchaResponse.success) {
        // Display error message for CAPTCHA verification failure
        setsignupLoading(false); // Set loading state to false
        return;
      }
      const data = {
        email: userDetail?.email,
        password: userDetail?.password,
      };
      const res = await IntervieweeLogin(data);
      console.log("res", res);
      toast.success("Login Successfully", ToastStyles);
      Cookies.set("token", res?.data?.token);
      Cookies.set("name", res?.data?.data?.interviewee?.firstname);
      Cookies.set("email", res?.data?.data?.interviewee?.email);
      Cookies.set("profileImg", res?.data?.data?.interviewee?.profile);
      setsignupLoading(false);
      Cookies.set("role", res?.data?.data?.interviewee?.hireProRole);
      Cookies.set("logintype", "interviewLink");
      const data2 = {
        email: userDetail?.email,
        password: userDetail?.password,
        token: res?.data?.token,
      };
      navigate(`/interview/upload-documents/${params.id}`, {
        state: { user: data2 },
      });
      // navigate(`/interview/${params.id}`);
    } catch (error) {
      // console.log(error);
      toast.error(String(error?.response?.data?.message), ToastStyles);
      setsignupLoading(false);
    }
  };

  // Calculate if interview is expired
  const isExpired = interviewdata?.expirydate
    ? new Date(interviewdata.expirydate) < new Date() || interviewdata.draft
    : false;
  const verifyCaptcha = async (token) => {
    try {
      // const response = await axios.post("/verify-captcha", { token });
      // const response = await fetch("https://api.viosa.in/verify-captcha", {
      const response = await fetch("https://api.viosa.in/verify-captcha", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          token: token,
        }),
      });
      // console.log(response);
      const responseData = await response.json(); // Parse response body as JSON
      // console.log(responseData); // Log the parsed response data
      return responseData;
    } catch (error) {
      throw error;
    }
  };
  return (
    <>
      <Header />
      {loading ? (
        <>
          <InterviewBoxStyled className=" interview-form d-flex scroller h-70 padding-0 align-items-center justify-content-center">
            <Loading />
          </InterviewBoxStyled>
        </>
      ) : isExpired ? (
        <InterviewBoxStyled className="scroller apply-interview interview-form h-70 padding-0">
          <InterviewPageBox className="px-5">
            <div className="interview-content text-center">
              <h1 className="text-danger">The Interview is Expired</h1>
            </div>
          </InterviewPageBox>
        </InterviewBoxStyled>
      ) : (
        <InterviewBoxStyled className="scroller apply-interview interview-form h-70 padding-0">
          <InterviewPageBox className="px-5">
            <div>
              <div className="section-1 p-3">
                <div className="mt-4 mb-2 step-counter">
                  <MultiStepProgress />
                </div>
              </div>
              <div className="section-2 my-3 col-12 d-flex">
                <div className="col-12 col-md-8">
                  <PurpleBoxYellowBorder className="mx-3 margin-res">
                    <div className="dark-purple-bg p-3">
                      <div className="d-flex align-items-center justify-content-between align-item-center">
                        <div className="d-flex ">
                          <img
                            src={interviewdata?.icon || InterviewLogo}
                            alt="logo"
                            className="rounded-circle "
                            width={100}
                            height={100}
                          />
                          <div className="px-3 d-flex flex-column justify-content-center">
                            <p className="my-1">
                              Company Name: <b>{interviewdata?.company}</b>
                            </p>
                            <h6 className="my-1">
                              {interviewdata?.jobtitle || "Job title"}
                            </h6>
                            <p className="my-1">
                              {interviewdata?.jobarea}, {interviewdata?.jobcity}
                              , India({interviewdata?.joblocation})
                            </p>
                          </div>
                        </div>
                        <div>
                          <LightPurpleBtn
                            className="d-none-820"
                            style={{ width: "100px" }}
                          >
                            Apply now
                          </LightPurpleBtn>
                        </div>
                      </div>
                    </div>
                    <div className="p-3">
                      <h5>Employment Type</h5>
                      <p>{interviewdata?.jobtype}</p>
                      <h5>Role Overview</h5>
                      <Paragraph className="mb-0">
                        <div
                          dangerouslySetInnerHTML={{
                            __html: interviewdata?.jobdescription,
                          }}
                        ></div>
                      </Paragraph>
                      <h5>Required Skills</h5>
                      <div className="skills-box">
                        {/* {interviewdata?.skills?.map((b, ind) => (
                          <PinkButton className="mx-1" key={ind}>
                            {b.skills}
                          </PinkButton>
                        ))} */}
                        {skillDetails.map((skill, index) => (
                          <PinkButton key={index} className="fs-12 mx-1 my-1">
                            {skill}
                          </PinkButton>
                        ))}
                      </div>
                    </div>
                  </PurpleBoxYellowBorder>
                </div>
                <div className="col-12 col-md-4">
                  <PurpleBoxYellowBorder className="me-3 margin-res mt-0">
                    {/* <div className="d-flex align-items-center px-3 mt-3">
                    <img
                      src={userDetail.profile || user}
                      style={{ marginRight: "20px" }}
                      className="rounded-circle"
                      width={80}
                      height={80}
                      alt=""
                    />
                    <div className="d-flex flex-column">
                      {loadingiconupload ? (
                        <ButtonPurpleOutline
                          className="mb-2"
                          style={{ width: "210px" }}
                        >
                          {percentage}% Uploading...
                        </ButtonPurpleOutline>
                      ) : (
                        <ButtonPurpleOutline
                          onClick={() => UploadInput.current.click()}
                          className="mb-2"
                          style={{ width: "210px" }}
                        >
                          SELECT A NEW PHOTO
                        </ButtonPurpleOutline>
                      )}

                      <input
                        ref={UploadInput}
                        type="file"
                        hidden
                        name="profile"
                        onChange={uploadIcon}
                      />
                      <ButtonPurpleOutline
                        onClick={removeProfilePhoto}
                        style={{ width: "160px" }}
                      >
                        REMOVE PHOTO
                      </ButtonPurpleOutline>
                    </div>
                  </div> */}
                    {isLogin ? (
                      <Form onSubmit={login} className="p-4">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="form-title">Email</Form.Label>
                          <Form.Control
                            name="email"
                            required
                            value={userDetail.email}
                            onChange={handleChange}
                            className="form-input"
                            type="email"
                            placeholder="abc@gmail.com"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="form-title">
                            Password
                          </Form.Label>
                          <Form.Control
                            className="form-input"
                            type="password"
                            name="password"
                            required
                            value={userDetail.password}
                            onChange={handleChange}
                            placeholder="password"
                          />
                        </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">
                          Linkedin Profile
                        </Form.Label>
                        <Form.Control
                          className="form-input"
                          type="text"
                          name="linkdin"
                          value={userDetail.linkdin}
                          onChange={handleChange}
                          placeholder="https://www.linkedin.com/in/will"
                        />
                      </Form.Group> */}
                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">
                          Resume (pdf only)*
                        </Form.Label>
                        <div
                          className="form-input pdf-input-box"
                          onClick={() => UploadResume.current.click()}
                        >
                          {
                            loadingfileUpload ? (<><Spinner size="sm" animation="border" /></>) : (<><AiFillFilePdf color="red" />
                              <h6>
                                Drag your pdf here or click to{" "}
                                <span className="yellow">browse</span>{" "}
                              </h6>
                              <p className="purple">Support PDF file only</p></>)
                          }
  
                        </div>
                        <input
                          className="form-input"
                          type="file"
                          name="resume"
                          ref={UploadResume}
                          hidden
                          onChange={uploadFile}
                        />
                        {userDetail?.resume && (
                          <p style={{ color: "red" }} className="form- mb-0">
                            Uploaded
                          </p>
                        )}
                      </Form.Group> */}
                        <p style={{ marginBottom: "5px" }}>
                          By applying, you agree with{" "}
                          <span className="yellow">
                            VIOSA.IN Candidate Terms of Use
                          </span>
                        </p>
                        <p
                          onClick={() => setIsLogin(!isLogin)}
                          className="yellow"
                        >
                          {isLogin ? "Signup" : "Login"}
                        </p>
                        <ReCAPTCHA
                          sitekey="6LcCodspAAAAANEomp20gNinaVp1PizoS-GEc-0Y"
                          onChange={handleCaptchaChange}
                          style={{
                            margin: "0px 0px 10px 0px",
                          }}
                        />
                        {signuploading ? (
                          <>
                            <ButtonYellowFill>
                              <Spinner animation="border" size="sm" />
                            </ButtonYellowFill>
                          </>
                        ) : (
                          <>
                            <ButtonYellowFill type="submit">
                              Submit
                            </ButtonYellowFill>
                          </>
                        )}
                      </Form>
                    ) : (
                      <Form onSubmit={signup} className="p-4">
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="form-title">
                            First Name
                          </Form.Label>
                          <Form.Control
                            className="form-input"
                            type="text"
                            required
                            name="firstname"
                            value={userDetail.firstname}
                            onChange={handleChange}
                            // placeholder="Deepa"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="form-title">
                            Last Name
                          </Form.Label>
                          <Form.Control
                            className="form-input"
                            type="text"
                            name="lastname"
                            value={userDetail.lastname}
                            onChange={handleChange}
                            // placeholder="chawan"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="form-title">Email</Form.Label>
                          <Form.Control
                            name="email"
                            required
                            value={userDetail.email}
                            onChange={handleChange}
                            className="form-input"
                            type="email"
                            placeholder="abc@gmail.com"
                          />
                        </Form.Group>
                        <Form.Group className="mb-3" controlId="formBasicEmail">
                          <Form.Label className="form-title">
                            Phone Number
                          </Form.Label>
                          <Form.Control
                            className="form-input"
                            type="phone"
                            name="phoneNumber"
                            required
                            value={userDetail.phoneNumber}
                            onChange={handleChange}
                            placeholder=""
                          />
                        </Form.Group>
                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">
                          Password
                        </Form.Label>
                        <Form.Control
                          className="form-input"
                          type="text"
                          name="password"
                          required
                          value={userDetail.password}
                          onChange={handleChange}
                          placeholder=""
                        />
                      </Form.Group> */}
                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">
                          Linkedin Profile
                        </Form.Label>
                        <Form.Control
                          className="form-input"
                          type="text"
                          name="linkdin"
                          value={userDetail.linkdin}
                          onChange={handleChange}
                          placeholder="https://www.linkedin.com/in/will"
                        />
                      </Form.Group> */}
                        {/* <Form.Group className="mb-3" controlId="formBasicEmail">
                        <Form.Label className="form-title">
                          Resume (pdf only)*
                        </Form.Label>
                        <div
                          className="form-input pdf-input-box"
                          onClick={() => UploadResume.current.click()}
                        >
                          {
                            loadingfileUpload ? (<><Spinner size="sm" animation="border" /></>) : (<><AiFillFilePdf color="red" />
                              <h6>
                                Drag your pdf here or click to{" "}
                                <span className="yellow">browse</span>{" "}
                              </h6>
                              <p className="purple">Support PDF file only</p></>)
                          }
  
                        </div>
                        <input
                          className="form-input"
                          type="file"
                          name="resume"
                          ref={UploadResume}
                          hidden
                          onChange={uploadFile}
                        />
                        {userDetail?.resume && (
                          <p style={{ color: "red" }} className="form- mb-0">
                            Uploaded
                          </p>
                        )}
                      </Form.Group> */}
                        <p style={{ marginBottom: "5px" }}>
                          By applying, you agree with{" "}
                          <span className="yellow">
                            VIOSA.IN Candidate Terms of Use
                          </span>
                        </p>
                        <p
                          onClick={() => setIsLogin(!isLogin)}
                          className="yellow"
                        >
                          {isLogin ? "Signup" : "Login"}
                        </p>
                        <ReCAPTCHA
                          sitekey="6LcCodspAAAAANEomp20gNinaVp1PizoS-GEc-0Y"
                          onChange={handleCaptchaChange}
                          style={{
                            margin: "0px 0px 10px 0px",
                          }}
                        />
                        {signuploading ? (
                          <>
                            <ButtonYellowFill>
                              <Spinner animation="border" size="sm" />
                            </ButtonYellowFill>
                          </>
                        ) : (
                          <>
                            <ButtonYellowFill type="submit">
                              Submit
                            </ButtonYellowFill>
                          </>
                        )}
                      </Form>
                    )}
                  </PurpleBoxYellowBorder>
                </div>
              </div>
            </div>
          </InterviewPageBox>
          <Footer />
        </InterviewBoxStyled>
      )}
    </>
  );
};

export default ApplyInterview;
