import { Bar } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js';
import { useEffect, useRef, useState } from "react";
ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend
);
function createGradient(CanvasRenderingContext2D) {
  const gradient = CanvasRenderingContext2D?.createLinearGradient(
    0,
    100,
    600,
    100
  );

  gradient?.addColorStop(0, "rgba(133, 0, 255, 1)");
  gradient?.addColorStop(0.52, "rgba(192, 123, 255, 1)");
  gradient?.addColorStop(1, "rgba(219, 184, 255, 1)");

  return gradient;
}
export const Chartbar = () => {
  // const chartRef = useRef(null);
  // const [ref, setRef] = useState(null);
  // console.log(ref);
  // useEffect(() => {
  //   const chart = chartRef?.current;
  //   debugger
  //   setTimeout(() => {
  //     if (chart) {
  //       const { ctx } = chart;
  //       setRef(ctx);
  //     }
  //   }, 1000);
  // }, [ref]);

  return (
    <div>
      <div className="App">
        <div style={{ maxWidth: "750px", height: "300px" }}>
          <Bar
            // ref={chartRef}
            data={{
              // Name of the variables on x-axies for each bar
              labels: ["Mon", "Tue", "Wed", "Thu", "Fri", "Sat", "Sun"],
              datasets: [
                {
                  // Label for bars
                  label: "total count/value",
                  // Data or value of your each variable
                  data: [300, 100, 13, 123, 220, 44, 240],
                  // Color of each bar
                  fill: true,
                  backgroundColor: "#C689FF",
                  // Border color of each bar
                  borderColor: "rgba(255, 255, 255, 0.5)",
                  borderWidth: 0.5,
                  borderRadius: 4,
                },
              ],
            }}
            // Height of graph
            height={400}
            options={{
              maintainAspectRatio: false,
              scales: {
                x: {
                  grid: {
                    display: false,
                  },
                },
                y: {
                  id: "y-axis-density",
                  grid: {
                    display: false,
                  },
                },
              },
              legend: {
                labels: {
                  fontSize: 15,
                },
              },
            }}
          />
        </div>
      </div>
    </div>
  );
};
