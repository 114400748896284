import React from "react";
import cardsample from "assets/image/cardsample.png";

import { BsFillFileEarmarkFill } from "react-icons/bs";
import { ButtonBlue } from "components/common/CommonStyles";
const CandidateCard = () => {
  return (
    <div className="candidate-card p-3">
      <img src={cardsample} alt="candidate" />
      <div className="my-3 px-2">
        <h5 className="title">Priya Murgan</h5>
        <p>
          Lorem ipsum dolor sit amet consectetur, adipisicing elit. Quaerat
          cupiditate a suscipit ipsam aut. Ab quam exercitationem aspernatur
        </p>
      </div>
      <div className="d-flex justify-content-between align-items-center copy-icon px-2">
        <BsFillFileEarmarkFill />
        <ButtonBlue className="h-40 px-4">
          <b>Course Content</b>
        </ButtonBlue>
      </div>
    </div>
  );
};

export default CandidateCard;
