import { Table } from "components/Table/Table";
import {
  BoxStyle,
  ButtonBlue,
  DarkHeading,
  DashboardBoxStyle,
  Paragraph,
  YellowHeading,
} from "components/common/CommonStyles";
import React, { useEffect, useState } from "react";
import { MdClose, MdDone } from "react-icons/md";
import { CorporateSignUp, DeleteCorporateById, GetCorporatesAll, UpdateCorporateById } from "../../axios/apis";
import { Toaster, toast } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import { Form, Modal, Spinner } from "react-bootstrap";
import Loading from "components/common/Loading";

export const Corporates = () => {

  const [loading, setLoading] = useState(false)
  const [interview, setInverView] = useState({
    expiry: true,
    renewed: false,
    newapplication: false,
  });
  const [corporates, setcorporates] = useState([])

  const GetAllCorporates = async () => {
    setLoading(true);
    try {
      const response = await GetCorporatesAll();
      console.log(response);
      setcorporates(response?.data?.data);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };

  const AllowAccess = async (e) => {
    console.log(e)
    const res = window.confirm("Are you sure you want to Approve?");
    if (res) {
      setLoading(true);
      try {
        const data = {
          id: e._id,
          status: true,
        }
        const response = await UpdateCorporateById(data);
        GetAllCorporates()
        console.log(response);
        toast.success("Approved Successfully", ToastStyles)
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Try Again", ToastStyles);
        setLoading(false);
      }
    }
  }
  const DeleteRequest = async (e) => {
    console.log(e)
    const res = window.confirm("Are you sure you want to Reject and Delete?");
    if (res) {
      setLoading(true);
      try {
        const data = {
          id: e._id,
        }
        const response = await DeleteCorporateById(data);
        console.log(response);
        GetAllCorporates()
        toast.error("Deleted Successfully", ToastStyles)
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Try Again", ToastStyles);
        setLoading(false);
      }
    }
  }
  useEffect(() => {
    GetAllCorporates();
  }, []);
  const columns = [
    {
      field: "profileImg",
      headerName: "",
      minWidth: 100,
      renderCell: (params) => (
        <div>
          <img src={params?.value} height={40} width={40} alt="profile" />
        </div>
      ),
    },
    {
      field: "company",
      headerName: "Company",
      minWidth: 170,
      renderCell: (row) => (
        <div>
          {row?.value}
        </div>
      ),
    },
    {
      field: "firstname",
      headerName: "Name",
      minWidth: 170,
      renderCell: (row) => (
        <div>
          {row?.value + " " + row?.row?.lastname}
        </div>
      ),
    },
    {
      field: "jobposting",
      headerName: "Job Posting",
      minWidth: 130,
      renderCell: (params) => (
        <div>
          {params?.value}
        </div>
      ),
    },
    {
      field: "phoneNumber",
      headerName: "Phone Number",
      minWidth: 170,
      renderCell: (params) => (
        <div>
          {params?.value}
        </div>
      ),
    },
    {
      field: "email",
      headerName: "Email",
      minWidth: 200,
      renderCell: (params) => (
        <div>
          {params?.value}
        </div>
      ),
    },
    {
      field: "status",
      headerName: "STATUS",
      minWidth: 170,
      renderCell: (params) =>
        params.value === true ? (
          <div className="mx-auto">

            <ButtonBlue className="table-button px-4 mx-0">Active</ButtonBlue>
          </div>

        ) : (
          <div className="mx-auto">

            <ButtonBlue className="table-button-danger px-4 mx-0">
              Stopped
            </ButtonBlue>
          </div>
        ),
    },
    {
      field: "share",
      headerName: "Access",
      minWidth: 140,
      renderCell: (params) =>
        params.row.status === true ? (
          <div className="mx-auto">
            🟢
          </div>
        ) : (
          <>
            <div onClick={() => { AllowAccess(params.row) }} className="circle-border-50 mx-2">
              <MdDone color="#219653" fontSize={24} />
            </div>
            <div onClick={() => { DeleteRequest(params.row) }} className="circle-border-50 mx-2">
              <MdClose color="#EB5757" fontSize={24} />
            </div>
            {/* <div className="circle-border-50 mx-2">
              <MdMoreHoriz color="#521986" fontSize={24} />
            </div> */}
          </>
        ),
    },
  ];

  const [corporateDetails, setcorporateDetails] = useState({
    name: "",
    email: "",
    password: "",
  })

  const [corporateModalLoading, setcorporateModalLoading] = useState(false)
  const handlecorporateDetails = (e) => {
    setcorporateDetails({ ...corporateDetails, [e.target.name]: e.target.value })
  }
  const [showcorporate, setShowcorporate] = useState(false);
  const addNewcorporate = async (e) => {
    e.preventDefault()
    setcorporateModalLoading(true)
    const formdata = {
      password: corporateDetails?.password,
      email: corporateDetails?.email,
      role: "corporate",
      company: corporateDetails?.name,
    };
    try {
      // debugger;
      const res = await CorporateSignUp(formdata);
      if (res?.data?.status === 400) {
        alert("Email already Exist");
      }
      else if (res?.data?.status === 500) {
        alert("Account already Exist");

      } else {
        GetAllCorporates()
        alert("Account Created SuccessFully")

      }
    } catch (error) {
      alert(error.response.data.message)
    }
    setcorporateModalLoading(false);
  }
  return (
    <>
      <Modal show={showcorporate} onHide={() => setShowcorporate(false)}>
        <Modal.Header closeButton>
          <DarkHeading>Add Corporate</DarkHeading>
        </Modal.Header>
        <Modal.Body className="job-form mx-2">

          <Form className="px-3 pb-2" onSubmit={addNewcorporate}>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Name</Form.Label>
              <Form.Control
                className="form-input"
                type="text"
                name="name"
                required
                value={corporateDetails.name}
                onChange={handlecorporateDetails}
                placeholder="Deepa"
              />
            </Form.Group>

            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Email</Form.Label>
              <Form.Control
                name="email"
                required
                value={corporateDetails.email}
                onChange={handlecorporateDetails}
                className="form-input"
                type="email"
                placeholder="abc@gmail.com"
              />
            </Form.Group>
            <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Password</Form.Label>
              <Form.Control
                className="form-input"
                type="password"
                name="password"
                required
                value={corporateDetails.password}
                onChange={handlecorporateDetails}
                placeholder=""
              />
            </Form.Group>
            {/* <Form.Group className="mb-1" controlId="formBasicEmail">
              <Form.Label className="form-title my-2">Password</Form.Label>
              <Form.Control
                className="form-input"
                type="text"
                name="password"
                value={studentDetails.password}
                onChange={handlestudentdetails}
                placeholder=""
              />
            </Form.Group> */}
            <hr />
            {corporateModalLoading ? (
              <ButtonBlue className="w-100">
                <Spinner animation="border" size="sm" />
              </ButtonBlue>
            ) : (
              <ButtonBlue className="w-100" type="submit">
                Add New Corporate
              </ButtonBlue>
            )}
          </Form>
        </Modal.Body>
      </Modal>
      <Toaster />
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className="scroller">
          <div className="container">
            <BoxStyle className="my-1 py-3 px-4 mb-3">
              <div className="">
                <YellowHeading className="mb-0 fs-30">
                  All Your Corporate Profile
                </YellowHeading>
                <br />
                <Paragraph className="mb-0 mt-0">
                  Search and keep track of your Corporate Clients
                </Paragraph>
              </div>
              {/* <MultiStepProgress /> */}
            </BoxStyle>
            {/* <div className="d-flex justify-content-between">
              <div className="col-9">
                <Wrapper className="w-100 border-10 border-1">
                  <SearchInput placeholder="Search Your Clients" className="border-10" />
                  <GridSearchIcon
                    style={{ position: "absolute", right: "20px", top: "20px" }}
                  />
                </Wrapper>
              </div>
              <Select className="border-10 h-60 mx-2">
                <option value="1">All</option>
                <option value="2">Active</option>
              </Select>
            </div> */}
            <div className="d-flex justify-content-end my-2">
              <ButtonBlue onClick={() => setShowcorporate(true)} className="px-4">
                Add Corporate
              </ButtonBlue>
            </div>
            {!interview.favoriteCourses && (
              <BoxStyle className="my-2 py-1 px-2 mb-3">
                {/* <Paragraph className="fs-20 mx-2 my-2">
                  Clients &nbsp;
                  <MdContacts color={"#521986"} fontSize={24} />
                </Paragraph>
                <div className="d-flex">
                  <div className="relative">
                    <ButtonBlue
                      onClick={() =>
                        setInverView({
                          newapplication: false,
                          renewed: false,
                          expiry: true,
                        })
                      }
                      className={`px-4 mx-2 h-40 ${interview.expiry ? "active" : "disabled"
                        }`}
                    >
                      Expiring
                    </ButtonBlue>
                    {interview.expiry && <div className="expiry-counter">1</div>}
                  </div>
                  <ButtonBlue
                    className={`px-4 mx-2 h-40 ${interview.renewed ? "active" : "disabled"
                      }`}
                    onClick={() =>
                      setInverView({
                        newapplication: false,
                        expiry: false,
                        renewed: true,
                      })
                    }
                  >
                    Renewed
                  </ButtonBlue>
                  <ButtonBlue
                    className={`px-4 mx-2 h-40 ${interview.newapplication ? "active" : "disabled"
                      }`}
                    onClick={() =>
                      setInverView({
                        expiry: false,
                        renewed: false,
                        newapplication: true,
                      })
                    }
                  >
                    New Application
                  </ButtonBlue>
                </div> */}
                <Table
                  columns={columns}
                  data={corporates.map((item) => {
                    return {
                      ...item,
                      id: item._id,
                      key: item._id,
                    };
                  })}
                  limit={10}
                />
              </BoxStyle>
            )}
          </div>
        </DashboardBoxStyle>)}
    </>
  );
};
