import { CircleStep, Paragraph } from "components/common/CommonStyles";
import React from "react";

export const Steps = ({ counter }) => {
  return (
    <div>
      {[...Array(counter)].map((e, i) => (
        <div className="container my-2">
          <div className="col-12">
            <div className="d-flex">
              <div className="col-2">
                <CircleStep
                  className={`steps ${
                    [...Array(counter)].length === i + 1 ? "last" : "other"
                  }`}
                  style={{ overflow: i === 0 ? "hidden" : "visible" }}
                >
                  {i + 1}
                </CircleStep>
              </div>
              <div className="col-10">
                <Paragraph className="mx-3" style={{ lineHeight: "1.5rem" }}>
                  Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                </Paragraph>
              </div>
            </div>
          </div>
        </div>
      ))}
    </div>
  );
};
