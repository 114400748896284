import React from "react";
import progress from "assets/image/progress.png";
import line from "assets/image/line.png";
import "./ProgressTrackGoal.css";
import { Paragraph } from "components/common/CommonStyles";

export const ProgressTrackGoal = ({ data }) => {
  return (
    <>
      {data.map((e, i) => (
        <div className="progress-track-goal">
          <div className="progress-track-goal-image">
            <img
              src={progress}
              alt="progress"
              className={
                (i !== data?.length - 1
                  ? "image-main margin-10-bottom"
                  : "image-main") +
                " " +
                (i !== 0 ? "opacity-50" : "")
              }
            />
            <div>
              <img
                src={line}
                className={i === data?.length - 1 ? "d-none" : ""}
              />
            </div>
          </div>
          <div className="progress-track-goal-image-1">
            <Paragraph className="fw-bold fs-14 text-left mb-0">
              {e.title}
            </Paragraph>
            <Paragraph
              className="fw-bold fs-12"
              style={{
                opacity: i === 0 ? 1 : 0.5,
                fontWeight: i === 0 ? "bold" : "400",
              }}
            >
              {e.description}
            </Paragraph>
          </div>
          <div className="progress-track-goal-text"></div>
        </div>
      ))}
    </>
  );
};
