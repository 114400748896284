import React, { useState } from "react";
import { useNavigate } from "react-router";
import {
   BoxStyle,
   ButtonBlue,
   DarkHeading,
   Paragraph,
   PinkButton,
   SpanYellow,
} from "components/common/CommonStyles";
import job from "assets/image/job.png";
import expiry from "assets/image/expiry.png";
import time from "assets/image/time.png";
import stock from "assets/image/stock.png";
import heart from "assets/image/heart.png";
import tick from "assets/image/tick.png";
const DraftInterviewCard = ({ item }) => {
   const [readmore, setreadmore] = useState(false);
   const navigate = useNavigate();

   return (
      <BoxStyle className="pt-3 my-3 padding-5 linear" key={item?._id}>
         <div className="container interview-card">
            <div className="row">
               <div className="col-12">
                  <div className="col-12">
                     <div className="mb-1">
                        <div className="d-flex">
                           <div className="interview-logo">
                              <img
                                 className="rounded-circle"
                                 height={70}
                                 width={70}
                                 src={item?.icon}
                                 alt={item?.jobtitle}
                              />
                           </div>
                           <div className="mx-2">
                              <div className="">
                                 <DarkHeading className="px-2">{item?.jobtitle}</DarkHeading>
                                 <div className="mb-3 d-none-525">
                                    <div className="d-flex">
                                       <div className="px-1 d-flex align-items-center">
                                          <img
                                             className="rounded-circle mx-1"
                                             height={17}
                                             width={17}
                                             src={tick}
                                             alt={tick}
                                          />
                                          <span
                                             className="fs-14"
                                             style={{ fontSize: "14px" }}
                                          >
                                             {item?.jobtype}
                                          </span>
                                       </div>
                                       <div className="px-1 d-flex align-items-center">
                                          <img
                                             className="rounded-circle mx-1"
                                             height={17}
                                             width={17}
                                             src={time}
                                             alt={time}
                                          />
                                          <span
                                             className="fs-14"
                                             style={{ fontSize: "14px" }}
                                          >
                                             {item?.joblocation}
                                          </span>
                                       </div>
                                       <div className="px-1 d-flex align-items-center">
                                          <img
                                             className="rounded-circle mx-1"
                                             height={17}
                                             width={17}
                                             src={job}
                                             alt={job}
                                          />
                                          <span
                                             className="fs-14"
                                             style={{ fontSize: "14px" }}
                                          >
                                             {item?.experience}&nbsp;Years
                                          </span>
                                       </div>
                                       <div className="px-1 d-flex align-items-center">
                                          <img
                                             className="rounded-circle mx-1"
                                             height={17}
                                             width={17}
                                             src={stock}
                                             alt={stock}
                                          />
                                          <span
                                             className="fs-14"
                                             style={{ fontSize: "14px" }}
                                          >
                                             {item?.jobcity}
                                          </span>
                                       </div>

                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>

                     <div className="my-2">
                    <div className="mb-3 d-flex-525">
                      <div className="w-100">
                        <div className="d-flex justify-content-centerw-100">

                          <div className="px-1 d-flex align-items-center col-6">
                            <img
                              className="rounded-circle mx-1"
                              height={17}
                              width={17}
                              src={tick}
                              alt={tick}
                            />
                            <span
                              className="fs-14"
                              style={{ fontSize: "14px" }}
                            >
                              {item?.jobtype}
                            </span>
                          </div>
                          <div className="px-1 d-flex align-items-center col-6">
                            <img
                              className="rounded-circle mx-1"
                              height={17}
                              width={17}
                              src={time}
                              alt={time}
                            />
                            <span
                              className="fs-14"
                              style={{ fontSize: "14px" }}
                            >
                              {item?.joblocation}
                            </span>
                          </div>
                        </div>
                        <div className="d-flex justify-content-center">

                          <div className="px-1 d-flex align-items-center col-6">
                            <img
                              className="rounded-circle mx-1"
                              height={17}
                              width={17}
                              src={job}
                              alt={job}
                            />
                            <span
                              className="fs-14"
                              style={{ fontSize: "14px" }}
                            >
                              {item?.experience}&nbsp;Years
                            </span>
                          </div>
                          <div className="px-1 d-flex align-items-center col-6">
                            <img
                              className="rounded-circle mx-1"
                              height={17}
                              width={17}
                              src={stock}
                              alt={stock}
                            />
                            <span
                              className="fs-14"
                              style={{ fontSize: "14px" }}
                            >
                              {item?.jobcity}
                            </span>
                          </div>
                        </div>

                      </div>
                    </div>
                  </div>
                     <div className="col-12 interview-des">
                        {readmore ? (
                           <Paragraph className="mb-0">
                              <div dangerouslySetInnerHTML={{ __html: item?.jobdescription }}></div>
                              <SpanYellow
                                 className="c-pointer"
                                 onClick={() => setreadmore(false)}
                              >
                                 READ LESS
                              </SpanYellow>
                           </Paragraph>
                        ) : (
                           <Paragraph className="mb-0">

                              {
                                 item?.jobdescription?.length > 150 ? (<>
                                    <div dangerouslySetInnerHTML={{ __html: item?.jobdescription.slice(0, 151) + "..." }}></div>
                                    <SpanYellow
                                       className="c-pointer"
                                       onClick={() => setreadmore(true)}
                                    >
                                       READ MORE
                                    </SpanYellow>
                                 </>) : (<Paragraph className="mb-0">
                                    <div dangerouslySetInnerHTML={{ __html: item?.jobdescription }}></div>
                                 </Paragraph>)
                              }
                           </Paragraph>
                        )}
                        {/* <Paragraph>
                              {item.description.slice(1,101)}...
                              <SpanYellow onClick={()=>setreadmore(true)}>READ MORE</SpanYellow>
                            </Paragraph> */}
                     </div>
                     <div className="d-flex justify-content-between align-items-center mb-2">
                        <div>
                           {item?.skills?.map((b, ind) => (
                              <PinkButton className="mx-1" key={ind}>
                                 {b.skills}
                              </PinkButton>
                           ))}
                        </div>
                        <div>
                           <ButtonBlue
                              className="px-3"
                              onClick={() => navigate(`${item?._id}`)}
                           >
                              Start Editing
                           </ButtonBlue>
                        </div>
                     </div>
                  </div>
               </div>
            </div>
         </div>
      </BoxStyle>
   );
};

export default DraftInterviewCard;
