import { BackButton } from "components/common/BackButton";
import {
  BoxStyle,
  BoxStyleBlueLight,
  BoxYellowOutline,
  ButtonBlue,
  ButtonYellowOutline,
  DarkHeading,
  DashboardBoxStyle,
  Input,
  Paragraph,
  SearchInput,
  Select,
  TextArea,
  TextEditorBox,
  Wrapper,
  YellowHeading,
} from "components/common/CommonStyles";
import React, { useEffect, useRef, useState } from "react";
import { Accordion, Form, Modal, Spinner } from "react-bootstrap";
import axios from "axios";
import { GridSearchIcon } from "@mui/x-data-grid";
import { AiFillCloseCircle, AiTwotoneEdit } from "react-icons/ai";
import {
  CreateInterview,
  CreateQuestions,
  CreateSkills,
  GetallQuestionsCategory,
  GetallSkillsCategory,
  GetCollegeTeams,
  GetSingleInterview,
  UpdateInterview,
  UpdateQuestions,
} from "../../../axios/apis";
import { cities } from "mock-api/cities";
import { jobDomain } from "mock-api/jobDomail";
import "./interview.css";
import InterviewLogo from "../../../assets/image/interview-logo.png";
import "react-quill/dist/quill.snow.css";
import { BsArrowUp } from "react-icons/bs";
import { toast, Toaster } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import ReactQuill from "react-quill";
import { createInterViewLeftData } from "container/Interviewer/CreateInterview/contants/data";
import { useNavigate, useParams } from "react-router";
import CreatableSelect from "react-select/creatable";
import { subDomain } from "mock-api/subDomain";

const DraftInterviewForm = ({ type }) => {
  const [activeIndex, setIndex] = useState(0);
  const [icon, seticon] = useState("");
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const [interviewdata, setInterviewData] = useState({});
  const params = useParams();
  const [jobdetails, setJobDetails] = useState({
    company: "",
    companyDescription: "",
    jobtitle: "",
    jobdescription: "",
    domain: "",
    jobcategory: "",
    jobcity: "",
    jobarea: "",
    jobtype: "",
    joblocation: "",
    experience: "",
    expirydate: "",
    status: "",
    skills: [],
    interviewquestions: [],
    documents: [],
    draft: "",
  });
  const [jobdescription, setJobDescription] = useState("");
  // console.log(jobdetails);
  const GetInterviewDetails = async () => {
    setLoading(true);
    try {
      const response = await GetSingleInterview({ id: params.id });
      //  console.log(response);
      setInterviewData(response?.data?.interview);
      setJobDetails(response?.data?.interview);
      setDocumentList(response?.data?.interview?.documents);
      let skills = response?.data?.interview?.skills?.map((data) => data?._id);
      setinterviewSkills(skills);
      setinterviewteams(response?.data?.interview?.teams);
      setInterviewPracticeQue(response?.data?.interview?.preparationQue);
      setJobDescription(response?.data?.interview?.jobdescription);
      seticon(response?.data?.interview?.icon);
      let interviewquestions2 =
        response?.data?.interview?.interviewquestions?.map((data) => {
          return {
            id: data?._id,
            duration: data?.duration,
            keyword: data?.keyword,
            question: data?.question,
            suggestedAnswer: data?.suggestedAnswer,
            hide: false,
            videoQuestion: data?.videoQuestion,
          };
        });
      // let expdate = response?.data?.interview?.expirydate.slice(0, 10);
      // setJobDetails({ ...jobdetails, ["expirydate"]: expdate })
      setInterviewQue(interviewquestions2);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };
  //  console.log(jobdetails.expirydate)
  useEffect(() => {
    GetInterviewDetails();
  }, [params.id]);
  // upload image aws
  const UploadInput = useRef(null);
  const [percentage, setPercentage] = useState(0);
  const [loadingiconupload, setLoadingIconupload] = useState(null);
  function fileUploadHandler(e) {
    uploadLogo(e.target.files[0]);
  }
  const uploadLogo = async (e) => {
    if (e) {
      setLoadingIconupload(true);
      const config = {
        onUploadProgress: (progress) => {
          const percentange = Math.round(
            (progress.loaded / progress.total) * 100
          );
          setPercentage(percentange);
          if (percentange === 100) {
            setLoadingIconupload(false);
          }
        },
      };
      const formData = new FormData();
      formData.append("file", e);
      const rootUrl =
        process.env.NODE_ENV === "production"
          ? "https://uploader.viosa.in/api"
          : "https://uploader.viosa.in/api";
      setLoadingIconupload(true);
      axios
        .post(`${rootUrl}/v2/upload/other`, formData, config)
        .then((data) => {
          // console.log(data.data.link);
          seticon(data.data.link);
          toast.success("Image Uploaded Successfully", ToastStyles);
          setLoadingIconupload(false);
        })
        .catch((error) => {
          toast.error("Try again", ToastStyles);
          console.error(error);
          setLoadingIconupload(false);
        });
    }
  };
  // Interview Form  basic Details
  // console.log(jobdetails);
  const handleChange = (e) => {
    setJobDetails({
      ...jobdetails,
      [e.target.name]: e.target.value,
    });
  };

  // Interview Skill

  const [showCreateSkillModal, setShowCreateSkillModal] = useState(false);

  const createSkillModalClose = () => setShowCreateSkillModal(false);
  const createSkillModalOpen = () => setShowCreateSkillModal(true);

  const [newSkilltitle, setNewSkilltitle] = useState("");
  const [newskills, setnewskill] = useState([]);
  const removenewskills = (indexToRemove) => {
    setnewskill([...newskills.filter((_, index) => index !== indexToRemove)]);
  };
  const addnewskills = (event) => {
    if (event.target.value !== "") {
      setnewskill([...newskills, event.target.value]);
      event.target.value = "";
    }
  };
  const [interviewskills, setinterviewSkills] = useState([]);
  const [interviewteams, setinterviewteams] = useState([]);
  // console.log(interviewskills);
  const handleinterviewSkillChange = (data) => {
    // console.log(data);
    if (data === "") {
      return;
    } else if (interviewskills.length === 20) {
      toast.error("20 Skills Already added", ToastStyles);
    } else if (interviewskills.length !== 0 && interviewskills.includes(data)) {
      // setinterviewSkills((interviewskills) =>
      //   interviewskills.filter((data2, index) => data2 !== data)
      // );
      toast.error("Skill Already Added", ToastStyles);
    } else {
      setinterviewSkills((interviewskills) => [...interviewskills, data]);
    }
  };
  const handleinterviewTeamChange = (data) => {
    // console.log(data);
    if (data === "") {
      return;
    } else if (interviewteams.length === 20) {
      toast.error("20 Teams Already added", ToastStyles);
    } else if (interviewteams.length !== 0 && interviewteams.includes(data)) {
      toast.error("Team Already Added", ToastStyles);
    } else {
      setinterviewteams((interviewteams) => [...interviewteams, data]);
    }
  };

  const removethisskill = (indexToRemove) => {
    setinterviewSkills([
      ...interviewskills.filter((_, index) => index !== indexToRemove),
    ]);
  };
  const removethisteam = (indexToRemove) => {
    setinterviewteams([
      ...interviewteams.filter((_, index) => index !== indexToRemove),
    ]);
  };
  //  console.log(interviewteams)

  const [skillmodalloading, setskillmodalloading] = useState(false);
  const createNewSkills = async () => {
    if (newSkilltitle === "") {
      toast.error("Title can't be empty", ToastStyles);
    } else if (newskills.length === 0) {
      toast.error("Add atleast one skill", ToastStyles);
    } else {
      setskillmodalloading(true);
      // console.log(newSkilltitle, newskills);
      const formdata = {
        skilltype: newSkilltitle,
        skill: newskills,
      };
      try {
        const response = await CreateSkills(formdata);
        // console.log(response);
        if (response.status !== 200) {
          toast.error("Try Again 😣", ToastStyles);
        } else {
          getallSkillswithType();
          toast.success("Skills set created", ToastStyles);
          let newinterviewskill = interviewskills;
          response.data.data.map((data) => {
            newinterviewskill.push(data?._id);
          });
          createSkillModalClose();
          setinterviewSkills(newinterviewskill);
        }
        setskillmodalloading(false);
      } catch (error) {
        console.log(error);
        toast.error("Try Again 😣", ToastStyles);
        setskillmodalloading(false);
      }
    }
  };
  const [allskills, setallskills] = useState([]);
  const [skillsdata, setskillsdata] = useState([]);
  const [teamsdata, setteamsdata] = useState([]);
  const [skillshowlist, setskillshowlist] = useState(3);
  // console.log(skillsdata);
  const getallSkillswithType = async () => {
    try {
      const data = await GetallSkillsCategory();
      // console.log(data?.data);
      const response2 = await GetCollegeTeams();
      setteamsdata(response2.data.data);
      setallskills(data?.data?.data);
      const skillls = [];
      data?.data?.data.map((data) =>
        data.skills.map((data2) => skillls.push(data2))
      );
      setskillsdata(skillls);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getallSkillswithType();
  }, []);

  // Interview Questions
  const [updatequestionId, setUpdateQuestionId] = useState("");
  const [showCreateNewQueModal, setshowCreateNewQueModal] = useState(false);
  const [interviewQue, setInterviewQue] = useState([
    {
      questiontype: "abc",
      question: "",
      keyword: [],
      suggestedAnswer: "",
      videoQuestion: "",

      duration: "",
      hide: false,
    },
  ]);
  //  console.log(interviewQue)

  const [interviewnewQue, setInterviewnewQue] = useState({
    questiontype: "",
    question: "",
    keyword: [],
    suggestedAnswer: "",
    videoQuestion: "",

    modeofquestion: "",
    modeofanswer: "",
    duration: "",
    hide: "false",
  });
  const handlenewquestionchange = (e) => {
    setInterviewnewQue({ ...interviewnewQue, [e.target.name]: e.target.value });
  };

  const handleInterviewQueChange = async (data, e) => {
    e.preventDefault();
    if (updatequestionId === "") {
      if (data._id && data.__v && data.createdAt && data.updatedAt) {
        delete data._id;
        delete data.__v;
        delete data.createdAt;
        delete data.updatedAt;
      }
      if (interviewQue.length !== 0 && interviewQue.includes(data)) {
        toast.error("Question Already Added", ToastStyles);
      } else {
        setInterviewQue((interviewQue) => [...interviewQue, data]);
        setshowCreateNewQueModal(false);
        setInterviewnewQue({
          questiontype: "",
          question: "",
          keyword: [],
          suggestedAnswer: "",
          videoQuestion: "",

          modeofquestion: "",
          modeofanswer: "",
          duration: "",
          hide: false,
        });
        toast.success("Question Added Successfully", ToastStyles);
      }
    } else {
      let questions;
      let body = {
        id: updatequestionId,
        questions: [data],
      };
      try {
        setquestionmodalloading(true);
        const response = await UpdateQuestions(body);
        toast.success("Question Added SuccessFully", ToastStyles);
        setInterviewnewQue({
          questiontype: "",
          question: "",
          keyword: [],
          suggestedAnswer: "",
          videoQuestion: "",

          modeofquestion: "",
          modeofanswer: "",
          duration: "",
          hide: false,
        });
        getallQuestionswithType();
        setquestionmodalloading(false);
        setshowCreateNewQueModal(false);
        setUpdateQuestionId("");
      } catch (error) {
        toast.error("Try Again", ToastStyles);
        console.log(error);
        setquestionmodalloading(true);
      }
    }
  };

  const removeInterviewQueField = (i) => {
    let newFormValues = [...interviewQue];
    newFormValues.splice(i, 1);
    setInterviewQue(newFormValues);
  };

  // console.log(interviewQue);

  const [interviePracticeQue, setInterviewPracticeQue] = useState("");

  // custom interview questions add
  const [showCreateQueModal, setShowCreateQueModal] = useState(false);

  const CreateQueModalClose = () => setShowCreateQueModal(false);
  const CreateQueModalOpen = () => setShowCreateQueModal(true);

  const [newquetitle, setNewquetitle] = useState();
  const [newquelist, setnewQuelist] = useState([
    {
      questiontype: newquetitle,
      question: "",
      keyword: "",
      duration: "",
      modeofquestion: "",
      modeofanswer: "",
      hide: false,
    },
  ]);
  const handleInterviewQueDataChange = (i, e) => {
    if (e.target.name === "videoQuestion") {
      let newFormValues = [...interviewQue];
      //  console.log(e)
      newFormValues[i][e.target.name] = e.target?.files[0];
      setInterviewQue(newFormValues);
    } else {
      let newFormValues = [...interviewQue];
      newFormValues[i][e.target.name] = e.target.value;
      setInterviewQue(newFormValues);
    }
  };
  const handleCustomQueChange = (i, e) => {
    let newFormValues = [...newquelist];
    newFormValues[i][e.target.name] = e.target.value;
    setnewQuelist(newFormValues);
  };

  const addCustomQueField = (e) => {
    e.preventDefault();
    setnewQuelist([
      ...newquelist,
      {
        questiontype: newquetitle,
        question: "",
        keyword: "",
        duration: "",
        modeofquestion: "",
        modeofanswer: "",
        hide: false,
      },
    ]);
  };
  const removeCustomQueField = (i) => {
    let newFormValues = [...newquelist];
    newFormValues.splice(i, 1);
    setnewQuelist(newFormValues);
  };

  const [questionmodalloading, setquestionmodalloading] = useState(false);
  const createNewInterviewQue = async (e) => {
    e.preventDefault();
    if (newquetitle === "") {
      toast.error("Title can't be empty", ToastStyles);
    } else if (newquelist.length === 0) {
      toast.error("Add atleast one Question", ToastStyles);
    } else {
      setquestionmodalloading(true);
      //  console.log(newquetitle, newquelist);
      const formdata = {
        questiontype: newquetitle,
        allquestions: newquelist,
      };
      try {
        const response = await CreateQuestions(newquelist);
        // console.log(response);
        getallQuestionswithType();
        toast.success("Questions set created", ToastStyles);

        CreateQueModalClose();
        setquestionmodalloading(false);
      } catch (error) {
        console.log(error);
        toast.error("Try Again 😣", ToastStyles);
        setquestionmodalloading(false);
      }
    }
  };
  const [allquestions, setallquestions] = useState([]);
  const [questionshowlist, setquestionshowlist] = useState(3);
  const getallQuestionswithType = async () => {
    try {
      const data = await GetallQuestionsCategory();
      setallquestions(data?.data?.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    getallQuestionswithType();
  }, []);

  // Ask for Documents
  const [documentlist, setDocumentList] = useState([]);
  const handleDocumentsListChange = (e) => {
    if (documentlist.length !== 0 && documentlist.includes(e.target.value)) {
      setDocumentList((documentlist) =>
        documentlist.filter((data, index) => data !== e.target.value)
      );
    } else {
      setDocumentList((documentlist) => [...documentlist, e.target.value]);
    }
  };
  const removenewdoc = (indexToRemove) => {
    setDocumentList([
      ...documentlist.filter((_, index) => index !== indexToRemove),
    ]);
  };
  const addnewdoc = (event) => {
    if (event.target.value !== "") {
      setDocumentList([...documentlist, event.target.value]);
      event.target.value = "";
    }
  };

  const [createInterviewLoading, setCreateInterviewLoading] = useState(false);
  const [createInterviewDraftLoading, setCreateInterviewDraftLoading] =
    useState(false);
  const [uploadVideoLoading, setUploadLoading] = useState(false);
  const uploadVideo = async () => {
    try {
      let count = 0;
      let count2 = 0;
      interviewQue?.forEach(async (item, i) => {
        if (
          item?.videoQuestion !== "" &&
          typeof item.videoQuestion === "object"
        ) {
          setUploadLoading(true);
          count2++;
          const config = {
            onUploadProgress: (progress) => {
              const percentange = Math.round(
                (progress.loaded / progress.total) * 100
              );
              if (percentange === 100) {
              }
            },
          };
          const formData = new FormData();
          formData.append("file", item.videoQuestion);
          const rootUrl = "https://uploader.viosa.in/api";
          const { data } = await axios.post(
            `${rootUrl}/v2/upload/other`,
            formData,
            config
          );
          count++;
          const newFormValues = [...interviewQue];
          newFormValues[i]["videoQuestion"] = data.link;
          setInterviewQue(newFormValues);
          toast.success("Updated", ToastStyles);
        }
        if (count === count2) {
          setUploadLoading(false);
        }
      });
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setUploadLoading(false);
    }
  };

  const UpdateNewInterview = async (e) => {
    e.preventDefault();
    const {
      company,
      companyDescription,
      jobtitle,
      domain,
      jobcategory,
      jobcity,
      jobarea,
      jobtype,
      joblocation,
      experience,
      expirydate,
    } = jobdetails;
    if (company === "") {
      toast.error("Please Add Company Name", ToastStyles);
    } else if (jobtitle === "") {
      toast.error("Please Add Job Title", ToastStyles);
    } else if (joblocation === "") {
      toast.error("Please Add Job Location", ToastStyles);
    } else if (jobtype === "") {
      toast.error("Please Add Job Type", ToastStyles);
    } else if (experience === "") {
      toast.error("Please Add Job Experience", ToastStyles);
    } else {
      try {
        setCreateInterviewLoading(true);
        // console.log(interviewQue)
        debugger;
        let updateQuestions = interviewQue.filter((data) =>
          data.hasOwnProperty("id")
        );
        var newQuestions = interviewQue.filter(
          (obj) => updateQuestions.indexOf(obj) == -1
        );
        let data = {
          id: params.id,
          updateBody: {
            jobtitle,
            jobdescription,
            joblocation,
            jobtype,
            jobcategory,
            expirydate,
            jobcity,
            jobarea,
            icon,
            companyDescription,
            company,
            domain: domain,
            experience,
            updateQuestions,
            newQuestions,
            documents: documentlist,
            preparationQue: interviePracticeQue,
            skills: interviewskills,
            draft: false,
            status: "Scheduled",
            teams: interviewteams,
            creator_type: "college",
          },
        };
        // console.log(data)

        const res = await UpdateInterview(data);
        // console.log(res);
        setCreateInterviewLoading(false);
        toast.success("Interview Updated SuccessFully", ToastStyles);
        navigate("/college/my-interviews");
      } catch (error) {
        toast.error("Try Again", ToastStyles);
        // console.log(error);
        setCreateInterviewLoading(false);
      }
    }
    // console.log(e);
    // console.log(jobdetails);
    // console.log(interviewskills);
    // console.log(interviewQue);
    // console.log(documentlist);
  };
  const UpdateInterviewDraft = async (e) => {
    e.preventDefault();
    const {
      company,
      companyDescription,
      jobtitle,
      domain,
      jobcategory,
      jobcity,
      jobarea,
      jobtype,
      joblocation,
      experience,
      expirydate,
    } = jobdetails;
    if (company === "") {
      toast.error("Please Add Company Name", ToastStyles);
    } else if (jobtitle === "") {
      toast.error("Please Add Job Title", ToastStyles);
    } else if (joblocation === "") {
      toast.error("Please Add Job Location", ToastStyles);
    } else if (jobtype === "") {
      toast.error("Please Add Job Type", ToastStyles);
    } else if (experience === "") {
      toast.error("Please Add Job Experience", ToastStyles);
    } else {
      try {
        setCreateInterviewDraftLoading(true);
        let updateQuestions = interviewQue.filter((data) =>
          data.hasOwnProperty("id")
        );
        var newQuestions = interviewQue.filter(
          (obj) => updateQuestions.indexOf(obj) == -1
        );
        let data = {
          id: params.id,
          updateBody: {
            jobtitle,
            jobdescription,
            joblocation,
            jobtype,
            jobcategory,
            expirydate,
            jobcity,
            jobarea,
            icon,
            companyDescription,
            company,
            domain: domain,
            experience,
            updateQuestions,
            newQuestions,
            documents: documentlist,
            preparationQue: interviePracticeQue,
            skills: interviewskills,
            draft: true,
            status: "Draft",
            creator_type: "corporate",
          },
        };

        const res = await UpdateInterview(data);
        // console.log(res);
        toast.success("Draft Interview Updated SuccessFully", ToastStyles);
        navigate(-1);
        setCreateInterviewDraftLoading(false);
      } catch (error) {
        toast.error("Try Again", ToastStyles);
        console.log(error);
        setCreateInterviewDraftLoading(false);
      }
    }
    // console.log(e);
    // console.log(jobdetails);
    // console.log(interviewskills);
    // console.log(interviewQue);
    // console.log(documentlist);
  };

  const jobTypes = [
    {
      value: "Full Time",
      label: "Full Time",
    },
    {
      value: "Part Time",
      label: "Part Time",
    },
  ];
  const jobLocation = [
    {
      value: "On-site",
      label: "On-site",
    },
    {
      value: "Remote",
      label: "Remote",
    },
    {
      value: "Hybrid",
      label: "Hybrid",
    },
  ];

  const experience = [
    {
      value: "1",
      label: "1",
    },
    {
      value: "2",
      label: "2",
    },
    {
      value: "3",
      label: "3",
    },
  ];

  return (
    <>
      <Toaster />
      {/* add custom skill modal */}
      <Modal show={showCreateSkillModal} onHide={createSkillModalClose}>
        <Modal.Header closeButton>
          <DarkHeading>Create Custom Skills</DarkHeading>
        </Modal.Header>
        <Modal.Body className="job-form mx-2">
          <label>Skills Title</label>
          <TextArea
            placeholder="Title"
            className="h-65 secondary border-10 mb-2"
            name="title"
            required
            value={newSkilltitle}
            onChange={(e) => {
              setNewSkilltitle(e.target.value);
            }}
          ></TextArea>
          <label>Skills</label>

          <div className="tags-input">
            <ul id="tags">
              {newskills?.map((tag, index) => (
                <li key={index} className="tag">
                  <span className="tag-title">{tag}</span>
                  <span
                    className="tag-close-icon"
                    onClick={() => removenewskills(index)}
                  >
                    x
                  </span>
                </li>
              ))}
            </ul>
            <input
              type="text"
              onKeyUp={(event) =>
                event.key === "Enter" ? addnewskills(event) : null
              }
              placeholder="Press enter to add Skill"
            />
          </div>
        </Modal.Body>
        <Modal.Footer>
          {skillmodalloading ? (
            <ButtonBlue className="w-100">
              <Spinner animation="border" size="sm" />
            </ButtonBlue>
          ) : (
            <ButtonBlue className="w-100" onClick={createNewSkills}>
              Add New Skills
            </ButtonBlue>
          )}
        </Modal.Footer>
      </Modal>

      {/* add custom questions modal */}

      <Modal show={showCreateQueModal} onHide={CreateQueModalClose}>
        <Modal.Header closeButton>
          <DarkHeading>Create Custom Questions</DarkHeading>
        </Modal.Header>
        <Form onSubmit={createNewInterviewQue}>
          <Modal.Body className="job-form h-70 mx-2">
            <label>Questions Title</label>
            <TextArea
              placeholder="Title"
              className="h-65 secondary border-10 mb-2"
              name="title"
              required
              value={newquetitle}
              onChange={(e) => {
                setNewquetitle(e.target.value);
                let data = [...newquelist];
                data[0].questiontype = e.target.value;
                setnewQuelist(data);
              }}
            ></TextArea>

            <div className="d-flex justify-content-between align-items-center">
              <DarkHeading className="fs-20">Interview Questions</DarkHeading>
              <ButtonBlue onClick={addCustomQueField} className="px-2">
                + Add More Question
              </ButtonBlue>
            </div>

            {newquelist.map((e, i) => (
              <div key={i}>
                <div className="d-flex justify-content-between my-3">
                  <h5>Q{i + 1}. Enter Your Question</h5>
                  <div
                    onClick={() => removeCustomQueField(i)}
                    className="cancel-icon c-pointer"
                  >
                    <AiFillCloseCircle color="red " size={24} />
                  </div>
                </div>
                <Input
                  placeholder="Enter Your Question"
                  className="h-65 mb-2"
                  name="question"
                  required
                  value={e.question}
                  onChange={(e) => handleCustomQueChange(i, e)}
                />
                <Input
                  placeholder="Keywords (Seprated by Comma(','))"
                  className="h-65 mb-2"
                  name="keyword"
                  required
                  value={e.keyword}
                  onChange={(e) => handleCustomQueChange(i, e)}
                />
                <Input
                  placeholder="Time Duration (seconds)"
                  className="h-65 mb-2"
                  name="duration"
                  type="number"
                  required
                  value={e.duration}
                  onChange={(e) => handleCustomQueChange(i, e)}
                />
                <Input
                  placeholder="Suggested Answer"
                  className="h-65 mb-2"
                  name="suggestedAnswer"
                  required
                  value={e.suggestedAnswer}
                  onChange={(e) => handleCustomQueChange(i, e)}
                />
              </div>
            ))}
          </Modal.Body>
          <Modal.Footer>
            {questionmodalloading ? (
              <ButtonBlue className="w-100">
                <Spinner animation="border" size="sm" />
              </ButtonBlue>
            ) : (
              <ButtonBlue className="w-100" type="submit">
                Add New Questions
              </ButtonBlue>
            )}
          </Modal.Footer>
        </Form>
      </Modal>
      <Modal
        show={showCreateNewQueModal}
        onHide={() => {
          setUpdateQuestionId("");
          setInterviewnewQue({
            questiontype: "",
            question: "",
            keyword: [],
            suggestedAnswer: "",

            modeofquestion: "",
            modeofanswer: "",
            duration: "",
          });
          setshowCreateNewQueModal(false);
        }}
      >
        <Modal.Header closeButton>
          <DarkHeading>Add New Question</DarkHeading>
        </Modal.Header>
        <Form onSubmit={(e) => handleInterviewQueChange(interviewnewQue, e)}>
          <Modal.Body className="job-form h-70 mx-2">
            <label>Questions Title</label>
            <TextArea
              placeholder="Title"
              className="h-65 secondary border-10 mb-2"
              name="questiontype"
              required
              value={interviewnewQue.questiontype}
              onChange={handlenewquestionchange}
            ></TextArea>
            <div>
              <h5>Enter Your Question</h5>
              <Input
                placeholder="Enter Your Question"
                className="h-65 mb-2"
                name="question"
                required
                value={interviewnewQue.question}
                onChange={handlenewquestionchange}
              />
              <Input
                placeholder="Keywords (Seprated by Comma(','))"
                className="h-65 mb-2"
                name="keyword"
                required
                value={interviewnewQue.keyword}
                onChange={handlenewquestionchange}
              />
              <Input
                placeholder="Time Duration (seconds)"
                className="h-65 mb-2"
                name="duration"
                required
                type="number"
                value={interviewnewQue.duration}
                onChange={handlenewquestionchange}
              />
              <Input
                placeholder="Suggested Answer"
                className="h-65 mb-2"
                name="suggestedAnswer"
                required
                value={interviewnewQue.suggestedAnswer}
                onChange={handlenewquestionchange}
              />
            </div>
          </Modal.Body>
          <Modal.Footer>
            {questionmodalloading ? (
              <ButtonBlue className="w-100">
                <Spinner animation="border" size="sm" />
              </ButtonBlue>
            ) : (
              <ButtonBlue className="w-100" type="submit">
                Add This Questions
              </ButtonBlue>
            )}
          </Modal.Footer>
        </Form>
      </Modal>

      <DashboardBoxStyle className="scroller">
        <div className="container my-3">
          <div className="row flex-wrap">
            <div
              className="col-12 col-md-4 position-unset-768 my-2"
              style={{
                position: "sticky",
                top: "0",
                bottom: "0",
                height: "100%",
              }}
            >
              <div className="d-flex align-items-center mx-0 px-0">
                <BackButton />
                <YellowHeading className="fs-20 my-2 mx-3">
                  Job Interview
                </YellowHeading>
              </div>
              <div className="my-3">
                {createInterViewLeftData.map((item, index) => (
                  <BoxYellowOutline
                    key={index}
                    onClick={() => setIndex(index)}
                    className={`p-1 my-2 cursor-pointer  ${
                      index === activeIndex ? "active-button" : ""
                    }`}
                  >
                    <div className="d-flex align-items-center">
                      <div className="theme-primary">{item.icon}</div>
                      <DarkHeading className="fs-14 fw-500 mx-2">
                        {item.name}
                      </DarkHeading>
                    </div>
                  </BoxYellowOutline>
                ))}
              </div>
              {activeIndex === 1 && (
                <div className="my-3">
                  <BoxStyle className="pt-3 p-2 border-10  px-3">
                    <div className="col-12 mb-3">
                      <div className="row mb-1 ">
                        <div className="col-10">
                          <Paragraph className="mb-1 fw-600 fs-14 mx-1">
                            Skills Tab
                          </Paragraph>
                        </div>

                        <div className="category-scroller">
                          {allskills.slice(0, skillshowlist).map((data, i) => (
                            <div className="mt-2 " key={i}>
                              <Accordion>
                                <Accordion.Item eventKey="0">
                                  <Accordion.Header>
                                    <div className="me-2 d-flex flex-column align-items-center">
                                      <div>
                                        <YellowHeading className="fs-14 fw-600 secondary">
                                          {data?.skilltype
                                            .charAt(0)
                                            .toUpperCase() +
                                            data?.skilltype.slice(1)}
                                        </YellowHeading>
                                        <YellowHeading className="fs-14 mb-0 fw-600">
                                          {data?.skills.length} Skills
                                        </YellowHeading>
                                      </div>
                                    </div>
                                  </Accordion.Header>
                                  <Accordion.Body className="d-flex flex-column">
                                    {data?.skills.map((data2) => (
                                      <Form.Group
                                        key={data2._id}
                                        className="mb-3 align-items-baseline d-flex"
                                        controlId="formBasicCheckbox"
                                      >
                                        <h6
                                          onClick={() =>
                                            handleinterviewSkillChange(
                                              data2?._id
                                            )
                                          }
                                          className="px-3 c-pointer"
                                        >
                                          {" "}
                                          {data2.skills}
                                        </h6>
                                      </Form.Group>
                                    ))}
                                  </Accordion.Body>
                                </Accordion.Item>
                              </Accordion>
                            </div>
                          ))}
                        </div>
                      </div>
                    </div>
                    <div>
                      {skillshowlist === 3 ? (
                        <YellowHeading
                          className="fs-14 text-center fw-700 c-pointer secondary"
                          onClick={() => setskillshowlist(allskills.length + 1)}
                        >
                          List More +
                        </YellowHeading>
                      ) : (
                        <YellowHeading
                          className="fs-14 text-center c-pointer fw-700 secondary"
                          onClick={() => setskillshowlist(3)}
                        >
                          List Less
                        </YellowHeading>
                      )}
                      <div className="col-12 mb-1">
                        <ButtonBlue
                          onClick={createSkillModalOpen}
                          className="w-100"
                        >
                          + Create Custom
                        </ButtonBlue>
                      </div>
                    </div>
                  </BoxStyle>
                </div>
              )}

              {activeIndex === 2 && (
                <div className="my-3">
                  <BoxStyle className="pt-3 p-2 border-10  px-3">
                    <div className="col-12 mb-3">
                      <div className="row mb-1">
                        <div className="col-10">
                          <Paragraph className="mb-1 fw-600 fs-14 mx-1">
                            Video Question Bank
                          </Paragraph>
                        </div>

                        <div className="w-100">
                          <Wrapper>
                            <SearchInput placeholder="Search" />
                            <GridSearchIcon
                              style={{
                                position: "absolute",
                                right: "20px",
                                top: "20px",
                              }}
                            />
                          </Wrapper>
                        </div>
                        <div className="category-scroller">
                          {allquestions
                            ?.slice(0, questionshowlist)
                            ?.map((data, i) => (
                              <div
                                className="
                             mt-2"
                                key={i}
                              >
                                <Accordion>
                                  <Accordion.Item eventKey="0">
                                    <Accordion.Header>
                                      <div className="me-2 d-flex flex-column align-items-center">
                                        <div>
                                          <YellowHeading className="fs-14 fw-600 secondary">
                                            {data?.questiontype
                                              .charAt(0)
                                              .toUpperCase() +
                                              data?.questiontype.slice(1)}
                                          </YellowHeading>
                                          <YellowHeading className="fs-14 mb-0 fw-600">
                                            {data?.questions.length} Questions
                                          </YellowHeading>
                                        </div>
                                      </div>
                                    </Accordion.Header>
                                    <Accordion.Body className="d-flex flex-column">
                                      <ButtonBlue
                                        onClick={() => {
                                          setUpdateQuestionId(data?._id);
                                          setInterviewnewQue({
                                            ...interviewnewQue,
                                            questiontype: data?.questiontype,
                                          });
                                          setshowCreateNewQueModal(true);
                                        }}
                                        className="h-40 px-2 mx-3 mb-3"
                                      >
                                        + Add Question
                                      </ButtonBlue>
                                      {data?.questions.map((data2, i) => (
                                        <Form.Group
                                          key={data2?._id}
                                          className="mb-3 align-items-baseline d-flex"
                                          controlId="formBasicCheckbox"
                                        >
                                          <h6
                                            className="px-3 c-pointer"
                                            onClick={(e) =>
                                              handleInterviewQueChange(data2, e)
                                            }
                                          >
                                            {data2.question}
                                          </h6>
                                        </Form.Group>
                                      ))}
                                    </Accordion.Body>
                                  </Accordion.Item>
                                </Accordion>
                              </div>
                            ))}
                        </div>
                      </div>
                    </div>
                    <div>
                      {questionshowlist === 3 ? (
                        <YellowHeading
                          className="fs-14 text-center fw-700 secondary c-pointer"
                          onClick={() =>
                            setquestionshowlist(allquestions.length)
                          }
                        >
                          List More +
                        </YellowHeading>
                      ) : (
                        <YellowHeading
                          className="fs-14 text-center fw-700 secondary c-pointer"
                          onClick={() => setquestionshowlist(3)}
                        >
                          List Less
                        </YellowHeading>
                      )}
                      <div className="col-12 mb-1">
                        <ButtonBlue
                          className="w-100"
                          onClick={CreateQueModalOpen}
                        >
                          + Create Category
                        </ButtonBlue>
                      </div>
                    </div>
                  </BoxStyle>
                </div>
              )}
            </div>
            <div className="col-12 col-md-8">
              {activeIndex === 0 && (
                <BoxStyle className="job-form">
                  <BoxStyleBlueLight
                    onClick={() => UploadInput.current.click()}
                    className="py-5 mb-2 box-shadow-none d-flex justify-content-center align-items-center"
                  >
                    <>
                      {loadingiconupload ? (
                        <div className="upload-btn-wrapper">
                          <button className="btn">
                            {percentage}% Uploading...
                          </button>
                        </div>
                      ) : (
                        <div className="upload-btn-wrapper">
                          {icon ? (
                            <button className="btn">
                              Change Image <BsArrowUp />
                            </button>
                          ) : (
                            <button className="btn">
                              Upload Image <BsArrowUp />
                            </button>
                          )}
                        </div>
                      )}

                      <input
                        ref={UploadInput}
                        type="file"
                        hidden
                        name="companyLogo"
                        onChange={fileUploadHandler}
                      />
                    </>
                  </BoxStyleBlueLight>
                  <Form
                    onSubmit={() => {
                      setIndex((prev) => prev + 1);
                    }}
                  >
                    <div className="d-flex align-items-center">
                      <div className="interview-logo">
                        <img
                          src={icon || InterviewLogo}
                          className="rounded-circle"
                          width={100}
                          height={100}
                        />
                      </div>
                      <div className="pt-3 mx-1 mx-sm-3">
                        <div className="d-flex justify-content-between">
                          <h5 className="mb-0">
                            <TextArea
                              value={jobdetails?.company}
                              id="company"
                              name="company"
                              className="b-none w-100"
                              placeholder="Name Of Company"
                              onChange={handleChange}
                            ></TextArea>
                          </h5>

                          <div className="mt-1 mx-0 mx-sm-1">
                            <AiTwotoneEdit />
                          </div>
                        </div>
                        <div className="d-flex justify-content-between">
                          <p className="mb-0">
                            <TextArea
                              value={jobdetails?.companyDescription}
                              name="companyDescription"
                              onChange={handleChange}
                              placeholder="Description of Company"
                              className="b-none w-100"
                            ></TextArea>
                          </p>
                          <div className="mt-1 mx-0 mx-sm-1">
                            <AiTwotoneEdit />
                          </div>
                        </div>
                      </div>
                    </div>
                    <label>Job Title*</label>
                    <TextArea
                      placeholder="Title"
                      className="h-65 secondary border-10 mb-2"
                      name="jobtitle"
                      required
                      value={jobdetails?.jobtitle}
                      onChange={handleChange}
                    ></TextArea>
                    <label>Job Description</label>
                    <TextEditorBox>
                      <ReactQuill
                        theme="snow"
                        value={jobdescription}
                        onChange={setJobDescription}
                      />
                    </TextEditorBox>
                    <div className="col-12">
                      <div className="row">
                        <label>Select Domain</label>
                        <div className="col-12 col-sm-6">
                          <CreatableSelect
                            className="select-interview"
                            createOptionPosition="first"
                            closeMenuOnSelect={true}
                            placeholder={jobdetails.domain}
                            onChange={(newval) => {
                              setJobDetails({
                                ...jobdetails,
                                domain: newval.value,
                              });
                            }}
                            options={jobDomain}
                            // onInputChange={changeInputHandler}
                            isClearable={false}

                            // inputValue={titleInput}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <CreatableSelect
                            className="select-interview"
                            createOptionPosition="first"
                            placeholder={jobdetails.jobcategory}
                            closeMenuOnSelect={true}
                            onChange={(newval) => {
                              setJobDetails({
                                ...jobdetails,
                                jobcategory: newval.value,
                              });
                            }}
                            options={subDomain}
                            // onInputChange={changeInputHandler}
                            isClearable={false}

                            // inputValue={titleInput}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <label>Job Location*</label>
                        <div className="col-12 col-sm-6">
                          <CreatableSelect
                            className="select-interview"
                            createOptionPosition="first"
                            placeholder={jobdetails.jobcity}
                            closeMenuOnSelect={true}
                            onChange={(newval) => {
                              setJobDetails({
                                ...jobdetails,
                                jobcity: newval.value,
                              });
                            }}
                            options={cities}
                            // onInputChange={changeInputHandler}
                            isClearable={false}

                            // inputValue={titleInput}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <TextArea
                            className="h-65 secondary border-10 mb-2"
                            name="jobarea"
                            required
                            placeholder="Area"
                            value={jobdetails?.jobarea}
                            onChange={handleChange}
                          ></TextArea>
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <label>Job Type (Optional)</label>
                        <div className="col-12 col-sm-6">
                          <CreatableSelect
                            className="select-interview"
                            createOptionPosition="first"
                            placeholder={jobdetails.jobtype}
                            closeMenuOnSelect={true}
                            onChange={(newval) => {
                              setJobDetails({
                                ...jobdetails,
                                jobtype: newval.value,
                              });
                            }}
                            options={jobTypes}
                            // onInputChange={changeInputHandler}
                            isClearable={false}

                            // inputValue={titleInput}
                          />
                        </div>
                        <div className="col-12 col-sm-6">
                          <CreatableSelect
                            className="select-interview"
                            createOptionPosition="first"
                            placeholder={jobdetails.joblocation}
                            closeMenuOnSelect={true}
                            onChange={(newval) => {
                              setJobDetails({
                                ...jobdetails,
                                joblocation: newval.value,
                              });
                            }}
                            options={jobLocation}
                            // onInputChange={changeInputHandler}
                            isClearable={false}

                            // inputValue={titleInput}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <label>Years of Experience (Optional)</label>

                        <div className="col-12 col-sm-6">
                          <CreatableSelect
                            className="select-interview"
                            createOptionPosition="first"
                            placeholder={jobdetails.experience}
                            closeMenuOnSelect={true}
                            onChange={(newval) => {
                              setJobDetails({
                                ...jobdetails,
                                experience: newval.value,
                              });
                            }}
                            options={experience}
                            // onInputChange={changeInputHandler}
                            isClearable={false}

                            // inputValue={titleInput}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <div className="row">
                        <label>Job Expiry Date*</label>
                        <div className="col-12 col-sm-6">
                          <TextArea
                            type="date"
                            name="expirydate"
                            required
                            value={jobdetails?.expirydate}
                            onChange={handleChange}
                            className="h-65 secondary border-10 mb-2"
                          ></TextArea>
                        </div>
                      </div>
                    </div>
                    <div className="col-12 d-flex justify-content-end">
                      <ButtonBlue className="px-4 mx-2" type="submit">
                        Next
                      </ButtonBlue>
                    </div>
                  </Form>
                </BoxStyle>
              )}
              {activeIndex === 1 && (
                <BoxStyle>
                  <DarkHeading className="fs-20 fw-500 mb-3">
                    {jobdetails.jobtitle || "Job Title"}
                  </DarkHeading>
                  <hr />
                  <Paragraph className="fs-14 fw-500 mb-1 secondary">
                    Add Skills* ( Select from the left Skills Tab )
                  </Paragraph>
                  <div className="tags-input">
                    {interviewskills.length > 0 ? (
                      <ul id="tags">
                        {interviewskills?.map((tag, index) => (
                          <li key={index} className="tag">
                            <span className="tag-title">
                              {
                                skillsdata?.find((data) => data._id == tag)
                                  ?.skills
                              }
                            </span>
                            <span
                              className="tag-close-icon"
                              onClick={() => removethisskill(index)}
                            >
                              x
                            </span>
                          </li>
                        ))}
                      </ul>
                    ) : (
                      <ul id="tags">
                        <h6>Add upto 20 Skills</h6>
                      </ul>
                    )}

                    {/* <input
                      type="text"
                      onKeyUp={(event) =>
                        event.key === "Enter" ? addnewskills(event) : null
                      }
                      placeholder="Press enter to add Skill"
                    /> */}
                    <Select
                      className="b-none border-10 secondary"
                      aria-hidden
                      onChange={(e) =>
                        handleinterviewSkillChange(e.target.value)
                      }
                    >
                      <option default value="">
                        Select here
                      </option>
                      {skillsdata.map((data) => (
                        <option value={data._id}>{data?.skills}</option>
                      ))}
                    </Select>
                  </div>
                  <div className="col-12 d-flex justify-content-end mt-4 mb-0">
                    <ButtonBlue
                      className="px-5 mx-2"
                      onClick={() => {
                        if (interviewskills.length === 0) {
                          toast.error(
                            "Please Select Atleast One Skill",
                            ToastStyles
                          );
                        } else {
                          setIndex((prev) => prev + 1);
                        }
                      }}
                    >
                      Next
                    </ButtonBlue>
                  </div>
                </BoxStyle>
              )}
              {activeIndex === 2 && (
                <BoxStyle>
                  <div className="d-flex justify-content-between align-items-center">
                    <DarkHeading className="fs-20">
                      Interview Questions
                    </DarkHeading>
                    <ButtonBlue
                      onClick={(e) => {
                        let data = {
                          question: "",
                          keyword: [],
                          suggestedAnswer: "",

                          duration: "",
                          hide: false,
                        };
                        handleInterviewQueChange(data, e);
                      }}
                      className="px-2"
                    >
                      + Add New Question
                    </ButtonBlue>
                  </div>
                  {interviewQue?.length > 0 ? (
                    <>
                      {interviewQue?.map((e, i) => (
                        <div key={i}>
                          {e.hide === true ? (
                            <>
                              <div className="d-flex justify-content-between my-3">
                                <h5>
                                  Q{i + 1}. {e?.question}
                                </h5>
                                <ButtonBlue
                                  onClick={(e) => {
                                    let newFormValues = [...interviewQue];
                                    newFormValues[i]["hide"] = false;
                                    setInterviewQue(newFormValues);
                                  }}
                                  className="px-5 h-40"
                                >
                                  View
                                </ButtonBlue>
                              </div>
                            </>
                          ) : (
                            <>
                              <div className="d-flex align-items-center justify-content-between my-2">
                                <h5>Q{i + 1}.</h5>
                                <Input
                                  placeholder="Enter Question Type"
                                  className="h-40 b-none mb-2"
                                  name="questiontype"
                                  value={e.questiontype}
                                  onChange={(e) =>
                                    handleInterviewQueDataChange(i, e)
                                  }
                                />
                                <div
                                  onClick={() => removeInterviewQueField(i)}
                                  className="cancel-icon c-pointer"
                                >
                                  <AiFillCloseCircle color="red " size={24} />
                                </div>
                              </div>
                              <h6>Question</h6>

                              <Input
                                placeholder="Enter Your Question"
                                className="h-65 mb-2"
                                name="question"
                                value={e.question}
                                onChange={(e) =>
                                  handleInterviewQueDataChange(i, e)
                                }
                              />
                              <h6>Keyword</h6>

                              <Input
                                placeholder="Keywords (Seprated by Comma(','))"
                                className="h-65 mb-2"
                                name="keyword"
                                value={e.keyword}
                                onChange={(e) =>
                                  handleInterviewQueDataChange(i, e)
                                }
                              />
                              <h6>Duration (seconds)</h6>

                              <Input
                                placeholder="Time Duration (seconds)"
                                className="h-65 mb-2"
                                name="duration"
                                type="number"
                                value={e.duration}
                                onChange={(e) =>
                                  handleInterviewQueDataChange(i, e)
                                }
                              />
                              <h6>Suggested Answer</h6>

                              <Input
                                placeholder="Suggested Answer"
                                className="h-65 mb-2"
                                name="suggestedAnswer"
                                value={e.suggestedAnswer}
                                onChange={(e) =>
                                  handleInterviewQueDataChange(i, e)
                                }
                              />
                              <h6>Upload Video</h6>

                              <Input
                                placeholder="Suggested Answer"
                                className="h-65 mb-2 pt-3"
                                type="file"
                                name="videoQuestion"
                                // value={e.videoQuestion}
                                onChange={(e) =>
                                  handleInterviewQueDataChange(i, e)
                                }
                              />
                              {e.videoQuestion && (
                                <p
                                  style={{ color: "green" }}
                                  className="form-title mb-0"
                                >
                                  Uploaded
                                </p>
                              )}

                              <div className="d-flex justify-content-end ">
                                <ButtonBlue
                                  onClick={(e) => {
                                    let newFormValues = [...interviewQue];
                                    newFormValues[i]["hide"] = true;
                                    setInterviewQue(newFormValues);
                                  }}
                                  className="px-5 h-40"
                                >
                                  Save
                                </ButtonBlue>
                              </div>
                            </>
                          )}

                          {/* <h6>Mode of Question</h6>

                          <Select
                            className="h-65 secondary border-10 mb-2"
                            name="modeofquestion"
                            value={e.modeofquestion}
                            onChange={(e) => handleInterviewQueDataChange(i, e)}
                          >
                            <option value={e.modeofquestion}>
                              {e.modeofquestion}
                            </option>
                            <hr />
                            <option value="Online">Online</option>
                            <option value="Offline">Offline</option>
                          </Select> */}
                          {/* <h6>Mode of Answer</h6>

                          <Select
                            className="h-65 secondary border-10 mb-2"
                            name="modeofanswer"
                            value={e.modeofanswer}
                            onChange={(e) => handleInterviewQueDataChange(i, e)}
                          >
                            <option value={e.modeofanswer}>
                              {e.modeofanswer}
                            </option>
                            <hr />
                            <option value="Online">Online</option>
                            <option value="Offline">Offline</option>
                          </Select> */}
                        </div>
                      ))}
                      <div className="col-12 d-flex justify-content-between mt-4 mb-2 align-items-center">
                        <p>
                          {uploadVideoLoading ? "Uploading in Progress" : ""}
                        </p>

                        {uploadVideoLoading ? (
                          <ButtonBlue
                            className="px-5 mx-2"
                            style={{ width: "180px" }}
                          >
                            <Spinner size="sm" animation="border" />
                          </ButtonBlue>
                        ) : (
                          <ButtonBlue
                            style={{ width: "180px" }}
                            onClick={() => uploadVideo()}
                          >
                            Update Questions
                          </ButtonBlue>
                        )}
                      </div>
                    </>
                  ) : (
                    <Paragraph className="fs-14 fw-500 mb-1 secondary">
                      Add Questions* ( Select from the left Question Bank or
                      Create Custom)
                    </Paragraph>
                  )}

                  {/* <div>
                     <h5 className="my-3">Preparation Question </h5>
                   </div>
                   <TextArea
                     placeholder="Title"
                     className="h-65 secondary border-10 mb-2"
                     value={interviePracticeQue}
                     onChange={(e) => {
                       setInterviewPracticeQue(e.target.value);
                     }}
                   ></TextArea> */}
                  <hr />

                  <div className="col-12 d-flex justify-content-end mt-4 mb-2">
                    <ButtonBlue
                      className="px-5 mx-2"
                      onClick={() => {
                        if (!uploadVideoLoading) {
                          if (interviewQue.length === 0) {
                            toast.error(
                              "Please Select Atleast One Question",
                              ToastStyles
                            );
                          } else {
                            setIndex((prev) => prev + 1);
                          }
                        }
                      }}
                    >
                      Next
                    </ButtonBlue>
                  </div>
                </BoxStyle>
              )}
              {activeIndex === 3 && (
                <BoxStyle>
                  <div className="d-flex justify-content-between align-items-center">
                    <DarkHeading className="fs-20">Ask Documents</DarkHeading>
                  </div>

                  <div>
                    <label className="secondary mb-2">List of documents</label>
                    <div className="col-12 col-sm-7">
                      <BoxYellowOutline className="secondary h-60 p-2 mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <p className="mb-0 fs-12">Ask for</p>
                            <Paragraph className="color-secondary fs-14 mt-0 fw-500 mb-1">
                              Upload Resume
                            </Paragraph>
                          </div>
                          <Form.Check
                            className="checkbox"
                            type="checkbox"
                            value="Resume"
                            checked={documentlist.includes("Resume")}
                            onChange={handleDocumentsListChange}
                          />
                        </div>
                      </BoxYellowOutline>
                      <BoxYellowOutline className="secondary h-60 p-2 mb-2">
                        <div className="d-flex justify-content-between align-items-center">
                          <div>
                            <p className="mb-0 fs-12">Ask for</p>
                            <Paragraph className="color-secondary fs-14 mt-0 fw-500 mb-1">
                              Cover Letter
                            </Paragraph>
                          </div>
                          <Form.Check
                            value="CoverLetter"
                            onChange={handleDocumentsListChange}
                            checked={documentlist.includes("CoverLetter")}
                            className="checkbox"
                            type="checkbox"
                          />
                        </div>
                      </BoxYellowOutline>
                      <label className="secondary mb-1">
                        Ask for Other Documents
                      </label>
                      <div className="tags-input">
                        <ul id="tags">
                          {documentlist
                            ?.filter(
                              (data) =>
                                data !== "Resume" && data !== "CoverLetter"
                            )
                            .map((tag, index) => (
                              <li key={index} className="tag">
                                <span className="tag-title">{tag}</span>
                                <span
                                  className="tag-close-icon"
                                  onClick={() => removenewdoc(index)}
                                >
                                  x
                                </span>
                              </li>
                            ))}
                        </ul>
                        <input
                          type="text"
                          onKeyUp={(event) =>
                            event.key === "Enter" ? addnewdoc(event) : null
                          }
                          placeholder="Press enter to add Document"
                        />
                      </div>
                    </div>
                  </div>
                  <div>
                    <label className="secondary my-3">Selected Documents</label>
                    <Input
                      placeholder="Documents"
                      className="h-65 mb-2"
                      name="docs"
                      value={documentlist}
                      disabled
                    />
                  </div>
                  {/* <label className="secondary my-3">Add Teams</label>
                           <div className="tags-input">
                              {interviewteams.length > 0 ? (
                                 <ul id="tags">
                                    {interviewteams?.map((tag, index) => (
                                       <li key={index} className="tag">
                                          <span className="tag-title">
                                             {
                                                teamsdata?.find((data) => data._id == tag)
                                                   ?.name
                                             }
                                          </span>
                                          <span
                                             className="tag-close-icon"
                                             onClick={() => removethisteam(index)}
                                          >
                                             x
                                          </span>
                                       </li>
                                    ))}
                                 </ul>
                              ) : (
                                 <ul id="tags">
                                    <h6>Add upto 20 Teams</h6>
                                 </ul>
                              )}
                              <Select
                                 className="b-none border-10 secondary"
                                 aria-hidden
                                 onChange={(e) =>
                                    handleinterviewTeamChange(e.target.value)
                                 }
                              >
                                 <option default value="">
                                    Select here
                                 </option>
                                 {teamsdata.map((data) => (
                                    <option value={data._id}>{data?.name}</option>
                                 ))}
                              </Select>
                           </div> */}
                  <div className="col-12 d-flex justify-content-end mt-4 mb-2">
                    {createInterviewDraftLoading ? (
                      <ButtonYellowOutline className="px-5">
                        <Spinner animation="border" size="sm" />
                      </ButtonYellowOutline>
                    ) : (
                      <ButtonYellowOutline
                        className="px-2 mx-2"
                        onClick={UpdateInterviewDraft}
                      >
                        Save As Draft
                      </ButtonYellowOutline>
                    )}

                    {createInterviewLoading ? (
                      <ButtonBlue className="mx-2 px-5">
                        <Spinner animation="border" size="sm" />
                      </ButtonBlue>
                    ) : (
                      <ButtonBlue
                        className=" mx-2 px-2"
                        onClick={UpdateNewInterview}
                      >
                        Update Interview
                      </ButtonBlue>
                    )}
                  </div>
                </BoxStyle>
              )}
            </div>
          </div>
        </div>
      </DashboardBoxStyle>
    </>
  );
};

export default DraftInterviewForm;
