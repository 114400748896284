import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router";
import {
  BoxStyle,
  ButtonBlue,
  DarkHeading,
  Paragraph,
  PinkButton,
  SpanYellow,
} from "components/common/CommonStyles";
import job from "assets/image/job.png";
import expiry from "assets/image/expiry.png";
import time from "assets/image/time.png";
import stock from "assets/image/stock.png";
import heart from "assets/image/heart.png";
import tick from "assets/image/tick.png";
import NotPurchasedModal from "components/common/NotPurchasedModal";
import Cookies from "js-cookie";
import Logo from "../../assets/image/logosmall.png";
const fetchSkillDetails = async (skillId) => {
  try {
    const response = await fetch(
      // `https://interview-api.viosa.in/interview/skills/${skillId}`
      `https://api.viosa.in/interview-pro/interview/skills/${skillId}`
      // `http://localhost:3200/interview-pro/interview/skills/${skillId}`
    );
    const data = await response.json();
    console.log("skills", data);
    return data.skills; // Assuming the API returns the skill object with a 'skills' field containing the skill name.
  } catch (error) {
    console.error(`Error fetching skill details for ${skillId}:`, error);
    return null;
  }
};

const PracticeInterviewCard = ({ item }) => {
  const isPurchased = Cookies.get("isPurchased");
  // console.log(isPurchased);
  const [readmore, setreadmore] = useState(false);
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const [skillNames, setSkillNames] = useState([]);
  console.log("skills", item);

  // useEffect(() => {
  //   const fetchSkills = async () => {
  //     if (Array.isArray(item.skills)) {
  //       console.log("skillss", item);
  //       const skillsPromises = item.skills.map(fetchSkillDetails);
  //       const skills = await Promise.all(skillsPromises);
  //       setSkillNames(skills.filter(Boolean));
  //     }
  //   };

  //   fetchSkills();
  // }, [item.skills]);
  useEffect(() => {
    if (item && item.skills) {
      const skills = item.skills.map((skill) => skill.skills);
      setSkillNames(skills);
    }
  }, [item.skills]);

  if (!item) {
    // console.log("Item is null or undefined");
    return null;
  }
  return (
    <>
      <NotPurchasedModal show={show} setShow={setShow} />
      <BoxStyle className="pt-3 mb-3 padding-5 linear" key={item?._id}>
        <div className="container interview-card">
          <div className="row">
            <div className="col-12">
              <div className="col-12">
                <div className="mb-1">
                  <div className="d-flex align-items-center">
                    {/* <div className="interview-logo">
                      <img
                        className="rounded-circle"
                        height={70}
                        width={70}
                        src={item?.icon || Logo}
                        alt={item?.jobtitle}
                      />
                    </div> */}
                    <div className="mx-2">
                      <div className="">
                        <DarkHeading className="px-2">
                          {item?.jobtitle}
                        </DarkHeading>
                        <div className="mb-3 d-none-525">
                          <div className="d-flex">
                            <div className="px-1 d-flex align-items-center">
                              <img
                                className="rounded-circle mx-1"
                                height={17}
                                width={17}
                                src={tick}
                                alt={tick}
                              />
                              <span
                                className="fs-14"
                                style={{ fontSize: "14px" }}
                              >
                                {item?.jobtype}
                              </span>
                            </div>
                            <div className="px-1 d-flex align-items-center">
                              <img
                                className="rounded-circle mx-1"
                                height={17}
                                width={17}
                                src={time}
                                alt={time}
                              />
                              <span
                                className="fs-14"
                                style={{ fontSize: "14px" }}
                              >
                                {item?.joblocation}
                              </span>
                            </div>
                            <div className="px-1 d-flex align-items-center">
                              <img
                                className="rounded-circle mx-1"
                                height={17}
                                width={17}
                                src={job}
                                alt={job}
                              />
                              <span
                                className="fs-14"
                                style={{ fontSize: "14px" }}
                              >
                                {item?.experience}&nbsp;Years
                              </span>
                            </div>
                            <div className="px-1 d-flex align-items-center">
                              <img
                                className="rounded-circle mx-1"
                                height={17}
                                width={17}
                                src={stock}
                                alt={stock}
                              />
                              <span
                                className="fs-14"
                                style={{ fontSize: "14px" }}
                              >
                                {item?.jobcity}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="my-2">
                  <div className="mb-3 d-flex-525">
                    <div className="w-100">
                      <div className="d-flex justify-content-centerw-100">
                        <div className="px-1 d-flex align-items-center col-6">
                          <img
                            className="rounded-circle mx-1"
                            height={17}
                            width={17}
                            src={tick}
                            alt={tick}
                          />
                          <span className="fs-14" style={{ fontSize: "14px" }}>
                            {item?.jobtype}
                          </span>
                        </div>
                        <div className="px-1 d-flex align-items-center col-6">
                          <img
                            className="rounded-circle mx-1"
                            height={17}
                            width={17}
                            src={time}
                            alt={time}
                          />
                          <span className="fs-14" style={{ fontSize: "14px" }}>
                            {item?.joblocation}
                          </span>
                        </div>
                      </div>
                      <div className="d-flex justify-content-center">
                        <div className="px-1 d-flex align-items-center col-6">
                          <img
                            className="rounded-circle mx-1"
                            height={17}
                            width={17}
                            src={job}
                            alt={job}
                          />
                          <span className="fs-14" style={{ fontSize: "14px" }}>
                            {item?.experience}&nbsp;Years
                          </span>
                        </div>
                        <div className="px-1 d-flex align-items-center col-6">
                          <img
                            className="rounded-circle mx-1"
                            height={17}
                            width={17}
                            src={stock}
                            alt={stock}
                          />
                          <span className="fs-14" style={{ fontSize: "14px" }}>
                            {item?.jobcity}
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-12 interview-des">
                  <Paragraph>Job Description</Paragraph>
                  {readmore ? (
                    <Paragraph className="mb-0">
                      <div
                        dangerouslySetInnerHTML={{
                          __html: item?.jobdescription,
                        }}
                      ></div>
                      <SpanYellow
                        className="c-pointer"
                        onClick={() => setreadmore(false)}
                      >
                        READ LESS
                      </SpanYellow>
                    </Paragraph>
                  ) : (
                    <Paragraph className="mb-0 d-block">
                      {item?.jobdescription?.length > 150 ? (
                        <>
                          <div
                            style={{ width: "100%" }}
                            className="d-flex align-items-end"
                          >
                            <div
                              style={{
                                width: `calc(100% - 100px)`,
                                height: "100px",
                                overflow: "hidden",
                              }}
                              dangerouslySetInnerHTML={{
                                __html: item.jobdescription,
                              }}
                            ></div>
                            <SpanYellow
                              style={{ width: "100px" }}
                              className="c-pointer pb-1"
                              onClick={() => setreadmore(true)}
                            >
                              READ MORE
                            </SpanYellow>
                          </div>
                        </>
                      ) : (
                        <Paragraph className="mb-0">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: item?.jobdescription,
                            }}
                          ></div>
                        </Paragraph>
                      )}
                    </Paragraph>
                  )}
                  {/* <Paragraph>
                              {item.description.slice(1,101)}...
                              <SpanYellow onClick={()=>setreadmore(true)}>READ MORE</SpanYellow>
                            </Paragraph> */}
                </div>
                {/* <Paragraph className="pt-1 mb-0">Skills</Paragraph> */}
                <div className="d-flex justify-content-between align-items-center mb-2 skills-cont">
                  {/* {item.skills.length > 0 && (
                    <div className="w-100-525">
                      <Paragraph className="pt-1 mb-0">Skills</Paragraph>
                      {skillNames.map((skill, index) => (
                        <PinkButton key={index} className="fs-12 mx-1 my-1">
                          {skill}
                        </PinkButton>
                      ))}
                    </div>
                  )} */}
                  {skillNames.length > 0 && (
                    <div className="w-100-525">
                      <Paragraph className="pt-1 mb-0">Skills</Paragraph>
                      {skillNames.map((skill, index) => (
                        <PinkButton key={index} className="fs-12 mx-1 my-1">
                          {skill}
                        </PinkButton>
                      ))}
                    </div>
                  )}
                  <div></div>
                  <div className="w-100-525">
                    <ButtonBlue
                      className="px-3 practice-btn-interview w-160"
                      onClick={() => {
                        if (isPurchased === "false") {
                          setShow(true);
                          // console.log("modal open")
                        } else {
                          navigate(`/interview/${item?._id}`);
                        }
                      }}
                    >
                      Practice Interview
                    </ButtonBlue>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </BoxStyle>
    </>
  );
};

export default PracticeInterviewCard;
