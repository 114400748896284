import { CollegeSidePanelData } from "contants/CollegeSidePanelData";
import Cookies from "js-cookie";
import React, { useEffect } from "react";
import { useNavigate } from "react-router";
import { SideBarAndHeaderCollge } from "screen/SideBarAndHeaderCollge";

const IndexCollege = () => {
  const navigate = useNavigate();
  let loginToken =  Cookies.get("token")
  useEffect(() => {
    if(!loginToken){
      navigate('/')
    }
  }, [loginToken])
  return <SideBarAndHeaderCollge data={CollegeSidePanelData} />;
};

export default IndexCollege;
