import React, { useEffect, useState } from "react";
import { FaUserFriends } from "react-icons/fa";
import { TiTick } from "react-icons/ti";
import "./Dashboard.css";
import { BsFillSaveFill, BsPatchCheckFill } from "react-icons/bs";

import { MdNotificationsActive } from "react-icons/md";
import {
  BoxStyle,
  DashboardBoxStyle,
  YellowHeading,
} from "components/common/CommonStyles";

import "swiper/css";
import "swiper/css/pagination";

import { AiFillFileAdd } from "react-icons/ai";
import RecentjobCard from "./RecentjobCard";
import {
  DeleteInterviewById,
  GetAllCreatorInterview,
  GetAllCreatorInterviewByLimit,
  getCorporateDashboardReq,
  getCorporateNotificationReq,
} from "../../../axios/apis";
import { Toaster, toast } from "react-hot-toast";
import { ToastStyles } from "components/common/ToastStyle";
import Moment from "react-moment";
import Loading from "components/common/Loading";
import { Link } from "react-router-dom";

export const Dashboard = () => {
  const [search, setSearch] = useState("");
  const [dashboardData, setDashboardData] = useState({
    activeJobs: 0,
    jobsPosting: 0,
    noOfResume: 0,
    scheduledInterview: 0,
    totalCandidates: 0,
    totalShortlisted: 0,
  });
  const [loading, setLoading] = useState(false);
  const [searchOption, setSearchOption] = useState("");
  const [interview, setInterView] = useState([]);
  const [allinterview, setAllInterView] = useState([]);
  const [notification, setNotification] = useState();
  const getNotification = async () => {
    try {
      setLoading(true);
      const res = await getCorporateNotificationReq();
      console.log("res notification", res);
      setNotification(res?.data?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
  };
  const GetAllInterviews = async () => {
    setLoading(true);
    try {
      const response = await GetAllCreatorInterviewByLimit({
        page: 1,
        limit: 5,
        search: search,
      });
      // Filter interviews based on creator_type
      const corporateInterviews = response.data.interviews.filter(
        (interview) => interview.creator_type === "corporate"
      );
      // setInterView(response.data.interviews);
      setInterView(corporateInterviews);
      setAllInterView(response.data.interviews);
      setLoading(false);
    } catch (error) {
      console.log(error);
      toast.error("Try Again", ToastStyles);
      setLoading(false);
    }
  };

  const getDashboardData = async () => {
    try {
      setLoading(true);
      const res = await getCorporateDashboardReq();
      setDashboardData(res?.data?.data);
      // console.log(res);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      toast.error("Try Again", ToastStyles);

      console.log(error);
    }
  };

  const handleSearch = (e) => {
    let searched = e.target.value;
    setSearch(e.target.value);
    if (searched.length > 0) {
      const searchInterview = allinterview.filter((interview) =>
        interview.jobtitle.toLowerCase().includes(searched.toLowerCase())
      );
      setInterView(searchInterview);
    } else {
      setInterView(allinterview);
    }
  };

  const DeleteInterview = async (e) => {
    // console.log(e)
    const res = window.confirm("Are you sure you want to Delete Interview?");
    if (res) {
      setLoading(true);
      try {
        const data = {
          id: e._id,
        };
        const response = await DeleteInterviewById(data);
        // console.log(response);
        GetAllInterviews();
        toast.error("Deleted Successfully", ToastStyles);
        setLoading(false);
      } catch (error) {
        console.log(error);
        toast.error("Try Again", ToastStyles);
        setLoading(false);
      }
    }
  };

  useEffect(() => {
    getDashboardData();
    GetAllInterviews();
    getNotification();
  }, []);

  return (
    <>
      <Toaster />
      {loading ? (
        <DashboardBoxStyle className="scroller d-flex align-items-center justify-content-center">
          <Loading />
        </DashboardBoxStyle>
      ) : (
        <DashboardBoxStyle className=" scroller">
          <div
            className="container px-0
        "
          >
            <div className="card1-container mt-3">
              {/* <Link
                to={"/hire-pro/my-interviews"}
                className="dashboardcard bg-purple"
              > */}
              <div className="dashboardcard bg-purple">
                <div className="col-10">
                  <h6 className="card1-head">Active Jobs</h6>
                  <h6>{dashboardData?.activeJobs}</h6>
                </div>
                <div className="col-2">
                  <BsPatchCheckFill size={26} color=" #521986" />
                </div>
              </div>
              {/* </Link> */}
              {/* <Link
                to={"/hire-pro/dashboard"}
                className="dashboardcard bg-purple"
              > */}
              <div className="dashboardcard bg-purple">
                <div className="col-10">
                  <h6 className="card1-head">Total Candidates</h6>
                  <h6>{dashboardData?.totalCandidates}</h6>
                </div>
                <div className="col-2">
                  <FaUserFriends size={26} color=" #521986" />
                </div>
              </div>
              {/* </Link> */}
              {/* <Link
                to={"/hire-pro/dashboard"}
                className="dashboard-card1 bg-purple"
              >
                <div className="col-10">
                  <h6 className="card1-head">Total Shortlisted</h6>
                  <h6>{dashboardData?.totalShortlisted}</h6>
                </div>
                <div className="col-2">
                  <TiTick size={26} color=" #521986" />
                </div>
              </Link> */}
              {/* <Link
                to={"/hire-pro/dashboard"}
                className="dashboardcard bg-purple"
              > */}
              <div className="dashboardcard bg-purple">
                <div className="col-10">
                  <h6 className="card1-head">Total Drafted Interviews</h6>
                  <h6>
                    {dashboardData?.jobsPosting - dashboardData?.activeJobs}
                  </h6>
                </div>
                <div className="col-2">
                  <TiTick size={26} color=" #521986" />
                </div>
              </div>
              {/* </Link> */}
              {/* <Link
                to={"/hire-pro/my-interviews"}
                className="dashboardcard bg-purple"
              > */}
              <div className="dashboardcard bg-purple">
                <div className="col-10">
                  <h6 className="card1-head">Interviews Posting</h6>
                  <h6>{dashboardData?.jobsPosting}/50</h6>
                </div>
                <div className="col-2">
                  <AiFillFileAdd size={26} color=" #521986" />
                </div>
              </div>
              {/* </Link> */}
              {/* <div className="dashboard-card1 bg-purple">
                <div className="col-10">
                  <h6 className="card1-head">Scheduled Interview</h6>
                  <h6>{dashboardData?.scheduledInterview}</h6>
                </div>
                <div className="col-2">
                  <RiVideoAddFill size={26} color=" #521986" />
                </div>
              </div>
              <div className="dashboard-card1 bg-purple">
                <div className="col-10">
                  <h6 className="card1-head">No of Resume</h6>
                  <h6>{dashboardData?.noOfResume}</h6>
                </div>
                <div className="col-2">
                  <HiDocumentText size={26} color=" #521986" />
                </div>
              </div>
              <div className="dashboard-card1-slider ">
                <div
                  id="carouselExampleAutoplaying"
                  className="carousel slide"
                  data-bs-ride="carousel"
                >
                  <div className="carousel-inner">
                    <div className="carousel-item active">
                      <div className="dashboard-card-slider">
                        <div className="col-10">
                          <h6 className="card1-head">
                            Upgrade your plan to the next level
                          </h6>
                          <h6>₹1500- Only</h6>
                        </div>
                        <div className="col-1 slider-icon">
                          <GiAchievement size={26} color=" #521986" />
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="dashboard-card-slider">
                        <div className="col-10">
                          <h6 className="card1-head">ATS Compliant Resume </h6>
                          <h6>₹1500- Only</h6>
                        </div>
                        <div className="col-1 slider-icon">
                          <MdFilePresent size={26} color=" #521986" />
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="dashboard-card-slider">
                        <div className="col-10">
                          <h6 className="card1-head">Apply Multiple Jobs</h6>
                          <h6>₹1500- Only</h6>
                        </div>
                        <div className="col-1 slider-icon">
                          <MdOutlineMultilineChart size={26} color=" #521986" />
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="dashboard-card-slider">
                        <div className="col-10">
                          <h6 className="card1-head">
                            Find you Suitable Career Path
                          </h6>
                          <h6>₹1500- Only</h6>
                        </div>
                        <div className="col-1 slider-icon">
                          <ImInfinite size={26} color=" #521986" />
                        </div>
                      </div>
                    </div>
                    <div className="carousel-item">
                      <div className="dashboard-card-slider">
                        <div className="col-10">
                          <h6 className="card1-head">
                            Upgrade your plan to the next level
                          </h6>
                          <h6>₹1500- Only</h6>
                        </div>
                        <div className="col-1 slider-icon">
                          <GiAchievement size={26} color=" #521986" />
                        </div>
                      </div>
                    </div>
                  </div>
                  <button
                    className="carousel-control-prev"
                    type="button"
                    style={{ opacity: "1", width: "3%" }}
                    data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="prev"
                  >
                    <span
                      className="carousel-control-prev-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Previous</span>
                  </button>
                  <button
                    style={{ opacity: "1", width: "3%" }}
                    className="carousel-control-next"
                    type="button"
                    data-bs-target="#carouselExampleAutoplaying"
                    data-bs-slide="next"
                  >
                    <span
                      className="carousel-control-next-icon"
                      aria-hidden="true"
                    ></span>
                    <span className="visually-hidden">Next</span>
                  </button>
                </div>
              </div> */}
            </div>
            <div className="">
              <div className="my-3 w-100-968">
                <BoxStyle className="padding-5 min-vh-65">
                  <YellowHeading className=" p-3">Recent Jobs</YellowHeading>
                  {/* {allinterview.length > 0 && <div className=" mb-3 mx-1 mx-sm-3"> <div className=" search-box-flex col-12 ">
                    <Form.Group className="col-12">
                      <Form.Control
                        type="text"
                        className="search-box form-input"
                        name="search"
                        value={search}
                        placeholder="Search Jobs"
                        onChange={handleSearch}
                      />
                    </Form.Group>

                  </div></div>} */}
                  {interview.length === 0 ? (
                    <>
                      <div className=" mb-3 mx-1 mx-sm-3">
                        Currently No Jobs to Show
                      </div>
                    </>
                  ) : (
                    <>
                      <div className=" mb-3 mx-1 mx-sm-3"></div>
                      <div className="m-1 m-sm-3">
                        {interview
                          ?.reverse()
                          ?.slice(0, 5)
                          .map((data, i) => (
                            <RecentjobCard
                              DeleteInterview={DeleteInterview}
                              data={data}
                            />
                          ))}
                      </div>
                    </>
                  )}
                </BoxStyle>
              </div>
              {/* <div className="dashboard-sec-2-2 d-none-968">
                <div className="reminders min-vh-65 pb-3 my-3">
                  <div className="d-flex justify-content-between align-content-center p-3">
                    <h4>Reminders</h4>
                    <MdNotificationsActive size={26} color=" #521986" />
                  </div>
                  {notification?.length > 0 ? (
                    <>
                      {notification?.slice(0, 5)?.map((data, i) => (
                        <div key={i} className="reminder-msg-box">
                          <div className="reminder-icon">
                            <BsFillSaveFill size={26} color="#521986" />
                          </div>
                          <div
                            style={{ width: "50%" }}
                            className="remainder-content"
                          >
                            <div>
                              <h6>{data?.title}</h6>
                            </div>
                            <h6 style={{ color: "#ffa303" }}>New Request</h6>
                          </div>
                          <div className="remainder-time">
                            <Moment format="hh:mm A" date={data?.createdAt} />
                          </div>
                        </div>
                      ))}
                    </>
                  ) : (
                    <>
                      <h6
                        className="
                      mx-2 px-2"
                      >
                        No Reminders
                      </h6>
                    </>
                  )}
                </div>
              </div> */}
            </div>
            {/* <div className="dashboard-sec-3 mx-3">
          <BoxStyle className="padding-5">
            <YellowHeading className=" px-3 py-2">My Interviews</YellowHeading>
            <h5 className="yellow px-3 mb-0">Recently Applied</h5>
            <div className="px-3 pb-3">
              <MyInterviews />
            </div>
          </BoxStyle>
        </div> */}
          </div>
        </DashboardBoxStyle>
      )}
    </>
  );
};
